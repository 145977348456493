<mat-chip-listbox class="chip-list" [disabled]="disabled" [selectable]="selectable">
  <mat-chip-option
    *ngFor="let chip of chips"
    [value]="chip"
    [selected]="isChipSelected(chip)"
    (removed)="chipRemoved(chip)"
    (selectionChange)="chipSelected($event, chip)">
    {{ getChipTitle(chip) }}
    <button matChipRemove *ngIf="removable">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-option>
</mat-chip-listbox>
