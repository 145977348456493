import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

export interface DeleteEntityData {
  entityTitle: string;
  deleteMessage: string;
}

@Component({
  selector: 'delete-entity-dialog',
  templateUrl: 'delete-entity-dialog.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DeleteEntityDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeleteEntityData,
  ) {}
}
