import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Config } from '../../config/config';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private config: Config) {
  }

  private static readonly APP_ID = 'hwru483c';
  private isActive: boolean = false;

  /**
   * Intercom is not disabled in the app config and current url should not contains __intercomDisabled
   */
  public get isEnabled(): boolean {
    return !this.config.appParams?.intercomDisabled && !window.location.href.includes('__intercomDisabled')
      && (this.config.isFullAdmin ? true : !this.config.hasFeature('light-map'));
  }

  /**
   * Start intercom session with Spinergie app ID.
   * We can define small CSS style here (horizontal and vertical padding for the intercom bubble)
   */
  public activate(): void {
    if (window['Intercom']) {
      window['intercomSettings'] ??= {};
      window['intercomSettings']['horizontal_padding'] = 20;
      window['intercomSettings']['vertical_padding'] = 36;
      window['Intercom']('boot', this.getUserAttributes());
      this.isActive = true;
    }
  }

  private getUserAttributes(): Params {
    const productPackages = this.config.userRights.productPackages;
    const product = this.config.product;
    const application = this.config.appParams.application;
    const userInfo = this.config.userInfo;

    const {
      externalUserId,
      spinergieUser,
      spinergieUserEmail,
      client,
      userActivated,
      isAppOwner,
      userGroupId,
      jobCategory,
    } = userInfo;
    const commaSeparatedProductPackages = productPackages.join(', ');
    const uniqueId = `${spinergieUserEmail}_${client}_${product}`;

    // Attributes that are used by Intercom. Be careful when changing them!
    const intercomAttributes = {
      app_id: IntercomService.APP_ID,
      user_id: externalUserId, // This id should be unique!!
      name: spinergieUser,
      email: spinergieUserEmail,
    };

    /**
     * Attributes added by us.
     * When adding new attributes, please use snake_case. Then it would be well displayed in Intercom back-office.
     * Tell CSM people if you rename some variables because they may display them or have filter on them.
     */
    const customAttributes = {
      uniqueId,
      client,
      domain: document.location.hostname,
      solution: product,
      application,
      active: userActivated,
      product_packages: commaSeparatedProductPackages,
      app_owner: isAppOwner,
      /* Used to know if a user has access to wind forecast */
      has_construction_wind_forecast_pp: this.config.hasFeature('wind_forecast'),
      has_construction_wind_supply_chain_pp: this.config.hasFeature('wind_supply_chain'),
      has_construction_cable_supply_chain_pp: this.config.hasFeature('cable_supply_chain'),
      user_group_id: userGroupId,
      user_job_category: jobCategory,
    };

    return { ...intercomAttributes, ...customAttributes };
  }

  public updateIntercomOptions({ hideDefaultLauncher }: { hideDefaultLauncher: boolean }): void {
    this.isActive = !hideDefaultLauncher;
    window['Intercom']('update', {
      'hide_default_launcher': hideDefaultLauncher,
    });
  }

  public openWithMessage(message: string): void {
    if (!this.isActive) return;

    window['Intercom']('showNewMessage', message);
  }
}
