import { capitalize, clone, cloneDeep, orderBy, sumBy } from 'lodash-es';
import dayjs, { Dayjs } from 'dayjs';
import tinycolor from 'tinycolor2';
import { ScaleLinear, ScaleOrdinal, scaleOrdinal } from 'd3-scale';
import { D3DragEvent, drag } from 'd3-drag';
import { Selection, BaseType as SelectionBaseType, select } from 'd3-selection';
import { Axis, axisBottom } from 'd3-axis';

import { ChartOrdering } from '../graph/chart-ordering';
import { LegendDrawingHelper, drawLegendItems, svgAttrTranslate, textWidth } from '../helpers/d3-helpers';
import { AdditionalProperty, AdditionalPropertyHighlight, ColorConfig, DrawingPositionParameters, GroupData,
  GroupDrawingData, LayerScheduleDrawingData, LegendColors, OverlapRegion, PossibleColors, ScheduleChartOptions,
  ScheduleDrawingData, ScheduleDrawingSituation, ScheduleFillConfig, ScheduleGraphSettings, ScheduleIntervalMode,
  ScheduleLayer, ScheduleLayerIcon, ScheduleLayerOptions, ScheduleLike, ScheduleTimeWindows, ScheduleVesselData,
  ScheduleWindowName, Scheduled, ScheduledOverlap, TimeWindow, VerticalBreakLine, VerticalBreaklinesParameters,
  VesselDrawingData, xAxisParameters } from './schedule-types';
import { DEFAULT_BAR_PADDING_LEFT, ScheduleTools } from './schedule-tools';
import { ChartLegendOptions, Color, DeepReadonly, FilterApplied, Interval, LayerId,
  SortDirection } from '../helpers/types';
import { ColorHelper, SPINERGIE_DEFAULT_GREEN } from '../helpers/color-helper';
import { TimeCreator } from '../helpers/time-creator';
import { ScheduleTimeManager } from './schedule-time-manager';
import { FilterHelper } from '../filters/filter-helper';
import { DatabaseHelper } from '../database/database-helper';
import { ScheduleComponent } from './spin-schedule';
import { ScheduleLayerLoader } from './schedule-layer-loader';
import { ChartTimeManager } from '../graph/chart-time-manager';
import { SelectableGroupBy } from '../graph/chart-types';
import { getChained } from '../data-loader/ref-data-provider';
import { pluralize } from '../helpers/data-helpers';
import { ColorDefinition } from '../helpers/legend-types';
import { ChartSelectsHelper } from '../graph/chart-selects-helper';

export const NOT_AVAILABLE = 'Not available';
export const MAX_SAFE_LOADABLE_VESSELS = 100;
export const TICK_WIDTH = 150;
/**
 * Amount of horizontal shift to apply to the title when hovering a legend item.
 */
export const LEGEND_ITEM_HOVER_TITLE_X_SHIFT = 30;

/**
 * Contains drawing helper methods that could have been extracted from the schedule
 * Everything that doesn't have direct dependency to the local schedule variables
 */
export class ScheduleDrawingHelper {
  static theoreticalBarHeight = 30;
  // Default bar width for future contracts without dateEnd (in pixels)
  static defaultFutureContractBarWidth = 80;
  private static nowStamp = dayjs().valueOf();

  /*
   * Method used to get the dateEnd for the contract for the overlap calculation.
   * For the overlaps we have to consider the end to be now timestamp
   * For future contract without dateEnd we force the width to be 80px
   */
  private static endGetter = (d: ScheduleLike): number => {
    if (!d.dateEnd) {
      const now = ScheduleDrawingHelper.nowStamp.valueOf();
      if (now > d.dateStart) {
        return now;
      }
      /*
       * For future contracts, use the `visualDateEnd` that has been calculated
       * during redraw, with a fallback of 1 week if date is missing
       */
      return d.visualDateEnd || d.dateStart + 7 * 24 * 3600 * 1000;
    }
    return d.dateEnd;
  };

  private colorScale: ScaleOrdinal<unknown, Color> = scaleOrdinal<Color>();
  private readonly intervalMode: ScheduleIntervalMode;

  private heavyLayersCache: { [layerId: string]: { [propId: string]: DeepReadonly<ScheduleLike>[] } } = {};
  private invalidHeavyLayerCaches: Set<string> = new Set();

  // dictionary to keep the possible list of colors (usually the contract status) per layer
  public possibleColors: PossibleColors = {};

  constructor(
    public chartingOptions: ScheduleChartOptions,
    private layers: { [layerId: string]: ScheduleLayer },
    private settings: ScheduleGraphSettings,
    private windows: ScheduleTimeWindows,
  ) {
    this.intervalMode = this.settings.intervalMode ? this.settings.intervalMode : 'order';
    this.colorScale.range(ColorHelper.getColors());
  }

  public visibleVesselsCount(group: GroupData): number {
    return Object.values(group.boats).filter(d => d.visible).length;
  }

  public onlyActiveVesselCount(group: GroupData): number {
    return Object.values(group.boats).filter(d => d.visible && d.hasBrushedContracts).length;
  }

  public vesselWithoutBrushContractCount(group: GroupData): number {
    return Object.values(group.boats).filter(d => d.visible && !d.hasBrushedContracts).length;
  }

  /**
   * Order the groups according to orderby config if it's defined, or by number of vessels descending by default.
   * Items that don't have group value go to the end
   */
  public groupOrder(groups: GroupData[], groupBy: string): GroupData[] {
    const groupByConf = ChartSelectsHelper.findSelectValueById(this.settings.selects.groupby.values, groupBy);
    const orderBy = groupByConf.orderBy;

    if (!orderBy) {
      return groups.sort((a, b) => this.defaultOrder(a, b));
    }

    if (orderBy.fixedOrder) {
      return groups.sort((a, b) => {
        const layerGroupOrder = this.layerGroupOrder(a, b);
        /*
         * If layerGroupOrder is equal 0 it means that two group are non-layer groups
         * So we have to order them by applying fixed order
         */
        if (layerGroupOrder) {
          return layerGroupOrder;
        }
        return ChartOrdering.fixedOrder(a.id, b.id, orderBy.fixedOrder);
      });
    }

    if (orderBy.firstStartDate) {
      return groups.sort((a, b) => this.firstStartDateOrder(a, b));
    }
  }

  private defaultOrder(group1: GroupData, group2: GroupData): number {
    const layerGroupOrder = this.layerGroupOrder(group1, group2);

    /*
     * If layerGroupOrder is equal 0 it means that two group are non-layer groups
     * So we have to order them by applying default order
     */
    if (layerGroupOrder) {
      return layerGroupOrder;
    }

    if (group1.id === NOT_AVAILABLE) {
      return 1;
    }

    if (group2.id === NOT_AVAILABLE) {
      return -1;
    }

    /*
     * If empty vessels are concatenated we order groups by the number of active (/visible) vessels
     * otherwise we will just order by the total number of vessel in group (active + not active)
     */
    const priorizeActiveVessels = this.settings.concatenateEmptyLines;

    const visibleVesselCount1 = this.onlyActiveVesselCount(group1);
    const visibleVesselCount2 = this.onlyActiveVesselCount(group2);
    const vesselWithoutContractCount1 = this.vesselWithoutBrushContractCount(group1);
    const vesselWithoutContractCount2 = this.vesselWithoutBrushContractCount(group2);

    if (priorizeActiveVessels) {
      if (visibleVesselCount1 > visibleVesselCount2) {
        return -1;
      }
      /*
       * If groups have same number of visible vessel we check if one groupe has more
       * non visible vessels
       */
      if (visibleVesselCount1 === visibleVesselCount2) {
        if (vesselWithoutContractCount1 >= vesselWithoutContractCount2) {
          return -1;
        }
      }
      return 1;
    }

    // If priorizeActiveVessels is false we just order by the total number of vessels (active + non-active)
    if (visibleVesselCount1 + vesselWithoutContractCount1 > visibleVesselCount2 + vesselWithoutContractCount2) {
      return -1;
    } else if (
      visibleVesselCount1 + vesselWithoutContractCount1 === visibleVesselCount2 + vesselWithoutContractCount2
    ) {
      // In case the total number of vessels is the same, we order by the group that has the most active vessels
      if (visibleVesselCount1 >= visibleVesselCount2) {
        return -1;
      }
      return 1;
    }
    return 1;
  }

  private firstStartDateOrder(group1: GroupData, group2: GroupData): number {
    const layerGroupOrder = this.layerGroupOrder(group1, group2);

    /*
     * If layerGroupOrder is equal 0 it means that two group are non-layer groups
     * So we have to order them by applying first start date order
     */
    if (layerGroupOrder) {
      return layerGroupOrder;
    }

    if (!group1.firstStartDate) {
      return -1;
    }
    if (!group2.firstStartDate) {
      return -1;
    }
    if (group1.firstStartDate < group2.firstStartDate) {
      return -1;
    }
    return 1;
  }

  private layerGroupOrder(group1: GroupData, group2: GroupData): number {
    // group layer will be always put on top in relation to multilayer groups
    if (group1.layerAsGroup && !group1.layerAsGroup) {
      return -1;
    } else if (!group1.layerAsGroup && group2.layerAsGroup) {
      return 1;
      // If both groups aren't group layer we return 0 and we applied current sort
    } else if (!group1.layerAsGroup && !group2.layerAsGroup) {
      return 0;
    }

    /*
     * Case where both groups are layer groups
     * If we have a fixedGroupOrder for this layer we apply it otherwise we apply default order
     * (group with more active vessels are on top)
     */
    const fixedGroupOrder = this.layers[group1.layerGroup].options.fixedGroupOrder;
    if (fixedGroupOrder) {
      return ChartOrdering.fixedOrder(group1.title, group2.title, fixedGroupOrder);
    }

    if (this.visibleVesselsCount(group1) >= this.visibleVesselsCount(group2)) {
      return -1;
    }
    return 1;
  }

  /**
   * Places the contract into the first available position that it founds
   */
  public static placeIntoPositions(overlap: OverlapRegion, contract: ScheduledOverlap): void {
    const positionList = Object.values(overlap.contractsOnPosition);
    // get the first line which has a space  - that is the last contract ends before the one that we are adding
    const availablePlace = positionList.find(singleLine =>
      singleLine.contracts.length > 0
      && ScheduleDrawingHelper.endGetter(singleLine.contracts[singleLine.contracts.length - 1])
      && ScheduleDrawingHelper.endGetter(singleLine.contracts[singleLine.contracts.length - 1]) < contract.dateStart
    );

    // if there is an available space add the contract to the line
    if (availablePlace) {
      availablePlace.contracts.push(contract);
    } else {
      // the contract can't be fitted on existing line - adding new inner line
      overlap.contractsOnPosition.push({
        position: overlap.contractsOnPosition.length + 1,
        contracts: [contract],
      });
      overlap.lines = overlap.lines + 1;
    }
  }

  public isOverlay(layerId: LayerId): boolean {
    const layer = this.layers[layerId];
    if (!layer) {
      console.warn('Did not find the layer to get the config');
    }
    return layer.options.overlay;
  }

  public orderVesselsInGroup(group: GroupData): ScheduleVesselData[] {
    // Order vessels in group. By default the vessels are ordered by id
    const orderByConfig = this.settings.orderBy ? clone(this.settings.orderBy) : {
      values: ['id'],
      orders: ['asc'] as SortDirection[],
    };
    const visibleVessels = Object.values(group.boats).filter(d => d.visible);

    /*
     * orderBy can be either a fixed order,
     * in this case each vessel has a fixedOrder parameter we can use to order the vessels
     */
    if (orderByConfig.fixedOrder) {
      orderByConfig.values = ['fixedOrder'];
      orderByConfig.orders = ['asc'];
    }

    if (this.intervalMode === 'order') {
      orderByConfig.values = ['hasBrushedContracts', ...orderByConfig.values];
      orderByConfig.orders = ['desc', ...orderByConfig.orders];
    }

    return orderBy(visibleVessels, orderByConfig.values, orderByConfig.orders);
  }

  /*
   * This method goes iteratively over all groups and gets out only those who are visible
   * for each visible group we will list the visible vessels and preparte the drawing data
   * - mainly calculate the Y position of each element
   */
  public setAllContractPositionsInGroup(
    scheduleDrawingData: ScheduleDrawingData,
    positionsParameters: DrawingPositionParameters,
    drawingSituation: ScheduleDrawingSituation,
  ): void {
    let allVisibleVesselsHandled = false;
    const groups = scheduleDrawingData.groups;
    for (const group of groups) {
      /*
       * We already handle the maximum of vessel that we can
       * we can just stop set contracts in position
       * (This boolean is used for workpackage layer for exemple, where we don't load data all at once)
       */
      if (allVisibleVesselsHandled) {
        break;
      }
      // Group has already been fully populate we can go to next group
      if (group.alreadyTreated) {
        continue;
      }
      const groupCount = group.orderedVessels.length;

      let groupYStart = group.groupYStart;
      let groupSeparationLine = group.groupSeparationLine;
      let contracts: Scheduled[] = group.allScheduled;

      // Case when it's first time we treat this group we have to init some values
      if (!group.alreadyStarted) {
        // capture the current position as start of the group
        groupYStart = positionsParameters.currentHeight;
        /*
         * offset by header text + space before the separation line
         * the text is not exactly in the middle of the group header (group header is 20px fixed)
         * so when we place the separation line and we want it in the middle of the text, we can't just divide
         * so we are adding some pixels
         */
        groupSeparationLine = positionsParameters.currentHeight + this.chartingOptions.groupHeaderSize / 2 + 5;
        // offset by header text + space before and after the separation line
        positionsParameters.currentHeight += this.chartingOptions.groupHeaderSize + this.chartingOptions.space;
        /*
         * note that we are going over all items - not only the items that are show=true (visible)
         * because contracts are shown still but with lover opacity if they are on a vessel which has
         * at least one visible contract
         */
        contracts = [];
      }
      const drawingVessels: VesselDrawingData[] = group.groupVessels;

      /*
       * we set this boolean here. Because if we come back in this function later
       * to handle more vessels on the group there is some params that we don't have to init again
       */
      group.alreadyStarted = true;
      const vesselListWithoutBrushContract: ScheduleVesselData[] = [];

      if (!drawingSituation.collapsedGroupIds.includes(group.id)) {
        // go over all vessels in a group
        for (let i = 0; i < group.orderedVessels.length; i++) {
          const vessel = group.orderedVessels[i];
          const isLast = i === group.orderedVessels.length - 1;

          if (allVisibleVesselsHandled) {
            break;
          }

          // If this vessel has already been treated we can just continue to next vessel
          if (vessel.alreadyTreated) {
            continue;
          }

          /*
           * the current vessel is the last vessel to treat (it means it's the last vessel for which
           * we have all the data) we set allVisibleVesselsHandle to true, to avoid treat next vessels
           * with incomplete data
           */
          if (vessel.lastToTreat) {
            allVisibleVesselsHandled = true;
            vessel.lastToTreat = false;
          }
          vessel.alreadyTreated = true;

          // we treated all the vessel in the group
          if (isLast) {
            group.alreadyTreated = true;
          }

          /*
           * if the vessel has no brushed contracts (no contracts in chosen interval) and if in the same time
           * the option to concatenate empty lines is activated we will add the vessel to the concatenated list
           * we have to also handle the "concatenateDescriptio - specially for retirement contracts"
           */
          if (!vessel.hasBrushedContracts && this.settings.concatenateEmptyLines) {
            for (const layerId in vessel.layers) {
              if (!vessel.layers[layerId].scheduled.length) {
                continue;
              }
              const layerOpts = this.layers[layerId].options;
              if (layerOpts.concatenateDescription) {
                const layerDescriptionValue = DatabaseHelper.replaceTokens(
                  layerOpts.concatenateDescription,
                  vessel.layers[layerId].scheduled[0],
                );
                vessel.concatenateDescription = layerDescriptionValue;
              }
            }
            vesselListWithoutBrushContract.push(vessel);
            continue;
          }

          const vesselStandardLayers: LayerScheduleDrawingData[] = [];

          // capture the start of thevessel
          const vesselYStart = positionsParameters.currentHeight;

          // space for the start of the vessel
          const vesselStartMargin = this.chartingOptions.space;

          positionsParameters.currentHeight += vesselStartMargin;

          vessel.vesselBarHeight = vesselStartMargin;

          const vesselLayersData = Object.values(vessel.layers);

          const layersScheduled: { [drawingLayerId: string]: LayerScheduleDrawingData } = {};

          // If vessel has only overlay to display we will create a "classic" layer to draw all the overlays in it.
          const emptyLayerForOverlay = Object.values(vessel.layers).every(d => this.isOverlay(d.layerId));

          /*
           * Only check for non overlay data to init this boolean.
           * Because currently an overlay will be draw over classic layers
           * This boolean is used to know if some layer will make the vessel visible,
           * so as overlay won't be draw on a dedicated space
           * we can't take these layers in account.
           */
          const someLayerHasData = Object.values(vessel.layers).some(
            d => !this.isOverlay(d.layerId) && (d.scheduled.length || this.layers[d.layerId].options.alwaysVisible),
          );

          for (let layerIndex = 0; layerIndex < vesselLayersData.length; layerIndex++) {
            const layer = vesselLayersData[layerIndex];
            const layerId = layer.layerId;
            const drawingLayerId = layer.drawingLayerId;
            const globalLayerInfo = this.layers[layerId];

            const layerBeggining = positionsParameters.currentHeight;
            if (!globalLayerInfo.visible) {
              continue;
            }

            /*
             * If layer hasn't any data we won't draw it in filter mode. In 'order' mode we can't continue now because
             * we wan't to draw a gray line for this vessel. So will draw an empty layer.
             * However this empty layer is only necessary if no other layer has data.
             * Normally an overlay is drawn in superposition of standard(s) layer(s)
             * and therefore does not need to draw as a "standard" layer here.
             * but if vessel has only overlay to display (emptyLayerForOverlay is true)
             * we will create a "standard" layer to draw all the overlays in it.
             * We also introduced the concept of alwaysVisible layer (e.g. workpackage in a vessel page).
             * In this case we want to display
             * the layer no matter if the layer has data or not.
             */
            if (
              !globalLayerInfo.options.alwaysVisible
                && (layer.scheduled.length === 0 && (this.intervalMode === 'filter' || someLayerHasData))
              || (layer.layerType === 'overlay' && !emptyLayerForOverlay)
            ) {
              continue;
            }

            /*
             * We plot on one unique layer all layers with same drawingLayerId
             * If !layerScheduled[drawingLayerId] === true it's the first time we meet this drawingLayerId
             */
            if (!layersScheduled[drawingLayerId]) {
              layersScheduled[drawingLayerId] = {
                yPosition: layerBeggining,
                layerId: drawingLayerId,
                layerCircles: [],
                isLast: false,
                vesselId: vessel.shortTitle,
                hideCircle: !vessel.hasBrushedContracts,
                message: layer.message,
                sampling: this.layers[layerId].options.sampling,
              };
            } else {
              /*
               * In this case we've already positioned layers with this drawingLayerId
               * so we can skip this layer
               */
              continue;
            }
            const layerColors: { [layerId: string]: Color } = {};
            layerColors[layer.layerId] = globalLayerInfo.options.toggleColor;

            let vesselContracts = layer.scheduled;

            const layerScheduleDrawingData = layersScheduled[drawingLayerId];

            /*
             * As we need to plot in same visual layer all layers with same drawingLayerId
             * We iterate through all layers and collect data with current drawingLayerId
             */
            vesselLayersData.forEach(otherLayer => {
              if (otherLayer.layerId === layerId) {
                // current layer
                return;
              }
              if (otherLayer.drawingLayerId === drawingLayerId) {
                vesselContracts = [...vesselContracts, ...otherLayer.scheduled];
                const otherLayerOpts = this.layers[otherLayer.layerId];
                layerColors[otherLayer.layerId] = otherLayerOpts.options.toggleColor;
              }
            });

            // adding a space on the begging of the layer (if there is a separator)
            positionsParameters.currentHeight += this.chartingOptions.space;

            const sortedContracts = ScheduleTools.sortContracts(vesselContracts);
            let layerHasOverlap = false;
            let currentLayerBarHeight = positionsParameters.defaultBarHeight;

            const overlaps = ScheduleDrawingHelper.determineOverlapForVesselContracts(sortedContracts);

            /*
             * Determine the minimum size for a vessel bar. Some contracts can overlape.
             * We want to be sure that each contract has a minimum height of 15pixel.
             */
            let maxOverlapLines = 0;
            for (const overlap of overlaps) {
              const overlappingCount = overlap.contractsOnPosition.length;
              const overlapSpace = 1;
              const spacesCount = overlappingCount - 1;

              // we keep the track of what is the max number of overlapping vessel lines
              if (overlap.lines > maxOverlapLines) {
                maxOverlapLines = overlap.lines;
              }

              if (overlappingCount > 1) {
                layerHasOverlap = true;
              }

              // take the available width substruct all spaces and divide the rest by the count
              let currentItemHeightInOverlap = (currentLayerBarHeight - (overlapSpace * spacesCount))
                / overlappingCount;
              /*
               * determines if the size calculated for an overlapping item
               * is greater than the minimum required size (15 pixels)
               * if not it set this size to 15 pixels and increase the size and increase the total bar height
               */
              if (currentItemHeightInOverlap < 15) {
                currentItemHeightInOverlap = 15;
                currentLayerBarHeight = (currentItemHeightInOverlap + overlapSpace) * overlappingCount - overlapSpace;
              }
            }
            layerScheduleDrawingData.layerBarHeight = currentLayerBarHeight;

            if (!layerHasOverlap) {
              positionsParameters.numberOfVisibleVesselWithoutOverlap++;
            } else {
              positionsParameters.numberOfVisibleVesselWithOverlap++;
              // globally keep track of the maximum overlapping lines in a single vessel
              if (positionsParameters.maxOverlappingLines < maxOverlapLines) {
                positionsParameters.maxOverlappingLines = maxOverlapLines;
              }
            }

            contracts.push(
              ...this.getPositionedContracts(overlaps, layerScheduleDrawingData, positionsParameters, drawingSituation),
            );

            // add the calculated layer height and  space after
            positionsParameters.currentHeight += layerScheduleDrawingData.layerBarHeight + this.chartingOptions.space;

            const totalLayerHeight = positionsParameters.currentHeight - layerBeggining;

            // but we have to add it to the total of thelayer
            layerScheduleDrawingData.layerHeight = totalLayerHeight;

            vesselStandardLayers.push(layerScheduleDrawingData);

            // adding the layer height to the vessel height with the space after the layer
            vessel.vesselBarHeight += totalLayerHeight;

            /*
             * A drawn layer can be either a simple layer or a visual layer merging different layers
             * In both case we need to draw a circle for each layer inside this 'visual' layer
             * We iterate through each circle to draw and set x/y position
             * and circle color (depending the layer color in config)
             */
            let colorIndex = 0;
            const numberCircle = Object.keys(layerColors).length;
            // Order the found circles according to the layer order in the config
            const layerOrder = Object.keys(this.layers);
            const orderedColoredLayers = Object.keys(layerColors).sort(
              (a, b) => layerOrder.indexOf(a) - layerOrder.indexOf(b),
            );
            for (const layerCircleId of orderedColoredLayers) {
              const xPosition = this.chartingOptions.nameWidthYAxis // start at the name line (nameWidthYAxis)
                + LEGEND_ITEM_HOVER_TITLE_X_SHIFT // leave space for shifted legend item title on hover
                + (colorIndex - numberCircle / 2) * 15; // if multiple circles, make them overlap a bit
              const yPosition = layerScheduleDrawingData.yPosition + layerScheduleDrawingData.layerHeight / 2;

              const circleOpts: ScheduleLayerIcon = {
                id: `${vessel.id}-${layerCircleId}`,
                color: layerColors[layerCircleId],
                x: xPosition,
                y: yPosition,
                hideCircle: layerScheduleDrawingData.hideCircle,
              };
              layerScheduleDrawingData.layerCircles.push(circleOpts);
              colorIndex++;
            }

            /*
             * The vessel will be gray and its height will be a layer height
             *  so we want maximum one layer to be drawn
             *   we will skip any subsequent layers
             *  if we're in this case it means that concatenateEmptyLines is false,
             *  so there will be one line per vessel without brushed contracts
             */
            if (!vessel.hasBrushedContracts) {
              break;
            }
          } // end of the foreach layer loop

          /*
           * Assign boolean isLast true to the last layer.
           * This bool is mainly used when drawing this layer
           */
          if (vesselStandardLayers && vesselStandardLayers.length) {
            vesselStandardLayers[vesselStandardLayers.length - 1].isLast = true;
          }

          /*
           * end of the vessel
           * adding the vessel drawing data structure for the legend
           */
          const legendItem: VesselDrawingData = {
            fullTitle: vessel.fullTitle,
            /*
             * if the vessel has a description we will add an asterisk to the right
             * and later we will add on mouse over
             */
            shortTitle: vessel.description ? vessel.shortTitle + ' *' : vessel.shortTitle,
            description: vessel.description,
            // legend is a simple text and has to be placed in the middle of the bart which is to it's right
            textYPosition: vesselYStart + vessel.vesselBarHeight / 2,
            startY: vesselYStart,
            endY: vesselYStart + vessel.vesselBarHeight,
            standardLayers: vesselStandardLayers,
            isLast: isLast && (!vesselListWithoutBrushContract.length || !this.settings.concatenateEmptyLines),
            id: vessel.id,
            hasBrushedContracts: vessel.hasBrushedContracts,
            pageLink: vessel.unallocated !== true ? vessel.pageLink : null,
            disabledExclude: vessel.disabledExclude,
          };
          drawingVessels.push(legendItem);

          // update yPosition and possibily currentScroll in scheduleDrawingData
          this.addVesselToPositionDictAndCheckCurrentScroll(scheduleDrawingData, drawingSituation, group, legendItem);

          /*
           * once we have finished with all the standard layers we can
           * handle the overlays. They are placed over the whole vessel line
           */
          for (const layerId in vessel.layers) {
            const layer = vessel.layers[layerId];
            if (layer.layerType === 'overlay') {
              const scheduled: Scheduled[] = layer.scheduled.map(d => {
                return Object.assign(Object.create(d), {
                  dy: 0,
                  yPosition: legendItem.startY,
                  height: vessel.vesselBarHeight,
                  itemLabel: this.getContractLabel(d as Scheduled),
                  color: this.pickColor(d, drawingSituation.colorConfig),
                });
              });
              contracts.push(...scheduled);
              continue;
            }
          }
        }
      }
      /*
       * In case we have one or more vessels without brushed contracts
       * and the schedule has the option concatenateEmptyLines true,
       * in this case the vessels without brushed contracts will be concatenated
       * in a single item placed at the end of the group.
       */
      const numberVesselWithoutBrushContract = vesselListWithoutBrushContract.length;
      const additionalListHeight = 40;
      if (numberVesselWithoutBrushContract && this.settings.concatenateEmptyLines) {
        const itemType = pluralize(this.settings.lineEntityName);
        const moreVesselLegendItem: VesselDrawingData = {
          fullTitle: '',
          shortTitle: `${numberVesselWithoutBrushContract} ${itemType} outside selected interval* `,
          description: `${capitalize(itemType)} outside of selected interval: `,
          textYPosition: positionsParameters.currentHeight + additionalListHeight / 2,
          startY: positionsParameters.currentHeight,
          endY: positionsParameters.currentHeight + additionalListHeight,
          standardLayers: [],
          isLast: true,
          id: `nonVisible - ${group.id} `,
          concatenatedItemList: orderBy(vesselListWithoutBrushContract, v => v.concatenateDescription, 'desc').map(
            v => v.concatenateDescription ? `${v.fullTitle} - ${v.concatenateDescription} ` : v.fullTitle,
          ),
          disabledExclude: true,
        };
        // If the concatenate list contains only one vessel we will display its info
        if (vesselListWithoutBrushContract.length === 1) {
          const vesselWithoutContract = vesselListWithoutBrushContract[0];
          vesselWithoutContract.description = vesselWithoutContract.concatenateDescription
            ? `${vesselWithoutContract.concatenateDescription} `
            : vesselWithoutContract.description;
          moreVesselLegendItem.fullTitle = vesselWithoutContract.fullTitle;
          moreVesselLegendItem.shortTitle = vesselWithoutContract.description
            ? vesselWithoutContract.shortTitle + ' *'
            : vesselWithoutContract.shortTitle;
          moreVesselLegendItem.description = vesselWithoutContract.description;
          moreVesselLegendItem.hasBrushedContracts = false;
          moreVesselLegendItem.concatenatedItemList = null;
          moreVesselLegendItem.pageLink = vesselWithoutContract.unallocated !== true
            ? vesselWithoutContract.pageLink
            : null;
          // If we have only one vessel we can exclude this vessel
          moreVesselLegendItem.id = vesselWithoutContract.id;
          moreVesselLegendItem.disabledExclude = vesselWithoutContract.disabledExclude ? true : false;
          // if this vessel has overlay we take it in account here
          for (const layerId in vesselWithoutContract.layers) {
            const layer = vesselWithoutContract.layers[layerId];
            if (layer.layerType === 'overlay') {
              const scheduled: Scheduled[] = layer.scheduled.map(d => {
                return Object.assign(Object.create(d), {
                  dy: 0,
                  yPosition: moreVesselLegendItem.startY,
                  height: additionalListHeight,
                  itemLabel: this.getContractLabel(d as Scheduled),
                  color: this.pickColor(d, drawingSituation.colorConfig),
                });
              });
              contracts.push(...scheduled);
              continue;
            }
          }
        }
        drawingVessels.push(moreVesselLegendItem);
        // update yPosition and possibily currentScroll in scheduleDrawingData
        this.addVesselToPositionDictAndCheckCurrentScroll(
          scheduleDrawingData,
          drawingSituation,
          group,
          moreVesselLegendItem,
        );

        positionsParameters.currentHeight += additionalListHeight;
      }

      /*
       * if the group has been fully handle we can add the groupFooterSize
       * otherwise it could be added multiple times
       */
      if (group.alreadyTreated) {
        positionsParameters.currentHeight += this.chartingOptions.groupFooterSize;
      }

      // if the user specified list of rigs then we should keep that order otherwise we sort by the vassels by name
      const rigOrder = drawingSituation.rigOrder;
      const sortedDrawingVessels = rigOrder
        ? drawingVessels.sort((a, b) => rigOrder.indexOf(a.fullTitle) - rigOrder.indexOf(b.fullTitle))
        : drawingVessels;

      group.groupYStart = groupYStart;
      group.titleYPosition = groupYStart + this.chartingOptions.groupHeaderSize;
      group.titleLength = textWidth(group.title, 16), group.allScheduled = contracts;
      group.count = groupCount;
      group.groupVessels = sortedDrawingVessels;
      group.groupSeparationLine = groupSeparationLine;
      group.groupYEnd = positionsParameters.currentHeight;
    }
  }

  /**
   * Goes over all contracts and figures out the vertical position (py). Iterates over list of OverlapRegions
   * Each overlap region contains over or multiple contracts. Overlap region can have multiple lines and each line
   * can have multiple contracts
   */
  public getPositionedContracts(
    overlaps: OverlapRegion[],
    layer: LayerScheduleDrawingData,
    positionsParameters: DrawingPositionParameters,
    drawingSituation: ScheduleDrawingSituation,
  ): Scheduled[] {
    const contracts: Scheduled[] = [];

    let overlapsPixelEnd = 0;

    for (let overlapIndex = 0; overlapIndex < overlaps.length; overlapIndex++) {
      const overlap = overlaps[overlapIndex];
      const overlappingCount = overlap.contractsOnPosition.length;
      const overlapSpace = 1;
      const spacesCount = overlappingCount - 1;
      const nextOverlap = overlaps[overlapIndex + 1];

      // take the available height substruct all spaces and divide the rest by the count
      const itemHeightInOverlap = (layer.layerBarHeight - (overlapSpace * spacesCount)) / overlappingCount;

      // go over all the inner lines (inside the overlap) and set the dy and height of each contract inside
      for (let i = 0; i < overlap.contractsOnPosition.length; i++) {
        const singleLine = overlap.contractsOnPosition[i];
        const contractNumber = singleLine.contracts.length;

        // finally iterate over the scheduledItems (aka contracts)
        for (let j = 0; j < contractNumber; j++) {
          const scheduledItem = singleLine.contracts[j] as Scheduled;
          scheduledItem.over = {
            position: singleLine.position,
            count: overlappingCount,
          };

          /*
           * if current contract is a punctual Contract we check if we have extra space after this contract
           * If we have we gonna extent the space available for this contract to display more contract info.
           * Anyway, we will correct its dateEnd based on available space.
           */
          if ((j === contractNumber - 1) && scheduledItem.punctualShape && scheduledItem.show) {
            const maxDate = drawingSituation.currentExtent ? drawingSituation.currentExtent[1] : null;
            // To determine available space we check the next overlap start date or the current brush max
            const overlapTimelapseSpace = nextOverlap ? Math.min(maxDate, nextOverlap.start) : maxDate;
            if (overlapTimelapseSpace && overlapTimelapseSpace > overlap.end) {
              overlap.end = overlapTimelapseSpace - 1;
            }
            scheduledItem.dateEnd = overlap.end;
          }

          /*
           * Sample out scheduled items if the overlap has only 1 item
           * and if layer was not already sample during the filtering
           */
          if (
            overlappingCount === 1
            && !layer.sampling
            && scheduledItem.dateEnd != null
          ) {
            /*
             * Figure out the end pixel of each contract. If the current overlap has only 1 scheduled item
             * it means there is no overlap - than we can say that if the endPixel of the contract that we are adding
             * would be before or on the same position as the currentPixel
             * (which is the end pixel of last added contract)
             * then we can drop this scheduled-item (it won't be drawn)
             * we can't do the same for overlaping regions that have multiple contracts
             */
            const pixelEnd = Math.round(drawingSituation.scale(scheduledItem.dateEnd));

            if (overlapsPixelEnd > 0 && overlapsPixelEnd >= pixelEnd) {
              continue;
            }
            overlapsPixelEnd = Math.max(pixelEnd, overlapsPixelEnd);
          }

          scheduledItem.yPosition = positionsParameters.currentHeight;
          scheduledItem.dy = (singleLine.position - 1) * (itemHeightInOverlap + overlapSpace);
          scheduledItem.height = itemHeightInOverlap;
          scheduledItem.itemLabel = this.getContractLabel(scheduledItem);
          scheduledItem.color = this.pickColor(scheduledItem, drawingSituation.colorConfig);
          contracts.push(scheduledItem);
        }
      }
    }
    return contracts;
  }

  /**
   * Prepare the data structure to set scheduleDrawingData
   * The goal is to do this step once while the update function can be called several times.
   * With the introduction of heavy layers on the schedule We can add data progressively in scheduleDrawingData.
   * That's why you have to separate the initialization from the update of the datastructure
   */
  public prepareScheduleDrawingData(
    groups: GroupData[],
    drawingSituation: ScheduleDrawingSituation,
  ): ScheduleDrawingData {
    const groupsWithVisibleVessels = groups.filter(g => this.visibleVesselsCount(g) >= 1);
    // order groups by number of visible vessels or according to groupby order config
    const orderedGroups = this.groupOrder(groupsWithVisibleVessels, drawingSituation.groupBy);

    // we need the total of visible vessels to determine the hight of an item
    const visibleVesselsCount = sumBy(orderedGroups, g => this.visibleVesselsCount(g));

    const groupDrawingData: GroupDrawingData[] = [];
    for (const group of orderedGroups) {
      let orderedVisibleVessels = [] as ScheduleVesselData[];
      orderedVisibleVessels = this.orderVesselsInGroup(group);

      // this group has only vessels that are not visible let's get out
      if (orderedVisibleVessels.length === 0) {
        continue;
      }
      groupDrawingData.push({
        groupYStart: 0,
        id: group.id,
        title: group.title,
        allScheduled: [],
        orderedVessels: orderedVisibleVessels,
        scheduledVisibleInDrawingArea: [],
        count: 0,
        titleYPosition: 0,
        titleLength: 0,
        groupSeparationLine: 0,
        groupVessels: [],
        vesselsInDrawingArea: [],
        groupYEnd: 0,
        alreadyTreated: false,
        collapsed: drawingSituation.collapsedGroupIds.includes(group.id),
      });
    }

    const positionParameters: DrawingPositionParameters = {
      // this is the counter and helps as prepare the y coordinates for all the groups and items inside
      currentHeight: 0,
      // This barHeight is the default barHeight
      defaultBarHeight: ScheduleDrawingHelper.theoreticalBarHeight,
      numberOfVisibleVesselWithoutOverlap: 0,
      numberOfVisibleVesselWithOverlap: 0,
      maxOverlappingLines: 0,
    };

    // if the barHeight is fixed - coming from the config, then we don't try to determine the best height
    if (this.chartingOptions.barHeight && this.chartingOptions.barHeight !== 'auto') {
      positionParameters.defaultBarHeight = this.chartingOptions.barHeight;
    }

    return {
      groups: groupDrawingData,
      height: positionParameters.currentHeight,
      visibleVessels: visibleVesselsCount,
      positionParameters: positionParameters,
      yPositionToVessels: [],
    };
  }

  public addXaxisLoadingShadow(schedule: ScheduleComponent): void {
    schedule.scrollLoading = true;
    schedule.container.select('.xaxis')
      .attr('class', 'xaxis xaxis-box-shadow');
    schedule.cdRef.detectChanges();
  }

  public removeXaxisLoadingShadow(schedule: ScheduleComponent): void {
    schedule.scrollLoading = false;
    schedule.container.select('.xaxis')
      .attr('class', 'xaxis');
    schedule.cdRef.detectChanges();
  }

  /**
   * With the introduction of the notion of heavy layer,
   * we will be able to add data progressively in the datastructure of scheduleDrawingData.
   * Overall, the function works as follows:
   * First we look at the boats to be drawn.
   * The function looks if this data is cached. If not it loads the missing data, the data is then cached
   * Then this method will determine the position of each element for the schedule.
   * First it determines the position with a default bar height
   * but it possible that the bar height change for each boat because the bar height is calculate dynamically.
   * Then when the function knows exactly the space available it will try to optimise this space.
   *
   * We pass the following information to the function:
   *   - ScheduleDrawingData: the datastructure where the information about the data plot is stored.
   *     This is the parameter that is updated at each call of the function
   *   - drawingSituation: drawing parameters (information about spacing, number of vessels to load etc...)
   *   - scheduleLayerLoader: class used to load the layer schedule data
   *   - filteringDataFunction: function used to be able to apply once all the front filtering
   *     (sidebar filters + tab filter + interval filter)
   *   - currentRedrawPromises: datastructure where are stored the promises to load data.
   *     During redraw conflicts we have to potentially cancel these promises
   */
  public async updateGroupDrawingData(
    scheduleDrawingData: ScheduleDrawingData,
    drawingSituation: ScheduleDrawingSituation,
    scheduleLayerLoader: ScheduleLayerLoader,
    filteringDataFunction: (layer: ScheduleLayer) => ScheduleLike[],
    schedule: ScheduleComponent,
  ): Promise<void> {
    const opts = this.chartingOptions;
    const positionParameters = scheduleDrawingData.positionParameters;

    // if the barHeight is fixed - coming from the config, then we don't try to determine the best height
    if (this.chartingOptions.barHeight && this.chartingOptions.barHeight !== 'auto') {
      positionParameters.defaultBarHeight = this.chartingOptions.barHeight;
    }

    const heavySchedule = Object.values(this.layers).some(layer => layer.visible && layer.options.vesselOrderUrl);

    if (heavySchedule) {
      this.addXaxisLoadingShadow(schedule);
    }
    await this.determineVesselsToBeLoaded(
      scheduleDrawingData,
      drawingSituation,
      scheduleLayerLoader,
      filteringDataFunction,
    );

    this.removeXaxisLoadingShadow(schedule);

    // first round determines the position of each element
    this.setAllContractPositionsInGroup(scheduleDrawingData, positionParameters, drawingSituation);

    /*
     * We calcule the space available for the contract without any overlap
     * taking out space for groups and overlaps we will see what rests for vessels
     */
    const spaceNeededForGroups = scheduleDrawingData.groups.length * (
      this.chartingOptions.groupHeaderSize + 3 * this.chartingOptions.space
    );

    // the minimum height for a line inside an overlap is 15
    const minSpaceNeededForOverlap = positionParameters.maxOverlappingLines * 15;

    const totalNumberOfLines = positionParameters.numberOfVisibleVesselWithoutOverlap
      + positionParameters.numberOfVisibleVesselWithOverlap;

    const spacesBetweenLines = totalNumberOfLines * opts.space * 4;
    const availableSpace = drawingSituation.timelineHeight - 5 - spaceNeededForGroups - spacesBetweenLines;

    /*
     * height take by contract with overlap
     * (this size is overestimated because we take the max overlap lines as reference)
     */
    const contractWithOverlapHeight = positionParameters.numberOfVisibleVesselWithOverlap * minSpaceNeededForOverlap;
    // Height take by contract without overlap
    const contractWithoutOverlapHeight = positionParameters.defaultBarHeight
      * positionParameters.numberOfVisibleVesselWithoutOverlap;

    const contractSpaceTake = contractWithOverlapHeight + contractWithoutOverlapHeight;

    /*
     * if there is still place available we will try to calculate the ideal barheight to fill all the free space
     *  for now we disabled this capacity for a heavy schedule
     */
    if (contractSpaceTake < availableSpace) {
      // we calculate the ideal bar height
      const calculatedBarHeight = Math.round(availableSpace / totalNumberOfLines);

      // we never want more then 120px per line otherwise it's  getting ridiculous
      const barHeightAndSpace = Math.min(calculatedBarHeight, 120);

      positionParameters.defaultBarHeight = barHeightAndSpace;
      positionParameters.currentHeight = 0;
      scheduleDrawingData.groups.forEach(group => {
        group.alreadyTreated = false;
        group.alreadyStarted = false;
        group.orderedVessels.forEach(vessel => {
          vessel.alreadyTreated = false;
        });
        group.groupVessels = [];
      });
      scheduleDrawingData.yPositionToVessels = [];
      this.setAllContractPositionsInGroup(scheduleDrawingData, positionParameters, drawingSituation);
    }
    scheduleDrawingData.height = positionParameters.currentHeight;
  }

  /**
   * Function used to add a vesselDrawingData to yPositionToVessels dict
   * We keep in yPositionToVessels a mapping that will return the vessel for its yPosition
   * it might be useful to retrieve a vessel associated with a current yPosition
   * We also check if the vessel we add isn't the vesselToTarget, if so we update newCurrentScrool
   * with the vessel yPosition
   */
  public addVesselToPositionDictAndCheckCurrentScroll(
    scheduleDrawingData: ScheduleDrawingData,
    drawingSituation: ScheduleDrawingSituation,
    group: GroupDrawingData,
    vesselDrawingData: VesselDrawingData,
  ): void {
    scheduleDrawingData.yPositionToVessels.push({
      groupId: group.id,
      vesselId: vesselDrawingData.id,
      // we take the middle of the vessel height as yPosition reference
      yPosition: (vesselDrawingData.startY + vesselDrawingData.endY) / 2,
    });
    if (
      drawingSituation.vesselToTarget
      && drawingSituation.vesselToTarget.groupId === group.id
      && drawingSituation.vesselToTarget.vesselId === vesselDrawingData.id
    ) {
      scheduleDrawingData.newCurrentScroll = vesselDrawingData.startY;
    }
  }

  /**
   * Function iterate trough each vessel to display in schedule
   * If vessel is alreadyTreated (which means that it is already drawn in the schedule) we can skip it
   * otherwise we will get the data from this vessel.
   * We limit the number of vessels for which we retrieve the data by maxLoadableVesselNumber (usually 50)
   * Once the data is retrieved we inject it in the datastructure which contains the vessels to draw.
   */
  public async determineVesselsToBeLoaded(
    scheduleDrawingData: ScheduleDrawingData,
    drawingSituation: ScheduleDrawingSituation,
    scheduleLayerLoader: ScheduleLayerLoader,
    filteringDataFunction: (layer: ScheduleLayer) => ScheduleLike[],
  ): Promise<void> {
    for (const layerId in this.layers) {
      const layer = this.layers[layerId];
      if (layer.visible && layer.options.vesselOrderUrl) {
        const isHeavyLayerCacheInvalid = this.invalidHeavyLayerCaches.has(layerId);
        // Init heavy layer cache or clear it if it was marked as invalid
        if (!this.heavyLayersCache[layerId] || isHeavyLayerCacheInvalid) {
          this.heavyLayersCache[layerId] = {};
          this.invalidHeavyLayerCaches.delete(layerId);
        }
        // Number of vessel we have to load from the target (by default we should load 50 vessel from the target)
        let vesselCountFromTarget = 0;
        // total number of vessel for which we will ask the data
        let loadedVesselsCount = 0;
        const vesselNeededLayerData = {};
        const managerNeedAwards = [];
        const numberVesselToLoadFromTarget = drawingSituation.numberVesselToLoadFromTarget
          ? drawingSituation.numberVesselToLoadFromTarget
          : 50;
        /*
         * In some redraw case we can have a target vessel,
         * it means we want to retrieve the vessel we were looking at before the redraw
         * at this moment we will iterate through each vessel until we found the new position of this vessel
         */
        let vesselToTargetFound = drawingSituation.vesselToTarget ? false : true;
        for (const group of scheduleDrawingData.groups) {
          // We have already reached the maximum number of vessels to load or the group is collapsed
          if (
            vesselCountFromTarget > numberVesselToLoadFromTarget || loadedVesselsCount > MAX_SAFE_LOADABLE_VESSELS
            || drawingSituation.collapsedGroupIds.includes(group.id)
          ) {
            continue;
          }
          vesselNeededLayerData[group.id] = {};
          for (const vessel of group.orderedVessels) {
            if (
              drawingSituation.vesselToTarget && drawingSituation.vesselToTarget.groupId === group.id
              && drawingSituation.vesselToTarget.vesselId === vessel.id
            ) {
              vesselToTargetFound = true;
            }
            /*
             * alreadyTreated: the vessel has already been treated (already drawn in the schedule)
             * vesselCountFromTarget > numberVesselToLoadFromTarget:
             *    We found our target and we load max loadable vessel from this target
             * loadedVesselsCount > MAX_SAFE_LOADABLE_VESSELS:
             *    Case where we were looking for a target vessel but we have to load too many data to find it
             *    so we just we remove the vesselToTarget and stop load more vessels
             */
            if (
              vessel.alreadyTreated
              || vesselCountFromTarget > numberVesselToLoadFromTarget
              || loadedVesselsCount > MAX_SAFE_LOADABLE_VESSELS
            ) {
              continue;
            }
            vesselNeededLayerData[group.id][vessel.id] = { data: null, vessel: vessel };

            /*
             * If we have a vesselToTarget we don't stop load vessels until we found this vessel
             * (or at least we finish the group of this supposed vessel)
             * so we don't increment vessel count until we found this vessel
             */
            if (!drawingSituation.vesselToTarget || vesselToTargetFound) {
              vesselCountFromTarget++;
              if (vesselCountFromTarget === numberVesselToLoadFromTarget) {
                vessel.lastToTreat = true;
              }
            }

            // case where the vessel data has already been loaded during a previous redraw
            if (this.heavyLayersCache[layerId][vessel.id]) {
              vesselNeededLayerData[group.id][vessel.id] = {
                vessel: vessel,
                data: this.heavyLayersCache[layerId][vessel.id],
              };
              continue;
            } else {
              loadedVesselsCount++;
              /*
               * We are loading too many vessel we stop looking for the target
               * and we just load the first MAX_SAFE_LOADABLE_VESSELS vessels
               */
              if (loadedVesselsCount === MAX_SAFE_LOADABLE_VESSELS) {
                vesselToTargetFound = true;
                drawingSituation.vesselToTarget = null;
                vessel.lastToTreat = true;
              }
            }
            /*
             * Case where the vessel is unallocated
             * in this case the vessel corresponds to a manager unallocated awards
             * so we collect the managerId and ask award for this manager
             */
            if (vessel.unallocated === true) {
              const managerId = vessel.id.replace(' awards / intel', '');
              if (Number(managerId)) {
                managerNeedAwards.push(managerId);
              }
            }
          }
          /*
           * Case where the target vessel isn't in group anymore (can happen with a retired vessel for example)
           * as we finished to go trough its corresponding group
           * we can consider the first vessel group as the new target vessel
           */
          if (
            drawingSituation.vesselToTarget
            && drawingSituation.vesselToTarget.groupId === group.id
            && !vesselToTargetFound
          ) {
            if (group.groupVessels.length) {
              drawingSituation.vesselToTarget.vesselId = group.groupVessels[0].id;
            } else {
              // The target vessel group hasn't vessel anymore we just remove vesselToTarget and keep current scroll
              drawingSituation.vesselToTarget = null;
            }
            vesselToTargetFound = true;
          }
        }

        const vesselNeedHeavyDataLoadDictionary = {};
        Object.values(vesselNeededLayerData).forEach(group => {
          Object.keys(group).filter(vesselId => !group[vesselId].data && Number(vesselId)).forEach(vesselId => {
            vesselNeedHeavyDataLoadDictionary[vesselId] = true;
          });
        });
        const vesselNeedHeavyDataLoad = Object.keys(vesselNeedHeavyDataLoadDictionary);
        /*
         * If we have data to load (vessel or manager) we load it,
         * but it's possible to be in case where all the data to display is already loaded
         */
        if (vesselNeedHeavyDataLoad.length || managerNeedAwards.length) {
          const heavyDataPromise = scheduleLayerLoader.getScheduleVesselHeavyData(
            layer.layerId,
            vesselNeedHeavyDataLoad,
            managerNeedAwards,
            isHeavyLayerCacheInvalid, // if the heavy layer cache was marked invalid, also force update the heavy data
          );
          const heavyData = await heavyDataPromise;

          const layerIdProperty = layer.options.idProperty;

          heavyData.forEach(d => {
            const propId = layerIdProperty
              ? getChained<string>(d, layerIdProperty)
              : getChained<string>(d, this.settings.idProperty);
            if (!this.heavyLayersCache[layerId][propId]) {
              this.heavyLayersCache[layerId][propId] = [];
            }
            /*
             * We have to apply the necessary post process for data before plot
             * we populate the cache datastructure
             */
            this.heavyLayersCache[layerId][propId].push(
              ScheduleLayerLoader.processDataForSchedule(d, layer.layerId, layer.options),
            );
          });
        }

        /*
         * We make a copy of the current layer. This object will be used to filter
         * new loaded data. We clone the layer to avoid to affect based layer data
         */
        const layerWithNewData = cloneDeep(layer);
        for (const groupId in vesselNeededLayerData) {
          for (const vesselId in vesselNeededLayerData[groupId]) {
            /*
             * We get from the heavyLayersCache the data corresponding to the vesselId and we same groupId value
             * because depending the groupBy value a vessel can have several rows in schedule
             */
            vesselNeededLayerData[groupId][vesselId].data = this.heavyLayersCache[layerId][vesselId]?.filter(d =>
              this.getGroupName(d, drawingSituation.groupBy) === groupId
            );
            /*
             * Case where the vessel has no data on heavy layer
             * we can just skip to next vessel
             */
            if (!vesselNeededLayerData[groupId][vesselId].data) {
              continue;
            }
            // We assign to the layer to filter the loaded data
            layerWithNewData.data = vesselNeededLayerData[groupId][vesselId].data;
            const filteredData = filteringDataFunction(layerWithNewData);
            const vesselToComplete = vesselNeededLayerData[groupId][vesselId].vessel;
            if (!vesselToComplete || !vesselToComplete.layers[layerId]) {
              console.warn(
                `Issue with ${layerId} heavy data - We found data for vesselId ' ${vesselId} '
                but scheduleVesselOrderer didn't return anything for this vessel,
                look the difference between both endpoints`,
              );
              continue;
            }
            filteredData.forEach(d => this.getElementLegendColor(d, drawingSituation.colorBy));
            vesselToComplete.layers[layerId].scheduled = filteredData;
          }
        }
      }
    }
  }

  /**
   * Takes a list of scheduled items and determines overlaps if there are any.
   * Creates a list of overlapping regions, each region contains at least one contract
   */
  public static determineOverlapForVesselContracts(scheduledItems: ScheduleLike[]): OverlapRegion[] {
    let overlaps: OverlapRegion[] = [];

    // go over all contracts for given vessel
    for (let i = 0; i < scheduledItems.length; i++) {
      const d = scheduledItems[i];

      const contract = d as ScheduledOverlap;

      // by default there is no overlap, that means this is the first contract of total 1
      contract.over = {
        count: 1,
        position: 1,
      };

      // initialize overlapping checker for given vessel, it holds current overlapping region
      if (overlaps.length === 0) {
        overlaps = [{
          start: d.dateStart,
          end: this.endGetter(d),
          lines: 1,
          contractsOnPosition: [{
            position: 1,
            contracts: [contract],
          }],
        }];
      } else {
        const numberOfOverlap = overlaps.length;
        const currentOverlap = overlaps[numberOfOverlap - 1];

        /*
         * contract without dateStart
         * won't be drawn so we don't have to worry about overlaps
         */
        if (!contract.dateStart) {
          continue;
        }

        // new contract starts after previous overlap, so the overlap is re-initialized
        if (currentOverlap.end && contract.dateStart > currentOverlap.end) {
          const newOverlap: OverlapRegion = {
            start: contract.dateStart,
            end: this.endGetter(contract),
            lines: 1,
            contractsOnPosition: [{
              position: 1,
              contracts: [contract],
            }],
          };
          overlaps.push(newOverlap);
        } else {
          // new contract starts before the end of the overlap - conflict
          currentOverlap.end = Math.max(this.endGetter(contract), currentOverlap.end);
          ScheduleDrawingHelper.placeIntoPositions(currentOverlap, contract);
        }
      }
    }
    return overlaps;
  }

  public static plotTodayLine<Datum, PElement extends SelectionBaseType, PDatum>(
    svg: Selection<SVGElement, Datum, PElement, PDatum>,
    height: number,
    x: number,
    clip: string,
    y: number = 0,
  ): Selection<SVGLineElement, Datum, PElement, PDatum> {
    return svg.append('line')
      .attr('class', 'todayLine')
      .attr('clip-path', 'url(#' + clip + ')')
      .attr('x1', x)
      .attr('y1', y)
      .attr('x2', x)
      .attr('y2', height)
      .style('stroke-width', 2)
      .style('stroke', '#757575')
      .style('stroke-dasharray', '4, 4');
  }

  public static plotNameLine(
    svg: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
    height: number,
    x: number,
    completeRedraw: (changeyAxis: number) => Promise<void>,
    y: number = 0,
  ): void {
    let isDragging: boolean = false;

    const dragBehavior = drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);
    const node_data = { x: 0, y: 0 };

    // Delete all old lines before creating a new one
    svg.selectAll('.namespaceline').remove();

    function dragEnded(this: SVGRectElement): void {
      isDragging = false;
      if (node_data.x !== 0) {
        completeRedraw(node_data.x);
      }
    }

    function dragStarted(this: SVGRectElement, event: D3DragEvent<SVGRectElement, undefined, unknown>): void {
      isDragging = true;
      (event.sourceEvent as Event).stopPropagation();
    }

    /**
     * Listener for the d3 drag event, invoked with the `this` context as the current DOM element of the selection.
     */
    function dragged(this: SVGRectElement, event: D3DragEvent<SVGRectElement, undefined, unknown>): void {
      // lock the bar horizontally in range 130 <> 500 px
      const svgWidth = svg.node().clientWidth;
      const maxWidth = svgWidth > 500 ? 500 : svgWidth - 50;
      const mouseX = event.x;
      if (x + mouseX < 50 || x + mouseX > maxWidth) {
        return;
      }
      node_data.x = mouseX;
      // Translate the object on the actual moved point
      select(this).attr('transform', svgAttrTranslate(node_data.x, node_data.y));
    }

    function handleMouseLeave(): void {
      if (isDragging) {
        return;
      }
      select('.namespaceline').transition().duration(600).style('opacity', 0);
    }

    function handleMouseEnter(): void {
      select('.namespaceline').transition().duration(200).style('opacity', 1);
    }

    svg.append('rect')
      .attr('class', 'namespaceline')
      .attr('x', x)
      .attr('y', y + 15)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', height)
      .attr('width', 7)
      .style('fill', 'rgb(63,63,63)')
      .style('stroke-width', '4')
      .style('stroke', 'rgba(0,0,0,0)')
      .style('cursor', 'col-resize')
      .style('opacity', 0)
      .classed('draggable', true)
      .on('mouseenter', handleMouseEnter)
      .on('mouseleave', handleMouseLeave);

    // Another Invisible bar to increase hitbox size on the left
    svg.append('rect')
      .attr('class', 'namespaceline')
      .attr('x', x - 7)
      .attr('y', y + 15)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', height)
      .attr('width', 7)
      .style('fill', 'rgb(63,63,63)')
      .style('stroke-width', '4')
      .style('stroke', 'rgba(0,0,0,0)')
      .style('cursor', 'auto')
      .style('opacity', 0)
      .on('mouseenter', handleMouseEnter)
      .on('mouseleave', handleMouseLeave);

    // Another Invisible bar to increase hitbox size on the right
    svg.append('rect')
      .attr('class', 'namespaceline')
      .attr('x', x + 7)
      .attr('y', y + 15)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', height)
      .attr('width', 7)
      .style('fill', 'rgb(63,63,63)')
      .style('stroke-width', '4')
      .style('stroke', 'rgba(0,0,0,0)')
      .style('cursor', 'auto')
      .style('opacity', 0)
      .on('mouseenter', handleMouseEnter)
      .on('mouseleave', handleMouseLeave);

    /*
     * Set the drag behavior on the objects having the "draggable" class
     * and set their position on the viewport (by the "node_data" matrix)
     */
    svg.select('.draggable').call(dragBehavior).data([node_data]);
  }

  public redrawWindow(
    svg: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
    window: TimeWindow,
    svgWidth: number,
    height: number,
    xScale: ScaleLinear<number, number>,
    clip: string,
  ): void {
    if (!window || !window.startDate || !svg) {
      return;
    }

    const startingX = xScale(window.startDate as any);
    const endingX = window.endDate ? xScale(window.endDate as any) : svgWidth;
    const width = endingX - startingX;
    svg.selectAll('.' + window.name + '-window').remove();
    svg.append('rect')
      .attr('class', window.name + '-window')
      .attr('clip-path', 'url(#' + clip + ')')
      .attr('x', startingX)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('stroke-width', 1)
      .style('stroke', window.color)
      .style('fill', window.color)
      .style('opacity', 0.25)
      .style('pointer-events', 'none');
  }

  public removeWindow(
    svg: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
    name: ScheduleWindowName | string,
  ): void {
    if (!svg) {
      return;
    }
    svg.selectAll('.' + name + '-window').remove();
    this.windows[name] = null;
  }

  public getConfigMinDate(now: Dayjs, settings: ScheduleGraphSettings): number {
    const currentBrush = now.clone();
    const hardMinimumDate = settings.hardMinimumDate ? dayjs(settings.hardMinimumDate).valueOf() : null;
    const relativeMinimumDate = settings.relativeMinimumDate
      ? currentBrush.add(settings.relativeMinimumDate, 'month').valueOf()
      : null;

    if (!hardMinimumDate && !relativeMinimumDate) {
      return null;
    }
    if (!hardMinimumDate) {
      return relativeMinimumDate;
    }
    if (!relativeMinimumDate) {
      return hardMinimumDate;
    }
    return Math.max(hardMinimumDate, relativeMinimumDate);
  }

  public static getBarWidth(d: Scheduled, scale: ScaleLinear<number, number>, today: number): number {
    if (d.dateStart === null) {
      return 0;
    }

    const xStart = scale(d.dateStart);
    // If the end date of a contract is null we consider it to be today
    const xEnd = !d.dateEnd ? today : scale(d.dateEnd);
    const result = xEnd - xStart;

    /*
     * this is the case when the contract starts in the future, but doesnt have end day
     * so the end they would be set to today making it invisible - here we give it few pixels
     */
    if (result < 0) {
      // Creating a "pseudo" date end for visual purpose
      d.visualDateEnd = scale.invert(xStart + ScheduleDrawingHelper.defaultFutureContractBarWidth);
      return ScheduleDrawingHelper.defaultFutureContractBarWidth;
    }

    return result;
  }

  public sortColors(colorsList: Color[], colorBy: string, layerId: string): void {
    let colorsConfig: ColorDefinition[];

    const layer = this.layers[layerId];

    // if the layer has a specific fixed color by config we take this config
    if (layer && layer.options.fixedColorBy) {
      colorsConfig = layer.options.fixedColorBy.colors;
    } else { // otherwise we take the schedule group by config
      colorsConfig = this.settings.selects.splitby?.colors?.[colorBy];
    }

    // sort the list of the colors if there is a config
    if (colorsConfig) {
      const colorsOrder = colorsConfig.map(color => color.id);

      // first in the list goes to the end, what is not in the list goes first (because we are drawing from the right)
      colorsList.sort((a: Color, b: Color) => {
        if (colorsOrder.indexOf(a) === -1) {
          return 1;
        }
        if (colorsOrder.indexOf(b) === -1) {
          return -1;
        }
        return colorsOrder.indexOf(a) - colorsOrder.indexOf(b);
      });
    }
  }

  public getColorForValue(value: string, config: ColorConfig, layerId: string): ColorDefinition {
    const matchingColor = layerId in config.layerColorsCache
      && config.layerColorsCache[layerId]?.find(color => color.id === value);

    if (matchingColor) {
      return matchingColor;
    }

    let colorDefinition: ColorDefinition = null;

    const colorsFromSettings = (this.settings.selects.splitby && this.settings.selects.splitby.colors)
      ? this.settings.selects.splitby.colors[config.colorBy]
      : undefined;

    if (colorsFromSettings) {
      colorDefinition = colorsFromSettings.find(color => color.id === value);
    }

    const layer = this.layers[layerId];
    const globalColorBy = (!layer.options.fixedColorBy && config.colorBy) ? true : false;

    if (!colorDefinition && globalColorBy) {
      for (const layerId in config.layerColorsCache) {
        if (colorDefinition) {
          continue;
        }
        const layerColors = config.layerColorsCache[layerId];
        Object.keys(layerColors).forEach(key => {
          if (!colorDefinition && key === value) {
            colorDefinition = layerColors.find(color => color.id === key);
            return;
          }
        });
      }
    }

    /*
     * If the color was not found - neither in the direct mapping in the config,
     * neither due to the dynamic colorBy of the component we will pick a color.
     * Special values 'Yes', 'No' and 'Not available' strings are handled here,
     * otherwise we pick from palette.
     */
    if (!colorDefinition) {
      if (value === NOT_AVAILABLE || value === 'No') {
        colorDefinition = { id: value, fill: ColorHelper.getNotAvailableColor() };
      } else if (value === 'Yes') {
        colorDefinition = { id: value, fill: SPINERGIE_DEFAULT_GREEN };
      } else {
        colorDefinition = { id: value, fill: this.colorScale(value) };
      }
    }

    if (!config.layerColorsCache[layerId]) {
      config.layerColorsCache[layerId] = [];
    }

    config.layerColorsCache[layerId].push(colorDefinition);

    return colorDefinition;
  }

  /**
   * Get a color of a single item which is drawn - either there is one fixed per layer
   * or the values are fixed in the config or we pick in the default palette
   */
  public pickColor(d: ScheduleLike, config: ColorConfig): ColorDefinition {
    const layerId = d.layerId;
    const fixedColorBy = this.layerOptions(d).fixedColorBy;

    /** This is the value of the field the color by is on (e.g. "CTV" for field "vesselType") */
    let value = getChained<string>(d, config.colorBy) ?? NOT_AVAILABLE;
    let color = this.layerOptions(d).color;

    if (fixedColorBy) {
      value = getChained<string>(d, fixedColorBy.variable) ?? NOT_AVAILABLE;
      color = fixedColorBy.colors.find(color => color.id === value)?.fill;
    }

    // Value should always be a string, convert remaining booleans to Yes/No
    if (typeof value === 'boolean') {
      value = value ? 'Yes' : 'No';
    }

    if (color) {
      config.layerColorsCache[layerId] ??= [];
      const colorDefinition = config.layerColorsCache[layerId].find(color => color.id === value);

      if (colorDefinition) {
        return colorDefinition;
      }

      const newColorDefinition = {
        id: value,
        fill: color,
      };

      config.layerColorsCache[layerId].push(newColorDefinition);

      return newColorDefinition;
    }

    return this.getColorForValue(value, config, layerId);
  }

  /**
   * Define the the stroke color for schedule bars
   *
   * @param  {Scheduled} d         d3 schedule item
   * @param  {boolean}   punctual  Punctual event
   * @return {string}              Stroke color
   */
  public pickStroke(d: Scheduled, punctual: boolean = false): string {
    if (d.punctualShape && !punctual) return '';
    // Check additional properties first
    if (d.__highlight && d.__highlight.stroke) {
      return d.__highlight.stroke;
    }

    // Find in options
    const layerStroke = this.layerOptions(d).stroke;
    if (layerStroke) {
      return layerStroke;
    }
    return '';
  }

  /**
   * Define the the stroke width for schedule bars
   *
   * @param  {Scheduled} d  d3 schedule item
   * @return {string}       Stroke color
   */
  public pickStrokeWidth(d: Scheduled): string {
    // Check additional properties first
    if (d.__highlight && d.__highlight.stroke) {
      return '2';
    }

    return '';
  }

  /**
   * Define the mask pattern for schedule bars
   *
   * @param  {Scheduled} d         d3 schedule item
   * @param  {boolean}   punctual  Punctual event
   * @return {string}              SVG color code or gradient URL
   */
  public pickMask(d: Scheduled, punctual: boolean = false): string {
    if (d.punctualShape && !punctual) return '';
    // Check additional properties first
    if (d.__highlight && d.__highlight.mask) {
      return 'url(#' + d.__highlight.mask + '-mask)';
    }
    return '';
  }

  /**
   * Define the fill color or gradient for schedule bars
   *
   * @param  {Scheduled} d         d3 schedule item
   * @param  {boolean}   punctual  Punctual event
   * @return {string}              SVG color code or gradient URL
   */
  public pickFill(d: Scheduled, punctual: boolean = false): string {
    if (d.punctualShape && !punctual) return 'none';
    // Check additional properties first
    if (d.__highlight && d.__highlight.pattern) {
      return 'url(#' + d.__highlight.pattern + '-pattern)';
    }
    if (d.__highlight && d.__highlight.fill) {
      return d.__highlight.fill;
    }
    if (punctual) return d.color.fill;

    const today = Date.now();
    // Plain color for past contracts or contracts with dateEnd
    if (d.dateEnd || d.dateStart < today) return d.color.fill;
    // Gradient (fading to white) for future contracts without dateEnd
    const uniqueId = LegendDrawingHelper.computeColorUniqueId(d.color.fill);
    return 'url(#gradient-' + uniqueId + ')';
  }

  /**
   * Add filtering effect to schedule bars
   *
   * @param  {Scheduled} d         d3 schedule item
   * @param  {boolean}   punctual  Punctual event
   * @return {string}              Filter or empty string
   */
  public pickFilter(d: Scheduled, punctual: boolean = false): string {
    if (d.punctualShape && !punctual) return '';
    // Check additional properties first
    if (d.__highlight && d.__highlight.shadow) {
      const shadow = d.__highlight.shadow;
      // Inset shadow
      if (shadow.inset) {
        return 'url(#shadow-' + LegendDrawingHelper.computeColorUniqueId(shadow.color) + ')';
      }
      // Drop shadow
      return 'drop-shadow(1px 2px 4px ' + shadow.color + ')';
    }
    return '';
  }

  public pickXRadius(d: Scheduled): number {
    const radius = this.layerOptions(d).radius;
    if (radius) {
      return radius.rx;
    }

    return 0;
  }

  public pickYRadius(d: Scheduled): number {
    const radius = this.layerOptions(d).radius;
    if (radius) {
      return radius.ry;
    }

    return 0;
  }

  private layerOptions(d: ScheduleLike): ScheduleLayerOptions {
    return this.layers[d.layerId].options;
  }

  /**
   * Get legend colors
   *
   * @param  {PossibleColors} possibleColors   Possible colors & patterns
   * @param  {ColorConfig}    config           Color configuration
   * @return {object}                          Legend colors
   */
  public getLegendColors(possibleColors: PossibleColors, config: ColorConfig): LegendColors {
    const itemColors: LegendColors = {};

    Object.keys(this.layers).forEach(layerId => {
      const layer = this.layers[layerId];
      // Hidden or overlay layer
      if (this.isOverlay(layerId) || !layer.visible) {
        return;
      }
      // Nothing for this layer
      if (!(layerId in possibleColors)) {
        return;
      }
      // Append colors
      const layerPossibleColors = Object.keys(possibleColors[layerId].colors) as Color[];
      this.sortColors(layerPossibleColors, config.colorBy, layerId);
      const legendLayerId = (!layer.options.fixedColorBy && config.colorBy) ? 'globalColorBy' : layerId;
      if (!itemColors[legendLayerId]) {
        itemColors[legendLayerId] = {};
      }
      if (legendLayerId === 'globalColorBy') {
        if (!itemColors['globalColorBy']['globalConfigLayerList']) {
          itemColors['globalColorBy']['globalConfigLayerList'] = [];
        }

        if (Array.isArray(itemColors['globalColorBy']['globalConfigLayerList'])) {
          itemColors['globalColorBy']['globalConfigLayerList'].push(layerId);
        }
      }
      for (const value of layerPossibleColors) {
        const color = layer.options.fixedColorBy?.colors?.find(color => color.id === value);
        itemColors[legendLayerId][value] = color?.fill ?? this.getColorForValue(value, config, layerId).fill;
      }
    });
    return itemColors;
  }

  public drawScheduleLegend(
    svg: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
    width: number,
    config: ColorConfig,
  ): void {
    const itemColors = this.getLegendColors(this.possibleColors, config);
    // if we have only one layer in the legend we don't want to add title
    const layerTitlePercentage = this.isLegendMultiLayer() ? this.chartingOptions.layerTitlePercentage : 0;

    const legendOptions: ChartLegendOptions = {
      width: width,
      nbItemPerLine: this.chartingOptions.itemsPerLegendLine,
      fontSize: this.chartingOptions.labelFontSize,
      minLegendItemSize: this.chartingOptions.minLegendItemSize,
      dashLegendPercentage: this.chartingOptions.dashLegendPercentage,
      layerTitlePercentage: layerTitlePercentage,
      commonLegendTitle: this.settings.commonLegendTitle,
    };

    drawLegendItems(svg, itemColors, legendOptions, this.layers);
  }

  /**
   * This function check if there is more than one legend to draw (there can be a legend
   * per layer)
   */
  public isLegendMultiLayer(): boolean {
    let defaultLegendCounted = false;
    return Object.keys(this.layers).filter(layerId => {
      const layer = this.layers[layerId];

      // we draw legend only for non overlay layers and visible layer
      if (!layer || this.isOverlay(layerId) || !this.layers[layerId].visible) {
        return false;
      }

      // if a layer has its own color by we draw a legend for this layer
      if (layer.options && layer.options.fixedColorBy) {
        return true;
      }

      if (defaultLegendCounted) {
        return false;
      }
      /*
       * otherwise this layer legend is the common default color by legend
       * we just check if this legend has been taken in account
       */
      defaultLegendCounted = true;
      return defaultLegendCounted;
    }).length > 1;
  }

  /**
   * Pick rectangle/label opacity
   *
   * @param  {Scheduled} d          d3 schedule item
   * @param  {boolean}   selection  Has selected element
   * @param  {boolean}   forLabel   Pick opacity for label (default for rectangle/punctual)
   * @return {number}               Calculated opacity
   */
  public pickOpacity(d: Scheduled, selection: boolean = false, forLabel: boolean = false): number {
    let opacity: number;
    // Check additional properties first
    if (d.__highlight && d.__highlight.opacity) {
      opacity = d.__highlight.opacity;
    } else {
      opacity = forLabel ? 1 : this.getBarOpacity(d);
    }
    // No selection
    if (!selection) return opacity;
    // Make selection opaque (1 for labels) and others more transparent (.6 for labels, x .8 for rectangles)
    if (forLabel) {
      return d.__selected ? 1 : .6;
    }
    return d.__selected ? opacity : opacity * .8;
  }

  /**
   * Determine the opacity of a bar - it might be completely hidden because layer is not active, or shown
   * or with low opacity if filtered out, but the vessels still shown
   *
   * @param  {Scheduled} d  d3 schedule item
   * @return {number}       Calculated opacity
   */
  public getBarOpacity(d: Scheduled): number {
    const lumi = tinycolor(d.color.fill).getLuminance();
    // Masked rectangles which are not filtered out
    if (this.hasDotOrDefaultPattern(d) && d.show) {
      // bright colors have bigger opacity
      if (lumi > 0.5) {
        return lumi;
      }
      // dark colors have opacity reduced to 0.3 when masked
      return 0.3;
    }

    /*
     * anything not filtered out with - which is shown will return the
     * opacity configured on the layer or 0.95 which is the default
     */
    if (d.show) {
      return this.layerOptions(d).opacity;
    }

    /*
     * filtered out contracts on vessels which are not filtered out get very little
     * if they are on dark color we reduce even more
     */
    if (lumi > 0.5) {
      return 0.02;
    }

    // this are dark color contracts filtered out default is 0.
    return this.layerOptions(d).filteredOpacity;
  }

  /**
   * Return true if the element has a customized filled pattern (in the schedule)
   *
   * @param d Scheduled entry to test
   * @param fill ScheduleFillConfig to test
   * @returns boolean
   */
  public hasFillConfig(d: ScheduleLike, fill: ScheduleFillConfig): boolean {
    // First case, availableValues is an empty array => any value is good, excepted from undefined or null
    if (getChained(d, fill.field) && fill.affectedValues.length === 0) {
      return true;
    }

    // Second case, fill.availableValues is not empty, try to find a match
    return fill.affectedValues.some(affectedValue => getChained(d, fill.field) === affectedValue);
  }

  /**
   * Format an element pattern url according to its layer config
   */
  public getPatternUrl(d: Scheduled): string {
    const layerConfig = this.layerOptions(d);
    // Go through fill configs and find the one that matches, if any
    const fillConfig = layerConfig.fill?.find(fill => this.hasFillConfig(d, fill));

    const patternId = fillConfig?.patternId ?? LegendDrawingHelper.DEFAULT_FILL_PATTERN;

    return `url(#${patternId}-pattern)`;
  }

  public hasDotOrDefaultPattern(d: Scheduled): boolean {
    return this.hasGivenFillPattern(d, 'dot') || this.hasGivenFillPattern(d, undefined);
  }

  public hasStrokePattern(d: Scheduled): boolean {
    return this.hasGivenFillPattern(d, 'stroke');
  }

  private hasGivenFillPattern(d: Scheduled, patternId: string): boolean {
    const fillConfigInLayer = this.layerOptions(d)?.fill?.find(fill => fill.patternId === patternId);

    return fillConfigInLayer ? this.hasFillConfig(d, fillConfigInLayer) : false;
  }

  /**
   * Gets the label of the contract - uses a bit of heuristics to shorten it, if there is no place
   */
  public getContractLabel(d: ScheduleLike): string {
    // for filtered contract then show labels
    if (!d.show) {
      return '';
    }

    const value = getChained<string>(d, this.layerOptions(d).label);
    if (!value) {
      return '';
    }

    return value;
  }

  /**
   * Returns the name of the group, if no grouping is selected - then artificial value NOT_AVAILABLE is added
   * If the item doesn't have the value defined (eg. Operator will be null for Stacked contracts)
   * then return Not available
   */
  public getGroupName(d: DeepReadonly<ScheduleLike>, groupBy: string): string {
    const nullTitle = this.getGroupByConfig(groupBy)?.nullTitle ?? NOT_AVAILABLE;
    return getChained(d, groupBy) ?? nullTitle;
  }

  private getGroupByConfig(groupBy: string): SelectableGroupBy {
    return this.settings.selects.groupby.values.find(({ value }) => value === groupBy);
  }

  public getWindowCenterInterval(window: TimeWindow): FilterApplied {
    if (window.endDate) {
      const windowRange = window.endDate - window.startDate;
      const newInterval = [window.startDate - windowRange, window.endDate + windowRange];
      const intervalFilter: FilterApplied = {
        id: '',
        filterType: 'date',
        active: true,
        values: newInterval,
      };
      return intervalFilter;
    }
  }

  public static createWindowForInterval(
    interval: Interval,
    color: Color,
    name: ScheduleWindowName | string,
  ): TimeWindow {
    return {
      startDate: interval[0],
      endDate: interval[1],
      name: name,
      color: color,
    };
  }

  public setChartingOptions(chartingOptions: ScheduleChartOptions): void {
    this.chartingOptions = chartingOptions;
  }

  public static calculateVerticalBreaklines(
    verticalBreaklineParameters: VerticalBreaklinesParameters,
    timeCreator: TimeCreator,
  ): VerticalBreakLine[] {
    // xScale is not available in spin-schedule until `completeRedraw`, some dataLoader requests may finish first
    if (!verticalBreaklineParameters.xScale) {
      return [];
    }

    const breaklines = [];
    const minorBreaklineUnit = verticalBreaklineParameters.breakLineUnit;
    const majorBreaklineUnit = ScheduleTimeManager.getMajorBreaklineUnit(minorBreaklineUnit);

    /*
     * we add 1000 ms to each timestamp as endOf will give the endOf a period and
     * we want to get the beginning of the next one
     */
    let currentTimeStamp = timeCreator.beginningOfNextPeriodTimestamp(
      verticalBreaklineParameters.minDate,
      minorBreaklineUnit,
    );
    let majorTimeStamp = timeCreator.beginningOfNextPeriodTimestamp(
      verticalBreaklineParameters.minDate,
      majorBreaklineUnit,
    );

    while (currentTimeStamp < verticalBreaklineParameters.maxDate) {
      const startingX = verticalBreaklineParameters.xScale(currentTimeStamp as any);
      const tooltip = minorBreaklineUnit === 'hour'
        ? 'YYYY-MM-DD HH:mm:ss'
        : 'YYYY-MM-DD';

      const newBreakline: VerticalBreakLine = {
        id: `${verticalBreaklineParameters.breakLineUnit} -${currentTimeStamp} `,
        startingX: startingX,
        timestamp: currentTimeStamp,
        tooltip: timeCreator.getDayJsForFormatting(currentTimeStamp).format(tooltip),
      };
      if (currentTimeStamp === majorTimeStamp) {
        newBreakline.majorBreakline = true;
      }
      if (currentTimeStamp >= majorTimeStamp) {
        majorTimeStamp = timeCreator.beginningOfNextPeriodTimestamp(majorTimeStamp, majorBreaklineUnit);
      }
      breaklines.push(newBreakline);
      currentTimeStamp = timeCreator.beginningOfNextPeriodTimestamp(currentTimeStamp, minorBreaklineUnit);
    }
    return breaklines;
  }

  public static plotVerticalBreaklines(
    svg: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
    verticalBreaklineParameters: VerticalBreaklinesParameters,
    timeCreator: TimeCreator,
  ): VerticalBreakLine[] {
    const breaklines = ScheduleDrawingHelper.calculateVerticalBreaklines(verticalBreaklineParameters, timeCreator);

    svg.selectAll<SVGLineElement, VerticalBreakLine>('.temporal-breaklines')
      .data(breaklines, d => d.id)
      .join(
        enter =>
          enter.insert('line', '#schedule-group')
            .attr('class', 'temporal-breaklines')
            .attr('y1', 0)
            .style('stroke-width', 1.5)
            .style('stroke', '#757575'),
      )
      .attr('clip-path', 'url(#' + verticalBreaklineParameters.clipId + ')')
      .attr('clip-path', 'url(#' + verticalBreaklineParameters.clipId + ')')
      .attr('x1', d => d.startingX)
      .attr('x2', d => d.startingX)
      .attr('y2', verticalBreaklineParameters.visibleStop)
      .style('stroke-opacity', d => d.majorBreakline ? '0.5' : '0.2');

    return breaklines;
  }

  public static drawXAxisIntoSvg(
    svg: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
    xAxisParameters: xAxisParameters,
    noRedraw: boolean = false,
    timeCreator: TimeCreator,
  ): Axis<number> {
    if (
      !xAxisParameters.currentExtent || xAxisParameters.currentExtent[1] <= xAxisParameters.currentExtent[0] || !svg
    ) {
      return;
    }

    const xAxis = axisBottom<number>(xAxisParameters.xScale);

    // The tickNumber depends on the available width on screen and expected tick width
    const tickNumber = Math.floor(xAxisParameters.width / TICK_WIDTH);
    const alignTicksWithBreaklines = xAxisParameters.breakLineUnit
      && xAxisParameters.verticalBreaklines
      && xAxisParameters.verticalBreaklines.length;

    if (alignTicksWithBreaklines) {
      const xAxisTicks = ChartTimeManager.getXaxisTicks(xAxisParameters, tickNumber);
      xAxis.tickValues(xAxisTicks);
    } else {
      xAxis.ticks(tickNumber);
    }

    const axisFormatting = timeCreator.getTimeAxisFormattingForInterval(xAxisParameters.currentExtent, tickNumber);
    xAxis.tickFormat(axisFormatting.formatter);

    const currentAxis = svg.select<SVGGElement>('g.axis');

    // We redraw only if we call this function while a full redraw or the axis was not drawn before
    if (!noRedraw || currentAxis.empty()) {
      svg.append('g')
        .attr('class', 'axis')
        .style('visibility', xAxisParameters.currentExtent ? 'visible' : 'hidden')
        .call(xAxis);
    } else {
      currentAxis
        .style('visibility', xAxisParameters.currentExtent ? 'visible' : 'hidden')
        .call(xAxis);
    }

    return xAxis;
  }

  public static drawExportHeader(
    svg: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
    title: string,
    currentExtent: Interval,
    width: number,
    groupBy?: string,
    colorBy?: string,
    metric?: string,
    tab?: string,
  ): number {
    let headerHeight = 0;
    const header = svg.append('g')
      .attr('class', 'export-header');

    let currentX = DEFAULT_BAR_PADDING_LEFT;

    const smallFont = Math.round(width / 100);
    const mediumFont = Math.round(width / 80);
    const bigFont = Math.round(width / 60);
    const xSpace = Math.round(width / 5);
    const ySpace = bigFont * 2;

    headerHeight += mediumFont + 10;

    if (tab) {
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('Selection');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(tab);

      currentX += xSpace;
    }

    header.append('text')
      .attr('x', currentX)
      .attr('dy', '0.35em')
      .attr('y', headerHeight)
      .attr('font-size', bigFont + 'px')
      .attr('font-style', 'italic')
      .text(title);

    if (groupBy) {
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('Group by');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(groupBy);
      currentX += xSpace;
    }
    if (colorBy) {
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('Color by');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(colorBy);
      currentX += xSpace;
    }

    if (metric) {
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('Metric');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(metric);

      currentX += xSpace;
    }

    if (currentExtent && currentExtent.length === 2) {
      const minDate = dayjs(currentExtent[0]).format('YYYY-MM-DD');
      const maxDate = dayjs(currentExtent[1]).format('YYYY-MM-DD');

      currentX += 40;
      // Current extent
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('From');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(minDate);

      currentX += Math.round(width / 8);

      // Current extent
      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace)
        .attr('font-size', smallFont + 'px')
        .text('To');

      header.append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', headerHeight + ySpace + mediumFont)
        .attr('font-size', mediumFont + 'px')
        .attr('font-weight', 800)
        .text(maxDate);
    }
    return headerHeight + 140;
  }

  public static drawExportFilterList(
    svg: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
    visibleLayers: ScheduleLayer[],
    width: number,
  ): number {
    const smallFont = Math.round(width / 100);
    const mediumFont = Math.round(width / 80);
    let footerHeight = 0;

    const footer = svg.append('g')
      .attr('class', 'export-filter-list');

    const filterList = footer.append('g')
      .attr('class', 'filter-list');

    footerHeight += 40;

    const filterListTitle = filterList.append('text')
      .attr('x', DEFAULT_BAR_PADDING_LEFT)
      .attr('dy', '0.35em')
      .attr('y', footerHeight)
      .attr('font-size', mediumFont + 'px')
      .attr('font-weight', 800)
      .text('Filter list:')
      .attr('visibility', 'hidden');

    const layersFilterList: { [layerTitle: string]: { [filterId: string]: string } } = { 'Common filters': {} };

    visibleLayers.forEach(layer => {
      if (Object.keys(layer.filtersForExport).length) {
        const exportFields = [{ title: '', fields: layer.options.exportFields }];
        layersFilterList[layer.options.title] = {};
        for (const filterId in layer.filtersForExport) {
          const filter = layer.filtersForExport[filterId];
          const field = FilterHelper.findFieldInFieldsets(exportFields, f => f.id === filterId);
          const values = filter.valuesTitle ? filter.valuesTitle : filter.values;
          const filterTitle = field && field.title ? field.title : filter.filterTitle;
          // If we didn't find field settings we can consider that this filter is a common filter
          if (!field) {
            layersFilterList['Common filters'][filterId] = '(' + filterTitle + ': ' + values.join(', ') + ')';
            continue;
          }
          layersFilterList[layer.options.title][filterId] = '(' + filterTitle + ': ' + values.join(', ') + ')';
        }
      }
    });

    for (const layerTitle in layersFilterList) {
      const layerFilterList = layersFilterList[layerTitle];
      if (Object.values(layerFilterList).length) {
        footerHeight += 40;
        filterList.append('text')
          .attr('x', 20)
          .attr('dy', '0.35em')
          .attr('y', footerHeight)
          .attr('font-size', smallFont + 'px')
          .text(`${layerTitle}: [` + Object.values(layerFilterList).join(',') + ']');
      }
    }

    filterListTitle.attr('visibility', 'visible');
    // Add space for logo if necessary
    if (footerHeight < 150) {
      footerHeight = 150;
    }
    return footerHeight;
  }

  public getGroupByTitle(groupBy: string): string {
    const selectSettings = this.settings.selects.groupby;
    if (!selectSettings) {
      return groupBy;
    }
    const foundSelectValue = ChartSelectsHelper.findSelectValueById(selectSettings.values, groupBy);

    return foundSelectValue?.title ?? groupBy;
  }

  public getColorByTitle(colorBy: string): string {
    const selectSettings = this.settings.selects.splitby;
    if (!selectSettings) {
      return colorBy;
    }
    const foundSelectValue = ChartSelectsHelper.findSelectValueById(selectSettings.values, colorBy);

    return foundSelectValue?.title ?? colorBy;
  }

  public setLayerVisible(layerId: LayerId, visible: boolean): void {
    this.layers[layerId].visible = visible;
  }

  public setLayers(layers: { [layerId: string]: ScheduleLayer }): void {
    this.layers = layers;
  }

  public getLayerColorByValue(d: ScheduleLike, colorBy: string): string {
    const layer = this.layers[d.layerId];

    // Get
    let color;
    if (layer.options.fixedColorBy) {
      color = getChained<string>(d, layer.options.fixedColorBy.variable) ?? NOT_AVAILABLE;
    } else color = getChained<string>(d, colorBy) ?? NOT_AVAILABLE;

    // Special case: boolean
    if (color === true) color = 'Yes';
    else if (color === false) color = 'No';

    return color as string;
  }

  /**
   * Update colors & patterns count
   *
   * @param  {ScheduleLike}       d        Schedule bar data
   * @param  {string}             colorBy  Column key for color
   * @return {void}
   */
  public getElementLegendColor(d: ScheduleLike, colorBy: string): void {
    // Init
    if (!this.possibleColors[d.layerId]) {
      console.warn(
        'LayerId used before layer configured. '
          + 'This can happen when 2 layers use exactly the same endpoint '
          + ' but different layer id in single schedule component ' + d.layerId,
      );
      this.possibleColors[d.layerId] = { colors: {}, patterns: {} };
    }
    const counts = this.possibleColors[d.layerId];

    // Color
    const colorValue = this.getLayerColorByValue(d, colorBy);
    counts.colors[colorValue] = counts.colors[colorValue] || 0;
    if (d.show) counts.colors[colorValue]++;

    // Pattern
    const layerConfig = this.layerOptions(d);

    // Increment if pattern is applied
    layerConfig?.fill?.forEach(fill => {
      if (!d.show) return;

      const field = fill.field;
      counts.patterns[field] ??= 0;
      counts.patterns[field + 'NoFill'] ??= 0;
      // if entity is on display and concerned field has a match with fill config
      if (this.hasFillConfig(d, fill)) {
        counts.patterns[field]++;
      } else {
        counts.patterns[field + 'NoFill']++;
      }
    });

    // Increment if additional property match
    layerConfig?.additionalProperties?.forEach((additionalProperty, index) => {
      if (!d.show) return;
      const { pattern, fill, stroke, mask } = additionalProperty.highlight;
      const patternId = pattern ?? mask;
      const field = 'additionalProperty' + index;
      if (!patternId && !fill && !stroke) return;
      if (getChained(d, additionalProperty.condition.key) === additionalProperty.condition.value) {
        counts.patterns[field] ??= 0;
        counts.patterns[field]++;
      }
    });
  }

  /**
   * Build palette (unique colors).
   */
  public changeColors(): void {
    const allColors = [];
    Object.keys(this.possibleColors).forEach(layerId => {
      const layerColors = this.possibleColors[layerId].colors;
      Object.keys(layerColors).forEach(color => {
        if (allColors.indexOf(color) === -1) {
          allColors.push(color);
        }
      });
    });
    this.colorScale.domain(allColors);
  }

  /**
   * Update d3 schedule item with applied rules (if any)
   *
   * @param  {Scheduled}   d      d3 schedule item
   * @param  {object}      props  Additional properties
   * @return {void}
   */
  public getHighlightingRules(d: Scheduled, props: object): void {
    if (d.__highlight !== undefined) return;
    d.__highlight = this.matchPropertyConditions(d, props);
  }

  /**
   * Check if item matches highlighting conditions
   *
   * @param  {ScheduleLike} d      d3 schedule item
   * @param  {object}       props  Additional properties
   * @return {AdditionalPropertyHighlight}  Highlight rule or null
   */
  public matchPropertyConditions(d: ScheduleLike, props: object): AdditionalPropertyHighlight {
    if (!props[d.layerId]) return null;
    const highlight: AdditionalPropertyHighlight = {};
    props[d.layerId].forEach((prop: AdditionalProperty) => {
      // Check against condition
      if (prop.condition instanceof Object) {
        // Simple key/value condition
        if (getChained(d, prop.condition.key) !== prop.condition.value) {
          return;
        }
      } else {
        /**
         * TODO: Complex condition as a string to be evaluated
         * See 260c448f67c5ee4df54a17be34d7323c90368e0c for an example
         * of complex condition based on `coherencyCheck` syntax (`value[key]`)
         */
        return;
      }
      // Merge properties
      Object.keys(prop.highlight).forEach((key: string) => {
        if (highlight[key]) return;
        highlight[key] = prop.highlight[key];
      });
    });
    return Object.keys(highlight).length ? highlight : null;
  }

  /**
   * Mark the cache for the given layerId as invalid.
   * The cache will be cleared next time it is requested.
   */
  public invalidateHeavyLayerCache(layerId: string): void {
    if (!this.heavyLayersCache[layerId]) return;
    this.invalidHeavyLayerCaches.add(layerId);
  }
}
