import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatChipSelectionChange, MatChipsModule } from '@angular/material/chips';

import { OptionValue } from '../helpers/types';

export interface ChipSelectionEvent {
  event: MatChipSelectionChange;
  chip: OptionValue;
}

@Component({
  selector: 'spin-chip-list',
  templateUrl: 'chip-list.html',
  styleUrls: ['chip-list.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatIconModule,
    MatChipsModule,
  ],
})
export class ChipListComponent {
  // Chip list
  @Input()
  chips: OptionValue[] = [];

  // Boolean true if chip can be removed
  @Input()
  removable: boolean = false;

  // Boolean true if chip can be selected
  @Input()
  selectable: boolean = false;

  // Optional selected value
  @Input()
  selected?: OptionValue;

  // Property used to read title
  @Input()
  propTitle?: string = 'title';

  @Input()
  disabled?: boolean = false;

  @Output()
  removed = new EventEmitter<OptionValue>();

  @Output()
  selectionChange = new EventEmitter<ChipSelectionEvent>();

  public getChipTitle(chip: OptionValue): string {
    return chip[this.propTitle] as string;
  }

  public isChipSelected(chip: OptionValue): boolean {
    return this.selected && this.selected.id === chip.id;
  }

  public chipRemoved(chip: OptionValue | string): void {
    if (typeof chip === 'string') {
      this.removed.emit({ title: chip });
      return;
    }
    this.removed.emit(chip);
  }

  public chipSelected(event: MatChipSelectionChange, chip: OptionValue): void {
    this.selectionChange.emit({ event, chip });
  }
}
