import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Output,
  ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ActionEvent, CompleteExport, EntityTableModalConfig, FiltersState, RefreshType } from '../helpers/types';
import { EntityTableWrapperComponent } from '../pages/entity-table-wrapper';
import { PearlButtonComponent } from '../shared/pearl-components';

interface TableModalConfigAndState {
  data: any[];
  component: EntityTableModalConfig;
  modalComponentState: FiltersState;
}

@Component({
  templateUrl: 'table-modal.html',
  styleUrls: ['table-modal.scss'],
  standalone: true,
  imports: [
    PearlButtonComponent,
    EntityTableWrapperComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableModalComponent implements AfterViewInit {
  @Output()
  onaction = new EventEmitter<ActionEvent>();
  @Output()
  exportEmitter = new EventEmitter<CompleteExport>();
  @ViewChild(EntityTableWrapperComponent)
  private $table: EntityTableWrapperComponent;
  private readonly data: Array<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: TableModalConfigAndState,
    private dialogRef: MatDialogRef<TableModalComponent>,
  ) {
    /*
     * If provided, data will be set and used in the modal. If not, then the refresh method will be called
     * which will eventually set the data itself.
     */
    this.data = config.data;
  }

  ngAfterViewInit(): void {
    this.$table.onaction = (event: ActionEvent) => {
      this.onaction.emit(event);
    };
    // We must wait that the entity-table-component has correctly set his definition
    setTimeout(() => {
      // If we already have data, we don't want to call refresh, which will override any given data.
      if (this.data) {
        this.$table.initDefinitionAndSetData(this.data);
        return;
      }
      this.$table.ownFiltersState = this.config.modalComponentState;
      this.$table.modalComponentState = this.config.modalComponentState;
      this.$table.refresh(RefreshType.Default);
    }, 100);
  }

  onexport(event: CompleteExport) {
    this.exportEmitter.emit(event);
  }

  public close() {
    this.dialogRef.close();
  }

  public selectRow(selectedRowId: any) {
    this.dialogRef.close(selectedRowId);
  }
}
