import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, NgZone, OnInit, QueryList, ViewChildren,
  ViewEncapsulation, forwardRef, inject, signal } from '@angular/core';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgStyle } from '@angular/common';

import { Config } from '../config/config';
import { Client, ClientsAndGroups } from '../dashboards/admin/admin-model';
import { DataLoader } from '../data-loader/data-loader';
import { TimezoneService } from '../helpers/timezone.service';
import { AfterSave, DeepReadonly, EntityDefinition, EntityDeletedDto, EntityFieldDefinition, EntityInformation,
  EntityTab, EntityUpdateDto, IntegratedTableDefinition, LayerId, LinkBy, LinkEntitiesQuery, ModeEnum, NumOrString,
  SomeEntity, UpdateEventType } from '../helpers/types';
import { TableStateService } from '../shared/services/helpers/table-state.service';
import { DatabaseHelper } from './database-helper';
import { DialogManager } from './dialog-manager';
import { EntityDataAccessor } from './entity-data-accessor';
import { EntityDetailFieldComponent } from './entity-detail-field';
import { IEntityDetailFieldParent } from './entity-detail-field-parent';
import { EntityLinkedCollectionComponent } from './entity-linked-collection';
import { UnsavedEntityDialog } from './unsaved-entity-dialog';
import { PearlAutocompleteComponent, PearlButtonComponent, PearlIconComponent,
  PearlSelectOptionValueType } from '../shared/pearl-components';
import { EntityDialogManager } from './entity-dialog-manager';

@Component({
  selector: 'entity-detail',
  templateUrl: './entity-detail.html',
  styleUrls: ['entity-detail.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TimezoneService],
  standalone: true,
  imports: [
    PearlButtonComponent,
    PearlIconComponent,
    PearlAutocompleteComponent,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    NgStyle,
    NgClass,
    forwardRef(() => EntityDetailFieldComponent),
    MatProgressSpinnerModule,
  ],
})
export class EntityDetailComponent implements IEntityDetailFieldParent, OnInit {
  private readonly dataLoader = inject(DataLoader);
  private readonly dialogManager = inject(DialogManager);
  private readonly config = inject(Config);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);
  private readonly tableStateService = inject(TableStateService);
  private readonly dialog = inject(MatDialog);
  private readonly injector = inject(Injector);
  private readonly entityDialogManager = inject(EntityDialogManager);

  public readonly timezoneService = inject(TimezoneService, { host: true });

  public definition: EntityDefinition;
  public message: string;
  private entityName: string;
  public dataLoading = false;
  public definitionLoading = false;
  public deleting = false;
  private entityId: number | string;
  private vesselId: number;
  public editMode: boolean;
  public title: string;
  public saving: boolean;
  public tabs: EntityTab[];
  public deleteMode: boolean;
  private linkBy: LinkBy;
  private idToReload: NumOrString;
  public prefill?: unknown;
  public entityAccessor: EntityDataAccessor;
  public coherencyErrors: string[];
  private afterSaveLinkQuery: LinkEntitiesQuery;
  private closeAfterSave: boolean;
  private layerId: LayerId;
  public availableClients: DeepReadonly<Client[]>;
  private userHasCustomFields: boolean = false;
  public canEdit: boolean = false;
  public errorMessage: string = null;
  private afterSaves: AfterSave[] = [];
  public fullyLoaded: boolean = false;
  public reloadAllLayers: boolean = false;
  public footerFields: EntityFieldDefinition[] = [];
  public integratedTablesDefinitions: IntegratedTableDefinition[] = [];
  public reloadDashboardConfig: boolean = false;
  public showAllGroups: number = 0;
  public tabFieldMatch: number[] = [];
  public selectedTabIndex = new FormControl(0);
  public showSearchBar = false;

  // Bypass app default timezone and use local (until user selects another timezone)

  private afterSave: (afterSave: AfterSave) => void;
  public afterClose: () => void;

  @ViewChildren('linkedCollection')
  $linkedCollections: QueryList<EntityLinkedCollectionComponent>;

  @ViewChildren('matSelect')
  $selectFields: QueryList<MatSelect>;
  @ViewChildren('matAutoComplete')
  $autocompleteFields: QueryList<MatAutocomplete>;
  @ViewChildren('entityFields')
  $fieldsComponents: QueryList<EntityDetailFieldComponent>;

  constructor(private dialogRef: MatDialogRef<EntityDetailComponent>) {
    this.entityAccessor = new EntityDataAccessor(this.injector);
    /*
     * We add this directly on the document because panel are not always existant
     * So we can't bind an event on them during initialisation
     */
    this.ngZone.runOutsideAngular(() =>
      document.addEventListener('keydown', ev => {
        if (ev.key === 'Escape') {
          this.closeModal();
        }
        DatabaseHelper.handleKeyEventForPanel(this.$selectFields, ev);
        DatabaseHelper.handleKeyEventForPanel(this.$autocompleteFields, ev);
      })
    );

    this.timezoneService.name = this.constructor.name;
    this.timezoneService.timezoneConfig = { timezone: 'local' };
  }

  public readonly fields = signal<EntityFieldDefinition[]>([]);
  private readonly filteredFields = signal<EntityFieldDefinition[]>([]);

  ngOnInit(): void {
    if (this.config.isLightOrFullAdmin) {
      this.dataLoader.get<ClientsAndGroups>('/sso/clients-groups')
        .then((data) => {
          this.availableClients = data.clients;
        });
    }
  }

  // part of the code that must be executed after all the view part has been correctly set
  private afterView(): void {
    EntityDetailComponent.disableScrollForInputTypeNumber();
    this.fullyLoaded = true;
    for (const group in this.definition.generalInfo.groupVisibility) {
      if (this.entityAccessor.isGroupModified(group) && this.modifiedGroups.indexOf(group) === -1) {
        this.modifiedGroups.push(group);
      }
    }
    if (this.fields().length >= 20) {
      this.showSearchBar = true;
    }

    this.refreshUI(); // We need to refresh the UI otherwise @ViewChildren('entityFields') is empty!
    this.$fieldsComponents.forEach(field => {
      field.$integratedTableComponent?.loadDefinitionAndValues();
      field.$linkedCollectionComponent?.parentEntityFullyLoaded();
      field.initTransientField();
      field.refreshUI();
    });

    this.checkCoherency();
  }

  public static disableScrollForInputTypeNumber(): void {
    /*
     * This part of the code will disable the fact that input number take the scroll event as
     * an event to increase/decrease the number in the field
     */
    const inputs = document.getElementsByClassName('mat-input-element');
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.getAttribute('type') === 'number') {
        (input as HTMLElement).addEventListener('wheel', function(_) {
          // When we scroll we will blur the input field so we will not use his behavior to modify scroll
          const isFocus = document.activeElement === this;
          this.blur();
          setTimeout(() => {
            if (isFocus) {
              // 10 milliseconds later we refocus on the field if it was focused before
              this.focus();
            }
          }, 10);
        });
      }
    }
  }

  public getMaxFieldWidth = DatabaseHelper.getFieldWidth;

  get loading(): boolean {
    return (
      this.dataLoading || this.definitionLoading || this.deleting || this.saving
    );
  }

  public async setEntityInformation(info: EntityInformation): Promise<void> {
    this.entityName = info.entityName;
    this.entityId = info.entity?.[info.idField];
    this.vesselId = info.entity?.['vesselId'];
    this.editMode = info.editMode;
    this.linkBy = info.linkBy;
    this.afterSave = info.afterSaveAction;
    this.closeAfterSave = info.closeAfterSave ?? false;
    this.afterClose = info.afterCloseAction;
    this.layerId = info.layerId;
    this.idToReload = info.idToReload;
    this.prefill = info.prefill;
    this.reloadAllLayers = info.reloadAllLayers;
    this.reloadDashboardConfig = info.reloadDashboardConfig;
    try {
      await this.getDefinition(info.creation);
    } catch (e) {
      this.closeModal();
      this.dialogManager.showMessage('An error occurred. Please contact support if this persists.', 'error');
      throw e;
    }
    this.entityAccessor.setEntity(info.entityName, info.entity, this.prefill);
    await this.getDetails();
    this.afterView();
    this.fullyLoaded = true;
  }

  closeModal(): void {
    if (this.tableStateService.editingState === ModeEnum.EDIT) {
      const dialogRef = this.dialog.open(UnsavedEntityDialog, {
        width: '355px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.close(false);
        }
      });

      return;
    }

    this.close(false);
    this.tableStateService.setNormalMode();
  }

  private async close(closeAll: boolean): Promise<void> {
    const afterSavePromise = this.dialogRef.afterClosed().toPromise();
    this.ngZone.run(() => {
      this.entityAccessor.leaveEditMode();
      if (closeAll) {
        this.dialog.closeAll();
      } else {
        this.dialogRef.close();
      }
    });

    /*
     * anytime there was change in linked components we store this change
     * we will send the first change to the parent element (dashboard or pages)
     * this is because we can be in an situation where the current dialog did not perform
     * any changes it all (user might have no rights on the current entity)
     * but we have to somehow notify the dashboard or pages that changes were done and that we have
     * to reload
     */
    if (this.afterSaves.length > 0) {
      this.afterSave(this.afterSaves[0]);
    }

    if (this.afterClose) {
      this.afterClose();
    }

    return await afterSavePromise;
  }

  enterDeleteMode(): void {
    this.deleteMode = true;
  }

  leaveDeleteMode(): void {
    this.deleteMode = false;
  }

  enterEditMode(): void {
    this.entityAccessor.enterEditMode();
    this.editMode = true;
    this.cdRef.detectChanges();
  }

  leaveEditMode(): void {
    this.$linkedCollections.forEach(linkedCollection => {
      linkedCollection.leaveEditMode();
    });
    this.$fieldsComponents
      .filter(
        field => field.$integratedTableComponent !== undefined && field.$integratedTableComponent !== null,
      ).forEach(field => {
        field.$integratedTableComponent.cancelModifications();
      });
    this.entityAccessor.leaveEditMode();
    this.editMode = false;
    this.cdRef.detectChanges();
  }

  async duplicate(): Promise<void> {
    this.dataLoading = true;
    const newEntity = await EntityDataAccessor.duplicate(
      this.entityAccessor.entity,
      this.definition,
      this.dataLoader,
    );
    const entityInformation: EntityInformation = {
      entityName: this.definition.class,
      entity: newEntity.entity,
      editMode: true,
      idField: this.definition.idField,
      afterSaveAction: this.afterSave,
      closeAfterSave: true,
      creation: false,
      afterCloseAction: null,
      layerId: null,
    };
    this.dataLoading = false;
    this.dialogManager.showMessage(newEntity.message, 'success');
    this.entityDialogManager.openEntityDialog(entityInformation);
  }

  get isNew(): boolean {
    return this.entityAccessor.isNew();
  }

  get showEdit(): boolean {
    if (!this.definition) {
      return false;
    }
    return this.definition.generalInfo.edit && !this.isNew
      && !this.editMode
      && this.canEdit;
  }

  get showDuplicate(): boolean {
    if (!this.definition) {
      return false;
    }
    return (
      this.definition.generalInfo.duplicate
      && !this.deleteMode
      && !this.entityAccessor.entityHasChanged
      && !this.isNew
      && DatabaseHelper.hasEditRight(this.definition)
      && !this.userHasCustomFields
    );
  }

  get showDelete(): boolean {
    if (!this.definition) {
      return false;
    }

    return (
      this.definition.generalInfo.delete
      && !this.isNew
      && !this.deleteMode
      && DatabaseHelper.hasEditRight(this.definition)
    );
  }

  get showSave(): boolean {
    return this.editMode && this.canEdit && !this.deleteMode;
  }

  static prepareTabWithLayout(tab: EntityTab, definition: EntityDefinition): void {
    if (tab.type === 'standard') {
      // Fill tab with standard and custom fields in the grid form
      const allFields = definition.fields.concat(definition.freeStyleFields);
      tab.fieldsWithLayout = tab.fieldIds.map(lineIds =>
        lineIds.map(fieldId => allFields.find(f => f.id === fieldId)).filter(f => f && !f.footer)
      );
      tab.orderedFields = null;
      tab.fields = [];
      tab.fieldsWithLayout.forEach(line => line.forEach(field => tab.fields.push(field)));
    }
  }

  /**
   * Prepare selected tab. Order field to respect given order
   */
  static prepareTab(tab: EntityTab, definition: EntityDefinition): void {
    if (tab.fieldIds.length && tab.fieldIds[0] instanceof Array) {
      return this.prepareTabWithLayout(tab, definition);
    }
    // fill the tab fields with fields coming from definition
    if (tab.type === 'standard') {
      tab.fields = definition.fields.filter(d => tab.fieldIds.includes(d.id) && !d.footer);

      /*
       * some custom fields might be configured to be added to an existing tab
       * the function to tabs merging will push the fieldId into the standard fields
       */
      tab.fields.push(...definition.freeStyleFields.filter(d => tab.fieldIds.includes(d.id)));
    } else {
      tab.fields = definition.freeStyleFields.filter(d => tab.fieldIds.includes(d.id));
    }

    /*
     * The fields ordering is a little bit tricky. Because the given order should be display like this:
     * 1 4
     * 2 5
     * 3 6
     * But as we used a 'display: flex' to integrate in the same way half-width field
     * and full width field (e.g. collection) we need to reorder the fields so that
     * the field order is the following one:  1 4 2 5 3 6
     *
     * Each time we meet a full width field we place the previous field
     * with the technique explained  and we start again with the following fields
     *
     *  So for exemple orderer fields should be displayed like this :
     * 1 3
     * 2 4
     *  5(collection)
     * 6 8
     * 7 9
     *
     * And organize like this :  1 3 2 4 5(collection) 6 8 7 9
     */

    // field index
    let i = 0;
    const orderedFields = [];
    while (i < tab.fields.length) {
      const fieldsToPlace = [];
      let collectionField = false;
      // While we don't meet a collection or the end of dataset we just create the array of field to order
      while (!collectionField && i < tab.fields.length) {
        if (tab.fields[i].type === 'collection' && tab.fields[i].formType !== 'multi') {
          collectionField = true;
        }
        fieldsToPlace.push(tab.fields[i]);
        i++;
      }

      let k = 0;
      const peer = fieldsToPlace.length % 2 === 0;
      const halfFieldNumber = Math.ceil(fieldsToPlace.length / 2);
      /*
       * We go through the list of field to order in order to place the first element of the 1st column,
       * then the 1st element of the 2nd column,
       * then the 2nd element of the 1st column,
       * the 2nd element of the 2nd column, etc...
       */
      while (k < fieldsToPlace.length) {
        orderedFields.push(fieldsToPlace[k]);
        // We do this because we don't want to place twice the (n/2+1)th element
        if (peer && k === fieldsToPlace.length - 1) {
          k = fieldsToPlace.length;
        } else {
          k = (k < halfFieldNumber) ? k + halfFieldNumber : k - halfFieldNumber + 1;
        }
      }
    }
    tab.orderedFields = orderedFields;
  }

  public pushAfterSave(afterSave: AfterSave): void {
    this.afterSaves.push(afterSave);
  }

  /**
   * Check if all tab fields are valid or not
   *
   * @param  {EntityTab} tab  Current tab
   * @return {boolean}        True if invalid
   */
  public tabInvalid(tab: EntityTab): boolean {
    // Use previously calculated value
    if (tab.valid !== undefined && tab.valid !== null) {
      return !tab.valid;
    }
    tab.valid = false;
    if (tab.orderedFields) {
      for (const field of tab.orderedFields) {
        if (this.fieldInvalid(field)) {
          return true;
        }
      }
    } else {
      for (const line of tab.fieldsWithLayout) {
        for (const field of line) {
          if (this.fieldInvalid(field)) {
            return true;
          }
        }
      }
    }
    tab.valid = true;
    return false;
  }

  /**
   * Check if field value is valid or not
   *
   * @param  {EntityFieldDefinition} field  Current field
   * @return {boolean}                      True if invalid
   */
  public fieldInvalid(field: EntityFieldDefinition): boolean {
    // Already calculated by field itself?
    if (field.fieldState?.valid !== null && field.fieldState?.valid !== undefined) {
      return !field.fieldState.valid;
    }
    // FIXME: find a way to use EntityDetailField.fieldInvalid instead (through a CustomValidator)
    if (field.type === 'collection' && field.style === 'integrated') {
      return (field as IntegratedTableDefinition).rows?.some(
        row => row.fields.some(innerField => !row.entityAccessor.fieldValidity(innerField, field.mappedBy).valid),
      );
    }

    return !this.entityAccessor.fieldValidity(field, field.mappedBy).valid;
  }

  public getEntity(): SomeEntity {
    return this.entityAccessor.entity;
  }

  async getDefinition(forCreation: boolean): Promise<void> {
    this.definitionLoading = true;

    try {
      this.definition = await this.dataLoader.getEntityDefinition(this.entityName, forCreation);
    } catch (e) {
      this.definitionLoading = false;
      throw e;
    }
    this.userHasCustomFields = this.definition.freeStyleFields && this.definition.freeStyleFields.length > 0;
    this.canEdit = DatabaseHelper.hasCustomEditRight(this.definition)
      || DatabaseHelper.hasOverrideEditRight(this.definition) || DatabaseHelper.hasEditRight(this.definition);
    this.entityAccessor.setDefinition(this.definition);

    this.footerFields = this.definition.fields.filter(f => f.footer);
    this.integratedTablesDefinitions = this.definition.fields.filter((f: IntegratedTableDefinition) =>
      f.type === 'collection' && f.style === 'integrated'
    );

    this.tabs = EntityDetailComponent.mergeTabsIfNecessary(
      this.definition.tabs ?? [],
      this.definition.freeStyleTabs ?? [],
    );
    this.tabs.forEach(tab => EntityDetailComponent.prepareTab(tab, this.definition));

    this.definitionLoading = false;
    this.setTitleWhenReady();

    if (this.linkBy) {
      await this.configureLink();
    }

    this.definitionLoading = false;
    this.fields.set(
      this.tabs.reduce(
        (acc, tab) => acc.concat(tab.fields.map(f => ({ ...f, value: f.title }))),
        [] as EntityFieldDefinition[],
      ),
    );
    this.filterFields();
    this.cdRef.detectChanges();
  }

  /*
   * Resizes the dialog depending on the space taken by the fields inside it
   * The resulting maxWidth is a percentage of the total width of the browser window
   * We add INNER_WINDOW_PX_MARGIN pixels of margin
   * The dialog cannot shrink to less than 33% of the full window size to avoid issues with the header buttons.
   */
  private adjustWindowSizeToFitContent(selectedTab: EntityTab): void {
    if (!selectedTab.fieldsWithLayout) {
      this.dialogRef.updateSize('95%', '85%');
      return;
    }
    let maxWidth = -1;
    selectedTab.fieldsWithLayout.forEach(line => {
      let lineWidth = 0;
      line.forEach(field => {
        lineWidth += this.getMaxFieldWidth(field);
      });
      maxWidth = Math.max(lineWidth, maxWidth);
    });
    maxWidth = Math.max(maxWidth, this.getSpaceNeededForTabs());
    if (
      maxWidth + DatabaseHelper.INNER_WINDOW_PX_MARGIN < window.innerWidth * DatabaseHelper.INNER_WINDOW_FACTOR_MARGIN
    ) {
      maxWidth = (maxWidth + DatabaseHelper.INNER_WINDOW_PX_MARGIN) / window.innerWidth * 100;
      if (maxWidth < 33) {
        maxWidth = 33;
      }
      this.dialogRef.updateSize(maxWidth + '%', '84%');
    }
  }

  private getSpaceNeededForTabs(): number {
    // We consider that a tab takes roughly 10% of the screen width
    return this.tabs.length * (window.innerWidth / 10);
  }

  public static mergeTabsIfNecessary(standardTabs: EntityTab[], customTabs: EntityTab[]): EntityTab[] {
    const remainingCustomTabs: EntityTab[] = [];
    for (const customTab of customTabs) {
      const correspondingStandardTab = standardTabs.find(d => d.tabId === customTab.tabId);

      if (correspondingStandardTab) {
        // If fieldId is an array, it means that a grid is used, so fieldIds is a 2D array
        if (correspondingStandardTab.fieldIds.length && correspondingStandardTab.fieldIds[0] instanceof Array) {
          correspondingStandardTab.fieldIds.push(customTab.fieldIds);
        } else {
          correspondingStandardTab.fieldIds.push(...customTab.fieldIds);
        }
      } else {
        remainingCustomTabs.push(customTab);
      }
    }
    return [...standardTabs, ...remainingCustomTabs];
  }

  /**
   * If this (new) entity is supposed to be linked in certain way to
   * any existing entity
   */
  async configureLink(): Promise<void> {
    await this.entityAccessor.linkEntity(this.linkBy);
    if (this.linkBy.linkType === 'ManyToMany') {
      this.afterSaveLinkQuery = this.linkBy.linkQuery;
    }
  }

  setTitleWhenReady(): void {
    if (this.entityAccessor.entityLoaded() && this.definition) {
      this.title = this.entityAccessor.getEntityTitle();
    }
  }

  async getDetails(): Promise<void> {
    if (!this.entityId) {
      return;
    }
    this.dataLoading = true;
    let value: SomeEntity;
    try {
      value = await this.dataLoader.getEntity(
        this.entityName,
        { id: this.entityId, vesselId: this.vesselId } as SomeEntity,
      );
    } catch {
      this.dataLoading = false;
      this.errorMessage = 'Error while loading the entity';
      this.cdRef.detectChanges();
      return;
    }

    this.entityAccessor.setEntity(this.entityName, value, this.prefill);
    this.setTitleWhenReady();
    this.dataLoading = false;
  }

  public checkCoherency(): void {
    // Reset tab validity
    if (this.tabs) this.tabs.forEach(tab => tab.valid = null);
    this.coherencyErrors = this.entityAccessor.getFieldsErrors(this.MappedBy, true);
  }

  /**
   * Saves the entity, if the save is successfull and if the creator of the component
   * requested some actions to be taken after, they will be done
   */
  async saveAndDoAfterSave(): Promise<void> {
    if (this.tableStateService.fn && this.tableStateService.row) {
      const result = await this.tableStateService.fn.saveRow(this.tableStateService.row);
      if (!result) {
        return;
      }
    }
    /*
     * note that we have to determine the  update type, before we do the actual save
     * because isNew depends on the presence of the  idField
     */
    const updateType = this.isNew
      ? UpdateEventType.New
      : UpdateEventType.Update;
    const result = await this.saveEntity();
    if (result) {
      const entity = this.entityAccessor.entity;
      if (this.linkBy && this.linkBy.linkType === 'OneToMany') {
        /*
         * the linkType 'oneToMany' mean we are the part of the relation
         * that own 'Many' of the relation. If we have been adressed with
         * this linkedBy it mean that we were called in an addNew of an entity field.
         * In this case this field will need a title for his new entity.
         * To economize an ajax called to create this title (and some other problems
         * because this entity isn't really saved yet) we will compute his title here
         * and pass it through our entity
         */
        entity['__title_for_option'] = this.entityAccessor.getEntityTitle();
      }
      // close modal and when closed will call after save
      if (this.closeAfterSave) {
        // after saving we just save the current modal, never all of them
        this.closeModal();
      }

      if (this.afterSave) {
        const afterSafeData: AfterSave = {
          entityName: this.definition.class,
          entity,
          eventType: updateType,
          layerId: this.layerId,
          idToReload: this.idToReload ?? entity.id,
          modifiedEntityId: this.entityId,
          reloadAllLayers: this.reloadAllLayers,
          reloadDashboardConfig: this.reloadDashboardConfig,
        };
        this.afterSave(afterSafeData);
      }
    }

    /*
     * unless the modal has been closed we want to detect changes because
     * the modal and the component are still open
     */
    if (!this.closeAfterSave) {
      this.cdRef.detectChanges();
    }
  }

  public async saveEntity(): Promise<boolean> {
    this.checkCoherency();
    if (this.coherencyErrors.length > 0) {
      return false;
    }

    let success = false;
    this.saving = true;

    /*
     * if we are linking to an existing entity, we are aware of whether we should save
     * or the parent will take care of the save operation
     */
    if (this.linkBy && this.linkBy.parentWillSave) {
      return true;
    }

    if (!await this.saveIntegratedTables()) {
      this.saving = false;
      this.cdRef.detectChanges();
      return false;
    }

    const saveResult = await this.entityAccessor.saveEntity();

    if (saveResult.partialSuccess) {
      this.closeAfterSave = false;
    }

    if (saveResult.success) {
      this.leaveEditMode();
      if (this.afterSaveLinkQuery) {
        // we add the id of the new creted entity to the linking query
        this.afterSaveLinkQuery.linkedEntityId = saveResult.id;
        // now we can link the entities
        await this.dataLoader.linkEntities(
          this.afterSaveLinkQuery,
        );
      }
      success = true;
      this.dialogManager.showMessage(saveResult.message, 'success');
    } else {
      this.dialogManager.showMessage(
        saveResult.message ? saveResult.message : saveResult.error,
        'error',
      );
    }

    this.saving = false;
    this.cdRef.detectChanges();
    return success;
  }

  /** Check coherency then save all integratedTables */
  private async saveIntegratedTables(): Promise<boolean> {
    let result: { id?: number; message?: string; success: boolean } | EntityUpdateDto = { success: true };
    for (const table of this.integratedTablesDefinitions) {
      if (!this.checkIntegratedTableCoherency(table)) {
        return false;
      }

      for (const row of table.rows) {
        result = await row.entityAccessor.saveEntity();
        if (!result.success) break;
        this.entityAccessor.addLinkedEntity(table.id, result.id as number);
      }

      for (const row of table.deletedRows) {
        result = await row.entityAccessor.deleteEntity();
        if (!result.success) break;
      }

      if (!result.success) {
        this.dialogManager.showMessage(result.message, 'error');
        return false;
      }
    }
    return true;
  }

  /** Check errors in given integrated table (of type collection and style integrated) */
  private checkIntegratedTableCoherency(table: IntegratedTableDefinition): boolean {
    const lineIndexes = [];

    table.rows.forEach((row, i) => {
      const rowCoherencyErrors = row.entityAccessor.getFieldsErrors(table.mappedBy, false);
      if (rowCoherencyErrors.length) {
        lineIndexes.push(i);
      }
    });

    // build and log error message if any errors
    if (lineIndexes.length) {
      const errorMessage = EntityDetailComponent.buildErrorMessage(lineIndexes, table.title);
      this.dialogManager.showMessage(errorMessage, 'error');
      return false;
    }
    return true;
  }

  private static buildErrorMessage(linesIndexes: number[], title: string): string {
    let message = `The following table could not be saved: ${title}.\nThe following lines are invalid:\n`;
    linesIndexes?.forEach(e => message += `  - Line ${(e + 1).toString()},\n`);
    return message.slice(0, message.lastIndexOf(','));
  }

  async deleteEntity(): Promise<void> {
    this.deleting = true;
    const deleteResult = await this.entityAccessor.deleteEntity();
    if (!deleteResult.success) {
      this.handleDeleteEntityErrorMessage(deleteResult);
      this.deleting = false;
    }

    if (deleteResult.success) {
      this.afterSave({
        entityName: this.definition.class,
        eventType: UpdateEventType.Delete,
        // we mock the entity to keep the entityId and be able to do the removal actions
        entity: { id: this.entityId } as any,
        layerId: this.layerId,
        idToReload: this.idToReload,
        modifiedEntityId: this.entityId,
        reloadAllLayers: this.reloadAllLayers,
        reloadDashboardConfig: this.reloadDashboardConfig,
      });
      // after delete close the current modal
      this.closeModal();
      this.dialogManager.showMessage(
        this.definition.header + ' has been removed',
        'success',
      );
    }

    this.deleting = false;
    this.cdRef.detectChanges();
  }

  private handleDeleteEntityErrorMessage(entityDeleted: EntityDeletedDto): void {
    this.dialogManager.showMessage(
      entityDeleted.message ?? `Error while deleting ${this.definition.header}`,
      'error',
    );
  }

  get MappedBy(): string {
    const linkBy = this.linkBy;
    if (linkBy && linkBy.linkType === 'OneToOne') {
      return linkBy.fieldName;
    }
    return null;
  }

  getValue(field: EntityFieldDefinition): any {
    let value = this.entityAccessor.getValue(field);
    /*
     * A field can have a coalesce value, it means
     * if this value is null it gonna be replaced by the coalesce one
     */
    if (!value && field.coalesce) {
      const coalesceField = {
        ...field,
        id: field.coalesce,
      };
      value = this.entityAccessor.getValue(coalesceField);
    }
    return value;
  }

  public getFormattedDatetime(field: EntityFieldDefinition): any {
    const value = this.entityAccessor.getValue(field);
    /*
     * For the time being, all datetimes in DataEntry should be in local timezone
     * This can be changed to `this.timezoneService.timezone` to use the TZ of the component/dashboard
     */
    return DatabaseHelper.getFormattedDate({ [field.id]: value }, field, 'local');
  }

  shouldShowGroup(groupNames?: string[]): boolean {
    const group = this.definition.generalInfo?.groupVisibility;
    if (!groupNames?.length || !group || Object.keys(group).length === 0 || this.showAllGroups === 1) {
      return true;
    }

    if (this.showAllGroups === -1) {
      return false;
    }

    for (const groupName of groupNames) {
      if (this.modifiedGroups.indexOf(groupName) !== -1) {
        return true;
      }

      if (group[groupName] && group[groupName](this.entityAccessor)) {
        return true;
      }
    }
    return false;
  }

  public shouldShowField(field: EntityFieldDefinition): boolean {
    return this.filteredFields().some(f => f.id === field.id);
  }

  advancedFieldButtonToShow(): number {
    /*
     * no button: 0
     * Show advanced fields button: 1
     * Hide advanced fields button: -1
     */
    if (this.deleteMode) {
      return 0;
    }
    if (this.showAllGroups !== 0) {
      return this.showAllGroups * -1;
    }
    if (!this.definition?.fields.filter(f => f.groups?.length).length || !this.definition.generalInfo.groupVisibility) {
      return 0;
    }
    const groupCount = Object.keys(this.definition.generalInfo.groupVisibility)?.length;
    if (this.modifiedGroups.length === groupCount) {
      return -1;
    }
    if (groupCount) {
      for (const group in this.definition.generalInfo.groupVisibility) {
        if (!this.shouldShowGroup([group])) {
          return 1;
        }
      }
      return -1;
    }
    return 0;
  }

  toggleAdvancedFields(on: boolean): void {
    if (on) {
      this.showAllGroups = 1;
    } else {
      this.showAllGroups = -1;
    }
    this.cdRef.detectChanges();
  }

  fieldsInLine(line: EntityFieldDefinition[]): boolean {
    for (const field of line) {
      if (this.shouldShowGroup(field.groups)) {
        return true;
      }
    }
    return false;
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (this.tabs[event.index]) {
      this.adjustWindowSizeToFitContent(this.tabs[event.index]);
    } else {
      this.dialogRef.updateSize('95%', '84%');
    }

    this.$fieldsComponents.forEach(field => field.afterParentInit());
  }

  public refreshUI(): void {
    this.cdRef.detectChanges();
  }

  private get modifiedGroups(): string[] {
    return this.entityAccessor.getModifiedGroups();
  }

  /**
   * @description Filters fields in each tab based on the current search text and counts the matches.
   */
  public filterFields(text?: string): void {
    const filteredFields: EntityFieldDefinition[] = [];
    this.tabFieldMatch = [];

    if (text) {
      const lowerText = text.toLowerCase();
      this.tabs?.forEach(tab => {
        const matchingFields = tab.fields?.filter(field => field.title.toLowerCase().includes(lowerText)) ?? [];
        filteredFields.push(...matchingFields);
        this.tabFieldMatch.push(matchingFields.length);
      });
    } else {
      filteredFields.push(...this.fields());
    }

    this.filteredFields.set(filteredFields);
  }

  public getTabMatchCount(tab: EntityTab): number {
    const index = this.tabs.indexOf(tab);

    if (index === -1 || this.tabFieldMatch.length <= index) {
      return 0;
    }

    return this.tabFieldMatch[index];
  }

  public switchToFieldTab(option: PearlSelectOptionValueType): void {
    this.filterFields(option.toString());
    const fieldName = option;
    const tabIndex = this.tabs.findIndex(tab => tab.fields.findIndex(field => field.title === fieldName) !== -1);
    this.selectedTabIndex.setValue(tabIndex);
  }

  public getMaxLineWidth(): number {
    return window.innerWidth * 0.95 - 70;
  }

  public isGroupDisabled(groupNames?: string[]): boolean {
    const group = this.definition.generalInfo.groupDisabled;

    if (!groupNames?.length || !group || Object.keys(group).length === 0) {
      return false;
    }

    for (const groupName of groupNames) {
      if (group[groupName] && group[groupName](this.entityAccessor)) {
        return true;
      }
    }

    return false;
  }
}
