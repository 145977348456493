import { Injector } from '@angular/core';

import { ComponentParameter, ComponentStateOptions, DashboardState, DateRange, DeepReadonly, HeavyActivityQuery,
  LayerId } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { PunctualShapeSettings, ScheduleLayerOptions, ScheduleLike } from './schedule-types';
import { LayerCache } from '../dashboards/map/layer-loader';
import { ScheduleDashboardSettings } from '../helpers/config-types';
import { DialogManager } from '../database/dialog-manager';
import { ScheduleTimeManager } from './schedule-time-manager';
import { getChained } from '../data-loader/ref-data-provider';
import { DataHelpers } from '../helpers/data-helpers';
import { NavigationHelper } from 'src/helpers/navigation-helper';

// Default interval & property for recent items
export const DEFAULT_RECENT_INTERVAL = 7 * 86400 * 1000; // 1 week (in ms)
export const MAX_RECENT_INTERVAL = 31 * 86400 * 1000; // 1 month (in ms)
export const DEFAULT_RECENT_PROPERTY = 'ctime';

/**
 * Responsible for loading of schedule layers
 */
export class ScheduleLayerLoader {
  private dataLoader: DataLoader;
  private dialogManager: DialogManager;
  public layerCaches: { [layerId: string]: LayerCache } = {};
  private settings: ScheduleDashboardSettings;
  public scheduleState: DashboardState = {};
  /**
   * Holds the unique data promise for each layer. \
   * We want to have maximum 1 ongoing data promise per "regular" layer.
   * This reference is used to cancel the ongoing promise for a lazy loading layer when an update is requested.
   */
  private dataPromises: { [layerId: string]: Promise<DeepReadonly<ScheduleLike[]>> } = {};
  private layerRanges: { [layerId: string]: [ScheduleLike, ScheduleLike] } = {};

  constructor(
    dataLoader: DataLoader,
    settings: ScheduleDashboardSettings,
    injector: Injector,
  ) {
    this.settings = settings;
    this.dataLoader = dataLoader;
    this.dialogManager = injector.get(DialogManager);
  }

  /**
   * @description Load the data for a given layer, caching the result to `this.layerCaches[layerId]`.
   * @param layerId Id of the layer to load the data for.
   * @param forceUpdate If `false` and layer data is already present in the cache, return the cached results.\
   *                    If `true` or no layer data present in cache, fetch the data and update the cache before
   *                    returning the result.
   * @param url The url to fetch to get the layer's data.
   */
  public async loadScheduleLayerData({ layerId, dataUrl, forceUpdate }: {
    layerId: LayerId;
    dataUrl: string;
    forceUpdate: boolean;
  }): Promise<ScheduleLike[]> {
    /**
     * Init the cache if not present
     * Because in schedule wrapper we use a url with params, this url cannot be read directly from the config,
     * and thus needs to be passed as a parameter and stored in `this.layersCache`.
     */
    if (!this.layerCaches[layerId]) {
      this.layerCaches[layerId] = { scheduleItemsById: {}, url: dataUrl };
    }

    // Init the cache's url if necessary (for example if cache initialized by this.loadScheduleLayerRange)
    if (!this.layerCaches[layerId].url) {
      this.layerCaches[layerId].url = dataUrl;
    }

    const layerCache = this.layerCaches[layerId];

    // Layer data has already been loaded, return the data already present in layerCache.scheduleItemsById
    if (Object.keys(layerCache.scheduleItemsById).length && !forceUpdate) {
      return Object.values(layerCache.scheduleItemsById);
    }

    // Store the HTTP promise to be able to cancel it
    const dataPromise = this.dataLoader.get<ScheduleLike[]>(dataUrl, { forceUpdate });
    this.dataPromises[layerId] = dataPromise;
    const data = await dataPromise;
    layerCache.scheduleItemsById = data.reduce<ScheduleLike>((obj, item) => {
      obj[item.id ?? item.contractId ?? item.title] = item;
      return obj;
    }, {} as any);

    return Object.values(layerCache.scheduleItemsById);
  }

  /**
   * Cancel the data promise of the given layer.
   *
   * @param layerId The layer ID.
   */
  public cancelDataPromise(layerId: string): void {
    if (this.dataPromises[layerId]) {
      this.dataLoader.cancelRequest(this.dataPromises[layerId]);
    }
  }

  /**
   * Get the given layer's range as schedule data items.
   *
   * @param layerId   The layer ID
   */
  public getLayerRangeItems(layerId: string): ScheduleLike[] {
    return this.layerRanges[layerId] ?? [];
  }

  /**
   * @description Load the range for a given layer, caching the result to `this.layersCache` and `this.layerRanges`.
   * This is used to initialize the schedule interval when it has layers with a `rangeUrl` configured, which
   * when fetched returns the min and max layer dates.
   *
   * @param layerId Id of the layer to load the range for.
   * @param rangeUrl The url to fetch to get the layer's range.
   * @param urlParams If given, `urlParams` will be injected in the rangeUrl before fetching it.
   */
  public async loadScheduleLayerRange({ layerId, rangeUrl, urlParams }: {
    layerId: LayerId;
    rangeUrl: string;
    urlParams?: ComponentParameter[];
  }): Promise<void> {
    urlParams = urlParams ?? null;

    // Init the cache if not present
    if (!this.layerCaches[layerId]) {
      this.layerCaches[layerId] = {
        scheduleItemsById: {},
        rangeUrl: rangeUrl,
      };
    }

    // Update the cache's rangeUrl if necessary (for example if cache initialized by this.loadScheduleLayerData)
    if (!this.layerCaches[layerId].rangeUrl) {
      this.layerCaches[layerId].rangeUrl = rangeUrl;
    }

    const layerCache = this.layerCaches[layerId];

    // Return if layer range has already been loaded
    if (layerCache.rangeLoaded || this.layerRanges[layerId]) {
      return;
    }

    let fullUrl = layerCache.rangeUrl;

    /**
     * Only keep the first query part of the url.
     * example.com/path?param=value?param2=value2 => example.com/path?param=value
     * Useless code?
     */
    const urlSplit = fullUrl.split('?');
    const params = urlSplit.length > 1 ? `?${urlSplit[1]}` : '';
    fullUrl = `${urlSplit[0]}${params}`;
    /** Useless code? */

    // Inject page parameters if needed for schedule wrapper
    if (urlParams) {
      fullUrl = NavigationHelper.injectParametersInURL(fullUrl, urlParams);
    }

    // Fetch the range
    const { minDate, maxDate } = await this.dataLoader.get<DateRange>(fullUrl);

    // Create ScheduleLike objects from range
    const minDateScheduleLike: ScheduleLike = {
      title: 'firstActivity',
      dateStart: minDate,
      dateEnd: minDate,
      show: false,
      layerId: layerId,
      uniqueId: null,
      id: '__firstActivity' as any,
      shortItemTitle: '',
      longItemTitle: '',
      description: '',
    };
    const maxDateScheduleLike: ScheduleLike = {
      title: 'lastActivity',
      dateStart: maxDate,
      dateEnd: maxDate,
      show: false,
      layerId: layerId,
      uniqueId: null,
      id: '__lastActivity' as any,
      shortItemTitle: '',
      longItemTitle: '',
      description: '',
    };

    this.layerRanges[layerId] = [minDateScheduleLike, maxDateScheduleLike];
    layerCache.rangeLoaded = true;
  }

  /**
   * Function used by the heavy layer to retrieve information on the vessels according
   * to the different filters in front end.
   */
  getLayerOrderedVessel = (
    layerId: LayerId,
    filterByInterval: boolean,
    filterByTab: boolean,
    display?: ComponentStateOptions,
    intervalExtent?: [number, number],
    restrictedVesselList: number[] = null,
  ): Promise<any[]> => {
    const layerConfig = this.settings.layers[layerId];

    const appliedFilters = {
      ...this.scheduleState['common-filters'],
      ...this.scheduleState[layerId],
    };

    /*
     * url: url of the endpoint
     * group: selected groupby
     * tabFilterSelected: selected tab filter value
     * intervalExtent: latest extent of the schedule interval
     * appliedFilters: all filters coming from sidebar
     * fieldsets: config description of the fieldsets (used to correctly use appliedFilters)
     * selectsConfig: config description of selects (use to applied groupby and tabFilterSelected)
     * filterByInterval: boolean used to know if we filter or not by interval
     * specificFilters: dictionary to establish the white list of filters
     * to apply or not according to certain values in the data
     */
    const query: HeavyActivityQuery = {
      group: filterByTab && display?.groupby ? display?.groupby : '',
      tabFilterSelected: filterByTab ? (display?.tabFilterSelected ? display.tabFilterSelected : '') : null,
      intervalExtent: filterByTab ? intervalExtent : null,
      appliedFilters: appliedFilters,
      selectsConfig: this.settings.graph?.selects,
      filterByInterval: filterByInterval,
      specificFilters: layerConfig.specificFilters,
      restrictedVesselList: restrictedVesselList,
    };

    return this.dataLoader.post<HeavyActivityQuery, any[]>(layerConfig.vesselOrderUrl, query, { cacheResult: true })
      .catch(error => {
        this.dialogManager.showMessage(error, 'error');
        return [];
      });
  };

  /**
   * Function called to get heavy data, this data will be cached, filtered and directly injected in scheduleDrawingData
   */
  getScheduleVesselHeavyData = (
    layerId: LayerId,
    vessels: string[],
    managers: string[],
    forceUpdate: boolean = false,
  ): Promise<DeepReadonly<ScheduleLike[]>> => {
    const layer = this.settings.layers[layerId];
    const fullUrl = `${layer.heavyDataUrl}?scheduleVessels=${vessels.join(',')}&managerAwards=${managers.join(',')}`;
    return this.dataLoader.get<ScheduleLike[]>(fullUrl, { forceUpdate }).catch(error => {
      this.dialogManager.showMessage(error, 'error');
      return [];
    });
  };

  /**
   * Function used to get heavy layer data for populate tabs
   * We call the layerOrderedVessel endpoint without interval and tab filter
   * (because initTab count doesn't depend on these filters)
   */
  getHeavyLayerDataForTab(layerId: LayerId): Promise<any[]> {
    return this.getLayerOrderedVessel(layerId, false, false);
  }

  /**
   * Function used to get all the vessel for a layer without applying interval filter
   * (but we still applied sidebar filters + tab filter)
   */
  getLayerVesselsWithoutIntervalFilter(
    layerId: LayerId,
    display: ComponentStateOptions,
    intervalExtent: [number, number],
    restrictedVesselList: number[] = null,
  ): Promise<any[]> {
    return this.getLayerOrderedVessel(layerId, false, true, display, intervalExtent, restrictedVesselList);
  }

  /**
   * Function used to get all the vessel for a layer in interval
   * (we applied sidebar filters + tab filter + interval filter)
   */
  getLayerVesselsInInterval(
    layerId: LayerId,
    display: ComponentStateOptions,
    intervalExtent: [number, number],
    restrictedVesselList: number[] = null,
  ): Promise<any[]> {
    return this.getLayerOrderedVessel(layerId, true, true, display, intervalExtent, restrictedVesselList);
  }

  setState(newState: DashboardState): void {
    this.scheduleState = newState;
  }

  /**
   * This necessary treatment has to be done on each layer data before trying plot data on schedule
   */
  public static processDataForSchedule(
    item: DeepReadonly<ScheduleLike>,
    layerId: LayerId,
    layerOptions: ScheduleLayerOptions,
    falseContracts: boolean = false,
  ): ScheduleLike {
    /**
     * Intentionally skip the deep copy because we have a lot of data on the schedules
     * but we can maybe use the dataLoader postProcessFunction instead there
     * to avoid doing the processing each time we get the same url from the data loader
     */
    const result = DataHelpers.makeMutable<ScheduleLike>(item, false);
    result.show = true;
    result.showAfterSidebar = true;

    /*
     * Assigning layer id and unique id to each component,
     * both are used later for positioning and drawing
     */
    result.layerId = layerId;
    const someUniqueId = result.id ?? result.contractId ?? result.title;
    const uniqueId = result.layerId + someUniqueId;
    result.uniqueId = uniqueId;

    /*
     * Layer can have special punctual shape which has only one point
     * We use a `some` to test each punctualShapeSettings and stop as soon as a punctualShape has been found
     */
    layerOptions.punctualShapes?.some(punctualShapeSettings => {
      /**
       * Order in layer.punctualShape is important.
       * When there is a match, other punctualShapeSettings are not tested.
       */
      if (result.punctualShape) return true;

      this.assignPunctualShape(result, punctualShapeSettings);
    });

    // It's possible item is load just to display a vessel line but shouldn't be display in schedule
    result.falseContract = falseContracts;

    // Mark recently added items as '__new'
    if (layerOptions.highlightAfter) {
      result.__new = (getChained(result, DEFAULT_RECENT_PROPERTY)
        && (getChained<number>(result, DEFAULT_RECENT_PROPERTY) >= layerOptions.highlightAfter))
        && (result.dateStart && (result.dateStart >= layerOptions.highlightStartAfter));
    }

    return result;
  }

  public static assignPunctualShape(item: ScheduleLike, punctualShapeSettings: PunctualShapeSettings): void {
    if (this.isPunctual(item, punctualShapeSettings)) {
      /*
       * schedule uses always dateStart and dateEnd to draw the items, even for punctual shapes.
       * these values are overridden for punctual shapes with the date at which the contract should be shown
       * scheduled[punctualDateProp] and the dateEnd is determined with algorithm to get the perfect length.
       * we have to store the original values to temporal properties because they might be needed for a tooltip
       * but we will do it only the first time - so that we really store the real dateStart and dateEnd
       * that might be potentially stored in the DB
       */

      const punctualDateProp = punctualShapeSettings.dateProp;
      item.__savedDateStart ??= item.dateStart;
      item.__savedDateEnd ??= item.dateEnd;

      // dateStart of punctual shape could be initialized just once
      item.dateStart ??= getChained(item, punctualDateProp);

      // date end of punctual shape changes on each move of the interval
      item.dateEnd = ScheduleTimeManager.getPunctualShapeDefaultDateEnd(
        getChained(item, punctualDateProp),
        punctualShapeSettings,
      );
      item.punctualShape = punctualShapeSettings;
    }
  }

  private static isPunctual(item: ScheduleLike, punctualShapeSettings: PunctualShapeSettings): boolean {
    const punctualDateProp = punctualShapeSettings.dateProp;
    const affectedValues = punctualShapeSettings.affectedValues;

    if (getChained(item, punctualDateProp)) {
      // If no affectedValues defined, return true if item has punctualDateProp
      if (!affectedValues) return true;

      /*
       * The item should match one of every affectedValues, this an "AND" at the prop level of affectedValues.
       * To achieve an "OR" you must define another punctualShapeSettings
       */
      return Object.entries(affectedValues).every(
        ([prop, values]) => values.some(value => getChained(item, prop) === value),
      );
    }

    return false;
  }
}
