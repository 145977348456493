<div [id]="componentSettings.id" class="spin-graph full-height plotly-multi">
  @if (title && !opts.scheduleGraph) {
    <div class="component-title">
      <div class="component-title-subtitle">
        <h3>{{ title }}</h3>
        @if (subtitle) {
          <div class="component-subtitle">{{ subtitle }}</div>
        }
      </div>
      <description-button [description]="description"></description-button>
    </div>
  }

  @if (!opts.hideOptions) {
    <spin-graph-options
      #chartOptions
      [graphId]="componentSettings.id"
      [opts]="opts"
      [noData]="noRawData"
      (showTable)="onTable()"
      (onchange)="onchange($event.chartParam, $event.value)"
      (xls)="onXls()"
      (ontoggletail)="ontoggletail($event)"
      (ondisplaymode)="ondisplaymode($event)"
      (onintervalchange)="onintervalchange($event)"
      (png)="onpng($event)"
      (onshowlabels)="onshowlabels($event)"
      [availableSelects]="availableSelects"
      [selects]="selects"
      [multiple]="allowMultiMetrics()"
      [messages]="messages"
      [display]="display"
      [intervalField]="intervalField"
      [minX]="minX"
      [maxX]="maxX"
      [labelCheckbox]="shouldShowLabelsCheckbox()"
      [showTableButton]="datatable"
      [showBarModeControls]="showBarModeControls"
      [dynamicGroupByState]="dynamicGroupByState">
    </spin-graph-options>
  }

  @if (showLoading()) {
    <div class="loading-indicator">
      <mat-spinner />
    </div>
  }

  @if (hasSamplingIndicator()) {
    <div class="sampling-indicator" [innerHTML]="formatSamplingText(selectedGroup.title)"></div>
  }

  @if (!noDataToShow()) {
    @if (showBarModeControls) {
      <mat-button-toggle-group [(ngModel)]="barMode" class="bar-mode-toggle">
        <mat-button-toggle [value]="'group'" (change)="onBarModeChange($event.value)">Grouped</mat-button-toggle>
        <mat-button-toggle [value]="'stack'" (change)="onBarModeChange($event.value)">Stacked</mat-button-toggle>
      </mat-button-toggle-group>
    }
    <div
      #chartArea
      id="{{ plotlyChartId }}"
      data-testid="spin-plotly-multi"
      class="chart-area"
      [class.no-metric-selected]="noMetricsSelected()"
      [class.hidden]="loading"></div>
  }

  @if (!noMetricsSelected() && noDataToShow()) {
    <div class="no-data-wrapper">
      <span
        >No data available
        @if (!noRawData && selects.metric && selects.metric.values.length > 1) {
          <span> - Try changing metric</span>
        }
      </span>
    </div>
  }
  @if (noMetricsSelected()) {
    <div class="no-data-wrapper">
      <span>No metrics selected - Please select a metric</span>
    </div>
  }
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
