<div class="mat-dialog-title-container" mat-dialog-title>
  <h2>Check reporting config</h2>
</div>

<div class="mat-dialog-content-container" mat-dialog-content>
  <div>
    <pearl-form-field label="Select a vessel">
      <mat-select [(ngModel)]="formData.vesselIds" multiple>
        @for (vessel of data.vessels; track vessel) {
          <mat-option [value]="vessel.id">
            {{ vessel.title }}
          </mat-option>
        }
      </mat-select>
    </pearl-form-field>

    <pearl-form-field label="Select a project">
      <mat-select [(ngModel)]="formData.projectIds" multiple>
        @for (project of data.projects; track project) {
          <mat-option [value]="project.id">
            {{ project.title }}
          </mat-option>
        }
      </mat-select>
    </pearl-form-field>

    <pearl-form-field label="Select a document type">
      <mat-select [(ngModel)]="formData.documentTypeIds" multiple>
        @for (documentType of data.documentTypes; track documentType) {
          <mat-option [value]="documentType.id">
            {{ documentType.title }}
          </mat-option>
        }
      </mat-select>
    </pearl-form-field>

    <pearl-form-field label="From date">
      <input
        matInput
        [pearlDatepicker]="fromPicker"
        [maxDate]="formData.to"
        [value]="formData.from"
        timezone="UTC"
        (dateChange)="formData.from = $event.value" />
      <ng-container pearlFormSuffix>
        <pearl-datepicker-toggle [for]="fromPicker" />
        <pearl-datepicker #fromPicker />
      </ng-container>
    </pearl-form-field>

    <pearl-form-field label="To date">
      <input
        matInput
        [pearlDatepicker]="toPicker"
        [minDate]="formData.from"
        [value]="formData.to"
        timezone="UTC"
        (dateChange)="formData.to = $event.value" />
      <ng-container pearlFormSuffix>
        <pearl-datepicker-toggle [for]="toPicker" />
        <pearl-datepicker #toPicker endOfPeriod />
      </ng-container>
    </pearl-form-field>
  </div>
</div>

<div mat-dialog-actions class="spin-dialog-buttons">
  <pearl-button type="alternative" (click)="onClosedClicked()">Cancel</pearl-button>
  <pearl-button type="primary" (click)="onValidateConfigClicked()">Validate config</pearl-button>
</div>
