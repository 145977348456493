<div [id]="componentSettings.id" class="spin-graph full-height plotly-boxplot">
  @if (title && !opts.scheduleGraph) {
    <div class="component-title">
      <h3>{{ title }}</h3>
      <description-button [description]="description" />
    </div>
  }

  @if (!opts.hideOptions) {
    <spin-graph-options
      #chartOptions
      [graphId]="componentSettings.id"
      [opts]="opts"
      [noData]="noRawData"
      (showTable)="onTable()"
      (onchange)="onchange($event.chartParam, $event.value)"
      (xls)="onXls()"
      (onintervalchange)="onintervalchange($event)"
      (png)="onpng()"
      [availableSelects]="availableSelects"
      [selects]="selects"
      [display]="display"
      [intervalField]="intervalField"
      [showTableButton]="datatable"
      [minX]="minX"
      [maxX]="maxX"
      [dynamicGroupByState]="dynamicGroupByState" />
  }

  @if (loading) {
    <div class="loading-indicator">
      <mat-spinner />
    </div>
  }
  @if (!noRawData) {
    <div
      #chartArea
      id="{{ plotlyChartId }}"
      data-testid="spin-boxplot"
      class="chart-area"
      [class.hidden]="loading"></div>
  }

  @if (noRawData) {
    <div class="no-data-wrapper">
      <span>No data available</span>
    </div>
  }
</div>

<chart-tooltip (navigate)="navigateToPage($event)" />
