<pearl-button type="icon" icon="more" [matMenuTriggerFor]="menu" aria-label="graph selects menu" />
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let select of selectsgroup">
    <spin-filters-select
      *ngIf="selects[select] && selects[select].values.length"
      (onchange)="onChange(select, $event)"
      [id]="select"
      [value]="$any(display[select])"
      [field]="selects[select]"
      [multiple]="select == 'metric' && multiple"
      [message]="messages[select]">
    </spin-filters-select>
  </ng-container>
</mat-menu>
