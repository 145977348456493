<div [id]="componentSettings.id" class="spin-graph full-height">
  @if (title && !opts.scheduleGraph) {
    <div class="component-title">
      <h3>{{ title }}</h3>
      <description-button [description]="description"></description-button>
    </div>
  }

  @if (!opts.hideOptions) {
    <spin-graph-options
      #chartOptions
      [graphId]="componentSettings.id"
      [opts]="opts"
      [noData]="noRawData"
      (showTable)="onTable()"
      (onchange)="onchange($event.chartParam, $event.value)"
      (xls)="onXls()"
      (onintervalchange)="onintervalchange($event)"
      (png)="onpng($event)"
      [availableSelects]="availableSelects"
      [selects]="selects"
      [display]="display"
      [intervalField]="intervalField"
      [minX]="minX"
      [maxX]="maxX"
      [showTableButton]="datatable"
      [dynamicGroupByState]="dynamicGroupByState">
    </spin-graph-options>
  }

  @if (loading) {
    <div class="loading-indicator">
      <mat-spinner></mat-spinner>
    </div>
  }

  @if (!noRawData) {
    <div #chartArea id="{{ plotlyChartId }}" class="chart-area" [class.hidden]="loading"></div>
  }

  @if (noRawData) {
    <div class="no-data-wrapper">
      <span>No data available</span>
    </div>
  }
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
