<div [id]="componentSettings.id" class="spin-graph full-height plotly-multi">
  @if (title && !opts.scheduleGraph) {
    <div class="component-title">
      <div class="component-title-subtitle">
        <h3>{{ title }}</h3>
        @if (subtitle) {
          <div class="component-subtitle">{{ subtitle }}</div>
        }
      </div>
      <description-button [description]="description"></description-button>
    </div>
  }

  @if (!opts.hideOptions) {
    <spin-graph-options
      #chartOptions
      [graphId]="componentSettings.id"
      [opts]="opts"
      [noData]="noRawData"
      (showTable)="onTable()"
      (onchange)="onchange($event.chartParam, $event.value)"
      (xls)="onXls()"
      (onintervalchange)="onintervalchange($event)"
      (png)="onpng($event)"
      [availableSelects]="availableSelects"
      [selects]="selects"
      [display]="display"
      [intervalField]="intervalField"
      [minX]="minX"
      [maxX]="maxX"
      [showTableButton]="datatable"
      [showBarModeControls]="showBarModeControls">
    </spin-graph-options>
  }

  @if (loading) {
    <div class="loading-indicator">
      <mat-spinner></mat-spinner>
    </div>
  }

  @if (!noDataToShow()) {
    <div #chartArea id="{{ plotlyChartId }}" class="chart-area" [class.hidden]="loading"></div>
  }

  @if (noDataToShow()) {
    <div class="no-data-wrapper">
      <span
        >No data available
        @if (!noRawData && selects.metric && selects.metric.values.length > 1) {
          <span> - Try changing metric</span>
        }
      </span>
    </div>
  }
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
