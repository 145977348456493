import { DataHelpers } from '../../helpers/data-helpers';
import { FieldValidityResult } from '../../helpers/types';

export function validateDigitsLengthEntry(
  requiredLength: number,
  enteredNumber: number,
): undefined | { valid: boolean; msg: string } {
  if (`${enteredNumber}`.length !== requiredLength) {
    return {
      valid: false,
      msg: `must be ${requiredLength} digits long`,
    };
  }
}

export function validateUrlEntry(url: string): FieldValidityResult {
  if (!(/^(http:\/\/|https:\/\/)/.test(url))) {
    return {
      valid: false,
      msg: 'must start with http:// or https://',
    };
  }
  return { valid: true };
}

export function validateCamelCaseEntry(text: string): FieldValidityResult {
  /**
   * [a-z]+: String should start with one to unlimited lowercase chars
   * ([A-Z][a-z]*)*: String should end with zero to unlimited chars following the pattern
   */
  if (!(/^[a-z]+([A-Z][a-z]*)*$/).test(text)) {
    return {
      valid: false,
      msg: 'must be in camelcase',
    };
  }
  return { valid: true };
}

/**
 * This function check if an email domain regex is a valid regex and
 * contains at least "@" and "$" characters and characters in between these symbols
 */
export function validateEmailDomainEntry(emailRegex: string): FieldValidityResult {
  try {
    const _ = new RegExp(emailRegex);
  } catch (error) {
    if (error instanceof SyntaxError) {
      return {
        valid: false,
        msg: `Regex syntax error: ${error.message}`,
      };
    } else {
      throw error;
    }
  }

  if (!new RegExp(/@[^@]+\$/).test(emailRegex)) {
    return {
      valid: false,
      msg: 'Invalid regex: regex should be in the form "[some-optional-chars]@[at-least-one-char]$"',
    };
  }

  return { valid: true };
}

export function validateEmailEntry(value: string): FieldValidityResult {
  if (DataHelpers.validateEmail(value)) {
    return { valid: true };
  }

  return {
    valid: false,
    msg: `${value} is not a valid email`,
  };
}

export function validateNumberFormatEntry(text: string): FieldValidityResult {
  /**
   * \d\.\d-\d: With a, b and c being single digit numbers, the string should have the format a.b-c.
   * Second condition is that b must be inferior or equal to c.
   */
  const regexNumberFormat = /^\d\.(\d)-(\d)$/;
  const match = text.match(regexNumberFormat);
  if (match === null || (parseInt(match[1]) > parseInt(match[2]))) {
    return {
      valid: false,
      msg: 'Format must be a.b-c with a, b and c being single digit numbers and b <= c.',
    };
  }
  return { valid: true };
}
