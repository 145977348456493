import dayjs, { Dayjs } from 'dayjs';

import { DateTimezone, InitBrushConfigValue, Interval, SpinTimeUnit, milliSecondInPeriod } from './types';
import { AxisFormatting, SelectableGroupBy } from '../graph/chart-types';
import { ChartingHelpers } from '../graph/charting-helpers';
import { TimezoneService } from '../helpers/timezone.service';
import { DateHelper } from './date-helper';

/**
 * TimeCreator provides functions to handle times and intervals
 * It also provides dates formatting. In order to format dates TimeCreator uses the timezone passed in the constructor.
 * This is mainly used by schedule - there are schedules in UTC and schedules in Local time
 *
 * Dates are formatted by creating DayJs timestamps (in UTC or in local) which are then formatted
 */
export class TimeCreator {
  public timezoneService: TimezoneService;
  public datatimeZone: DateTimezone;

  private readonly MAX_GROUPBY_TICKS = {
    day: 31,
    year: 35,
    default: 20,
  };

  /**
   * @constructor
   *
   * @param {TimezoneService} timezoneService   Timezone service
   * @param {DateTimezone}    dataTimeZone
   */
  constructor(timezoneService: TimezoneService, dataTimeZone: DateTimezone) {
    this.timezoneService = timezoneService;
    this.datatimeZone = dataTimeZone;
  }

  /**
   * Formats a timestamps
   * @param d Timestamp
   * @returns String formatted value for export either 'YYYY-MM-DD hh:mm' or 'YYYY-MM-DD'
   */
  public timeStampExportFormat(d: number): string {
    const date = this.getDayJsForFormatting(d);
    if (!date.isValid()) {
      return `${d}`;
    }
    let format: string;
    if (date.hour() == 0 && date.minute() == 0) {
      format = 'YYYY-MM-DD';
    } else {
      format = 'YYYY-MM-DD HH:mm';
    }

    return date.format(format);
  }

  public beginningOfNextPeriodTimestamp(timestamp: number, periodUnit: SpinTimeUnit): number {
    const day = this.getDayJsForFormatting(timestamp);
    const endOfCurrentPeriod = day.endOf(periodUnit);
    const nextPeriod = endOfCurrentPeriod.add(1, 'second');
    return nextPeriod.valueOf();
  }

  public static getInitTimestamps(
    now: Dayjs,
    initBrushInterval: InitBrushConfigValue,
    initBrushUnit: string,
    minDate: number,
    maxDate: number,
    configMinDate: number,
    closestFromToday?: number,
    fixedInterval?: boolean,
  ): Interval {
    // autofitting the brush, just add a margin of 5 percent to either side
    let sideMargin = (maxDate - minDate) * 0.05;

    // if there is only 1 point in dataset, margin would be 0, we will use 1 day
    if (sideMargin === 0) {
      sideMargin = milliSecondInPeriod.day;
    }

    if (initBrushInterval === 'auto') {
      return [Math.max(minDate - sideMargin, configMinDate), maxDate + sideMargin];
    }

    if (!initBrushInterval) {
      initBrushInterval = [-6, 24];
    }

    if (!initBrushUnit) {
      initBrushUnit = 'month';
    }

    const unit = initBrushUnit;

    /*
     * If initBrushInterval is [number, number] and unit is fixed
     * it means that this interval is a timestamp extent we should use to init interval
     */
    if (
      initBrushInterval
      && initBrushInterval.length === 2
      && Number(initBrushInterval[0])
      && Number(initBrushInterval[1])
      && unit === 'fixed'
    ) {
      return [Number(initBrushInterval[0]), Number(initBrushInterval[1])];
    }

    const calculatedInterval = initBrushInterval.map((value, index) => {
      let referencedDate = now.clone();
      let periodValue = value as number;

      /*
       * to calculate interval[0] and interval[1] we can use some keywords:
       * date_max +/- value: interval bound is calculated from the data maxDate
       * date_min +/- value: interval bound is calculated from the data minDate
       * relative_min +/- value: interval bound is calculated relatively from the min between data minDate and now
       * relative_max +/- value: interval bound is calculated relatively from the max between data maxData and now
       * past_relative +/-: interval bound is calculated by taken the min between max date in the past and today
       *                    (so it's the closest date in the past before today)
       * Fixed return the given timestamp
       */
      if (typeof value === 'string') {
        periodValue = Number(value.replace(/[^0-9\.-]+/g, ''));
        periodValue = periodValue ? periodValue : 0;
        if (value.includes('date_max')) {
          referencedDate = dayjs(maxDate);
        } else if (value.includes('date_min')) {
          referencedDate = dayjs(Math.max(minDate, configMinDate));
        } else if (value.includes('relative')) {
          if (value.includes('past_relative')) {
            referencedDate = closestFromToday ? dayjs(closestFromToday) : dayjs();
          } else if (value.includes('relative_min')) {
            referencedDate = dayjs(Math.min(minDate, now.valueOf()));
          } else {
            referencedDate = dayjs(Math.max(maxDate, now.valueOf()));
          }
        } else if (value.includes('fixed')) {
          return dayjs(periodValue).valueOf();
        }
      }
      return index === 1
        ? referencedDate.add(periodValue, unit as any).valueOf()
        : Math.max(referencedDate.add(periodValue, unit as any).valueOf(), configMinDate);
    }) as Interval;

    if (fixedInterval) {
      return calculatedInterval;
    }

    /*
     * there might be a situation when we get invalid interval
     * eg -12, auto where -12 means 12 months before now and auto
     * just goes to the maximum in the dataset which can be before
     * it usually means that th config should be changed to use relative. but we do some
     * effort to show some reasonable interval
     */
    if (calculatedInterval[1] <= calculatedInterval[0]) {
      console.warn('Calculated interval is not coherent: ' + calculatedInterval.toString());
      calculatedInterval[0] = Math.min(calculatedInterval[0], calculatedInterval[1]) - sideMargin;
      calculatedInterval[1] = calculatedInterval[0] + sideMargin;
    }

    return calculatedInterval;
  }

  /**
   * TODO: Check if this is still necessary after Greg's refacto. If not this can fallback to DateHelper.getDayJs
   * This method creates an instance for `daysJs` in local or utc zone so that it can be correctly used for
   * showing (formatting) the date, this is not as simple as it seems:
   * All dates are handled and received from server s UTC timestamps.
   * These timestamps are shown either as UTC or local date-times. Each component decides if it wants to see the data
   * in UTC or in local (config of the component, fallbacks on config of the app. In general Spinrig and Construction
   * are showing charts in UTC, SFM in local times - but there are exceptions, eg analytics in SFM).
   * The issue is that Plotly shows all timestamps in local zone, so that if we want to show a UTC chart with plotly
   * we have o convert the timestamps (see enforceLocalTz)
   * The following issue is that once the data is has been "locally enforced" we have to show it in the tooltips/excels -
   * taking into account that it has been moved
   * That is the reason for which this method has to look at:
   * whether the chart should show local or UTC data
   * whether the timestamps have been changed or no.
   *
   * Note that the same formatting methods can be used by our charts (mainly schedule) and plotly - that is good because
   * we want the same formatting capabilities (choosing the good format depending on grouping/interval)
   * to be available on both systems.
   * @param d timestamp
   * @returns instance of dayjs
   */
  getDayJsForFormatting(d: number): dayjs.Dayjs {
    // For plotly charts, timestamps are already 'enforced', no need to do it twice
    if (this.datatimeZone === 'local') {
      return dayjs(d);
    }
    // Else use DateHelper.getDayjs()
    return DateHelper.getDayjs(d, this.timezoneService.timezone);
  }

  /**
   * For given metric (groupby config) and time interval returns AxisFormatting.
   * Axis formatting contains methods and formats to export, format the timestamps
   */
  public getXAxisFormattingForGroupbyTimeAxis(groupBy: SelectableGroupBy, interval: Interval): AxisFormatting {
    let d3Format = '%d %b %H:%M'; // '04 jan 08:45'

    /*
     * formatter is used in the tooltip, it depends on the tz on of the data
     * for plotly charts the localTZ was enforced so the data timezone will be local
     */
    const formatter = (d: number): string => {
      const date = this.getDayJsForFormatting(d);
      return ChartingHelpers.renderTimeToGroupByLevel(date, groupBy);
    };

    const intervalLength = interval[1] - interval[0];

    const dayTickLength: number = milliSecondInPeriod.day;
    let dtick: number | string = dayTickLength;
    let avgTickLength: number;

    switch (groupBy.value) {
      case 'year':
      case 'y':
        d3Format = '%Y'; // '2023'
        dtick = 'M12';
        avgTickLength = dayTickLength * 365;
        break;
      case 'quarter':
        d3Format = 'Q%q %Y'; // 'Q1'
        dtick = 'M3';
        avgTickLength = dayTickLength * 90;
        break;
      case 'month':
        d3Format = '%b %Y'; // 'jan 2023
        dtick = 'M1';
        avgTickLength = dayTickLength * 30;
        break;
      case 'week':
        d3Format = '%b %-d'; // 'jan 4' (no leading 0 on day number)
        dtick = 7 * dayTickLength;
        avgTickLength = dayTickLength * 7;
        break;
      case 'day':
      case 'date': // groupby date is used on heavy series and is equivalent to group by day in this context
      case 'hour':
        d3Format = '%b %-d'; // 'jan 4' (no leading 0 on day number)
        avgTickLength = dayTickLength;
        break;
      default:
        return this.getTimeAxisFormattingForInterval(interval, null, groupBy);
    }

    // Estimate the number of ticks (we won't show all of them if there would be too many).
    const numberOfTicks = intervalLength / avgTickLength;

    /*
     * For day grouping, we allow 31 ticks (one month).
     * For year grouping, we allow 35 ticks (years are short texts so we can have a lot).
     * For other cases, allow 20 ticks.
     * This maximum value should be adapted to the chart width.
     */
    const maxTicks = ['day', 'date', 'd'].includes(groupBy.value)
      ? this.MAX_GROUPBY_TICKS.day
      : groupBy.value === 'year'
      ? this.MAX_GROUPBY_TICKS.year
      : this.MAX_GROUPBY_TICKS.default;

    // In case there are too many ticks, we set the dtick to null and plotly will choose how many ticks it will show
    if (dtick && numberOfTicks > maxTicks) {
      dtick = null;
    }

    /*
     * For formats without the year, we check that the data does not span over several years
     * otherwise we add the year to the formatting
     */
    if (d3Format === '%b %-d') {
      const yearDifference = dayjs(interval[1]).year() - dayjs(interval[0]).year();
      d3Format = yearDifference > 0
        ? '%b %-d, %Y' // 'jan 4, 2023'
        : d3Format;
    }

    return {
      isTimeFormatting: true,
      formatter: formatter,
      d3Format: d3Format,
      exportFormatter: d => this.timeStampExportFormat(d),
      dtick: dtick,
      avgTickLength: avgTickLength,
    };
  }

  /**
   * For given time interval returns AxisFormatting.
   * If numberOfTicks is provided, some check will be made to ensure two consecutive ticks do not show the same
   * information.
   * If it is not provided, it means that the function is called for tooltip formatting.
   * If groupBy is provided, it is used to determine whether hour:minute should be displayed in the tooltip
   * (that's the case when the time grouping is <= 1 day)
   */
  public getTimeAxisFormattingForInterval(
    xScaleDomain: Interval,
    numberOfTicks: number = null,
    groupBy: SelectableGroupBy = null,
  ): AxisFormatting {
    let tooltipFormat = 'D MMM';
    let d3Format = '%d %b'; // '04 jan'
    let dtick: number | string;
    let avgTickLength: number | null = null;
    const formatter: (d: number) => string = null;
    if (!xScaleDomain) {
      return null;
    }
    const checkIdenticalTicks = numberOfTicks !== null;
    const sizeXScaleDomain = xScaleDomain[1] - xScaleDomain[0];
    const nbDayExtent = sizeXScaleDomain / milliSecondInPeriod.day;

    if (nbDayExtent > 300 && (checkIdenticalTicks ? numberOfTicks + 2 < nbDayExtent / 30 : true)) {
      /*
       * If the interval is more than 300 days we display the month and the year.
       * The second condition ensure that the numberOfTicks to display is less than the number of month in the extent
       * it avoid to display the same information in two consecutive ticks
       * we add +2 to numberOfTicks because the numberOfTicks display is dynamic and can reach numberOfTicks+2
       */
      tooltipFormat = 'MMM YYYY';
      d3Format = '%b %Y'; // 'jan 2023'
    } else if (nbDayExtent > 30 && (checkIdenticalTicks ? numberOfTicks + 2 < nbDayExtent : true)) {
      /*
       * If the interval is less than 300 days and more than 30, we display the day, the month and the year
       * The second condition ensure that the numberOfTicks to display is less than the number of day in the extent
       */
      tooltipFormat = 'D MMM YYYY';
      d3Format = '%d %b %Y'; // '04 jan 2023'
    } else if (nbDayExtent < 1) {
      // If very small extent (<1 day), show more ticks, based on numberOfTicks
      dtick = TimeCreator.dtickFromSampling(sizeXScaleDomain, numberOfTicks ? numberOfTicks * 4 : 40, groupBy);
      avgTickLength = dtick;
      d3Format = '%H:%M';
    }

    let showHoursTooltip: boolean;
    let showDayTooltip = true;
    if (groupBy) {
      const samplingMs = DateHelper.parseDurationFromString(groupBy.value);
      showHoursTooltip = groupBy.value === 'none' || (samplingMs !== null && samplingMs < milliSecondInPeriod.day);
      showDayTooltip = groupBy.value === 'none' || (samplingMs !== null && samplingMs < milliSecondInPeriod.month);
      avgTickLength = samplingMs ?? avgTickLength;
    } else {
      showHoursTooltip = nbDayExtent < 4;
    }

    if (showHoursTooltip) {
      tooltipFormat += ' HH:mm';
    }
    if (showDayTooltip && tooltipFormat[0] !== 'D') {
      tooltipFormat = 'D ' + tooltipFormat;
    }

    return {
      isTimeFormatting: true,
      formatter: formatter ?? ((d: number): string => this.getDayJsForFormatting(d).format(tooltipFormat)),
      dtick,
      avgTickLength,
      d3Format: d3Format,
      exportFormatter: d => this.timeStampExportFormat(d),
    };
  }

  /**
   * For given domain (time extent), desired number of ticks, and selected time metric,
   * compute and returns the most optimal dtick for plotly for the ticks to align with the selected time metric.
   */
  public static dtickFromSampling(
    domainSize: number,
    numberOfTicks: number,
    groupBy: SelectableGroupBy,
  ): number | null {
    if (!groupBy) return null;

    const granularity = groupBy.granularity ?? groupBy.value;
    let samplingMs = DateHelper.parseDurationFromString(granularity);
    if (samplingMs === null) {
      return null;
    }

    let maxNbTick = domainSize / samplingMs;
    // if we have a small number of values, let plotly do the layout of ticks
    if (maxNbTick < numberOfTicks) return null;

    while (maxNbTick > numberOfTicks) {
      samplingMs *= 2;
      maxNbTick = domainSize / samplingMs;
    }

    return samplingMs;
  }
}
