<h1 mat-dialog-title>Drawio diagram</h1>
<div mat-dialog-content class="apply-dialog">
  <pearl-form-field class="report-date-field" label="Report date">
    <input
      matInput
      [pearlDatepicker]="picker"
      [value]="selectedDate"
      timezone="UTC"
      (dateChange)="dateChange($event.value)" />
    <ng-container pearlFormSuffix>
      <pearl-datepicker-toggle [for]="picker" />
      <pearl-datepicker #picker />
    </ng-container>
  </pearl-form-field>
  <div class="diagram-field" [ngClass]="{ 'invalid-diagram-field': !selectedProjectId }">
    <pearl-form-field label="Select a project" [hintStart]="!selectedProjectId ? 'You must select a project' : null">
      <mat-select [(ngModel)]="selectedProjectId">
        <mat-option *ngFor="let project of data.projects" [value]="project.id">
          {{ project.title }}
        </mat-option>
      </mat-select>
    </pearl-form-field>
  </div>
  <div class="diagram-field" [ngClass]="{ 'invalid-diagram-field': !selectedVesselId }">
    <pearl-form-field label="Select a vessel" [hintStart]="!selectedVesselId ? 'You must select a vessel' : null">
      <mat-select [(ngModel)]="selectedVesselId">
        <mat-option *ngFor="let vessel of data.vessels" [value]="vessel.id">
          {{ vessel.title }}
        </mat-option>
      </mat-select>
    </pearl-form-field>
  </div>
  <div class="diagram-field" [ngClass]="{ 'invalid-diagram-field': !selectedDocumentTypeId }">
    <pearl-form-field
      label="Select a document type"
      [hintStart]="!selectedDocumentTypeId ? 'You must select a document type' : null">
      <mat-select [(ngModel)]="selectedDocumentTypeId">
        <mat-option *ngFor="let documentType of data.documentTypes" [value]="documentType.id">
          {{ documentType.title }}
        </mat-option>
      </mat-select>
    </pearl-form-field>
  </div>
  <div *ngIf="data.isForCompare" class="diagram-field" [ngClass]="{ 'invalid-diagram-field': !selectedVesselId }">
    <pearl-form-field label="Drawio URL" [hintStart]="!urlComparison ? 'You must enter a Drawio url' : null">
      <input matInput type="text" [(ngModel)]="urlComparison" />
    </pearl-form-field>
  </div>
</div>
<div mat-dialog-actions class="spin-dialog-buttons">
  <pearl-button type="alternative" (click)="onNoClick()">Cancel</pearl-button>
  <pearl-button type="primary" [disabled]="validateDisabled()" (click)="confirmAndClose()">
    {{ confirmationMessage }}
  </pearl-button>
</div>
