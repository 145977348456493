import dayjs, { Dayjs } from 'dayjs';
import { orderBy } from 'lodash-es';

import { DataHelpers, getOriginalValue } from '../helpers/data-helpers';
import { DateHelper } from '../helpers/date-helper';
import { EntityFieldDefinition, FieldState, FieldValidityResult, FieldsDefinition, OptionValue,
  SomeEntity } from '../helpers/types';
import { DatabaseHelper } from './database-helper';
import { validateCamelCaseEntry, validateDigitsLengthEntry, validateEmailDomainEntry, validateEmailEntry,
  validateNumberFormatEntry, validateUrlEntry } from './helper/data-entry.validator';
import { ReportingField } from '../live-dpr/models/reporting-config-types';
import { DatetimeWithTimezoneValue, FieldDataValue } from '../live-dpr/models/reporting-types';

interface InputEvent extends Event {
  target: HTMLInputElement;
}

export class DataAccessor {
  public definition: FieldsDefinition;
  protected value: SomeEntity;
  public formattedValue: { [fieldId: number | string]: string } = {};
  private itemsCountLimit = 50;
  private modifiedGroups: string[] = [];
  protected errorMessageDetails: string;

  public setDefinition(definition: FieldsDefinition): void {
    if (!definition) {
      return;
    }
    this.definition = definition;
    this.value = {};

    /*
     * When a new definition is set, initialize each field state
     */
    for (const field of definition.fields) {
      // Special case: timezone
      if (field.type === 'timezone') {
        field.style = 'searchBar';
        field.values = DateHelper.getIdentityItemOfficialTimezones();
      }
      if (!field.fieldState) {
        field.fieldState = {} as FieldState;
      }
      if (field.values) {
        this.shouldFieldShowMoreVisible(field, field.values);
      }
    }
  }

  public setValue(value: SomeEntity): void {
    if (!value) return;
    this.value = value;
    this.definition.fields.forEach(field => {
      this.initTime(field);
      // out of consistency with the rest of the DE, make sure values are always in timestamp and not ISO string
      if (['datetime', 'date'].includes(field.type) && this.value[field.id] != null) {
        this.value[field.id] = dayjs.utc(this.value[field.id]).valueOf();
      }
      // make sure the formatted value is set for those new fields
      this.afterUpdate(field.id);
    });
  }

  public setAnything(field: EntityFieldDefinition, value: unknown): void {
    if (value === '') {
      value = null;
    } else if (typeof value === 'string') {
      value = value.trim();
    }
    this.value[field.id] = value;
    this.initTime(field);
    this.afterUpdate(field.id);
  }

  /**
   *  Specific initialization for time field:
   *  - Create the dayjs object in the field tz
   *  - Initialize the suffix (d+1)
   */
  private initTime(field: EntityFieldDefinition): void {
    if (field.type !== 'time') return;
    const fieldDef = this.definition.fields.find(f => field.id === f.id);
    let currentDatetime = this.getDatetime(field);
    if (currentDatetime) {
      if (DateHelper.isUtcOffsetZero(currentDatetime, fieldDef.timezone)) {
        currentDatetime = currentDatetime.utc();
      } else {
        currentDatetime = currentDatetime.tz(fieldDef.timezone);
      }
    } else {
      currentDatetime = null;
    }
    this.setDatetime(field, currentDatetime);
  }

  public getLinkedEntityTitle(field: EntityFieldDefinition): string {
    if (!this.value) {
      return '';
    }
    const rawValue: unknown = this.value[field.id];
    const linkedEntityTitle = DatabaseHelper.getLinkedEntityTitleForDefinition(field, this.value, rawValue);
    return linkedEntityTitle;
  }

  public getChoice(field: EntityFieldDefinition): any {
    if (this.value && field.values) {
      const valueOnEntity = this.value[field.id];
      if ((field as ReportingField).reportingFieldType === 'composite' && valueOnEntity) {
        // If we have a value on a composite field the value corresponds to the child fieldId containing selected value
        const childFieldId = valueOnEntity;
        // d.childFieldId is used to differentiate items in different child fields that have the same id.
        return childFieldId
          ? field.values.find(d => d.id === this.value[childFieldId] && d.childFieldId === childFieldId)
          : null;
      }
      const value = field.values.find(d => d.id === valueOnEntity);
      return value;
    }
    return null;
  }

  public getNumber(field: EntityFieldDefinition): number | string {
    if (this.value) {
      if (this.value[field.id] != null) {
        if (field.calc && field.format) {
          return DataHelpers.formatNumber(this.value[field.id], field.format);
        } else if (field.type === 'duration' && !field.editable) {
          return DataHelpers.formatMillisecondsDuration(this.value[field.id], field.format, field?.inputUnit);
        }
        return this.value[field.id];
      }
    }
    return null;
  }

  public getValue(field: EntityFieldDefinition): any {
    if (!this.value) {
      return;
    }

    if (
      field.type === 'entity' || field.type === 'client_entity' || field.type === 'collection'
      || field.type === 'files'
    ) {
      return this.getLinkedEntityTitle(field);
    }

    if (field.type === 'date') {
      return this.getDatetime(field);
    }

    // for checkbox return the raw value
    if (field.type === 'checkbox' || field.type === 'checkbox-details-required') {
      return this.value[field.id];
    }

    if (field.type === 'number') {
      if (this.value[field.id] || this.value[field.id] === 0) {
        return this.value[field.id];
      }
      return '';
    }
    if (field.type === 'choice') {
      return this.getChoice(field)?.title ?? '';
    }

    /**
     * handles types text (short/long).
     * timezone field type is considered as a text type
     */
    if (this.value[field.id]) {
      return this.value[field.id];
    }
    return '';
  }

  public getDatetime(field: EntityFieldDefinition): Dayjs {
    return DatabaseHelper.getDateOnValue(field, this.value);
  }

  /**
   * This function is called by entity-detail-field and entity-table when they need to set a new date.
   * This function is in charge of modifying the dayjs so that value is in the good timezone (or at least call the
   * method that do so)
   * @param field the field that contain information about where the value must be set
   * @param value the dayjs representing the new date.
   */
  public setDatetime(field: EntityFieldDefinition, value: Dayjs | number): void {
    const fieldDef = this.definition.fields.find(f => field.id === f.id);
    // we can choose to delete the date
    if (value === null) {
      const previousTime = this.getDatetime(field);
      // reset to previous value if new value is invalid and time was previously set
      if (field.type === 'time' && fieldDef.req && previousTime) {
        this.setDatetime(field, dayjs(previousTime));
      } else {
        this.value[field.id] = null;
        const fieldId = DatabaseHelper.dayJsField(field.id);
        field.suffix = '';
        this.value[fieldId] = value;
        this.afterUpdate(field.id);
      }
      return;
    }

    if (typeof value === 'number') {
      value = dayjs(value).utc();
    }

    if (field.type === 'date' && !value.isUTC()) {
      console.warn('field type date, value should be UTC');
    }

    if (field.type === 'datetime' && value.isUTC()) {
      console.warn('field type datetime, value should be local');
    }

    // Handle suffix 'd+1' for time fields
    if (field.type === 'time') {
      /*
       * update datetime value of the field based on the hour input
       * e.g. timeDate is 05/02 06:00 AM, hour input is 05:00 AM, then target datetime should be 05/02 05:00 AM
       */
      let timeDate = dayjs.utc(fieldDef.timeDate);
      if (!DateHelper.isUtcOffsetZero(timeDate, fieldDef.timezone)) {
        timeDate = timeDate.tz(fieldDef.timezone);
      }

      value = timeDate
        .hour(value.hour())
        .minute(value.minute())
        .second(value.second());

      if (
        timeDate.hour() > value.hour()
        || (fieldDef.isEndTime && timeDate.hour() === value.hour() && value.minute() === 0)
      ) {
        value = value.add(1, 'day');
      }

      /**
       * We reinforce the date timezone. This is particularly necessary on days when the time changes
       * On these days, the utc offset varies during the day.
       *
       * Example with Europe/Paris during the changeover to winter time:
       * 00:00 -> 02:00: utc offset = 120
       * after 02:00: utc offset = 60
       *
       * However, as value is initialised from timeDate, which is the date at the start of the utc offset report
       * (i.e. potentially before 2am), if nothing is done, the dayjs object keep the old utcOffset (from timeDate)
       * and so when the date is retrievied in utc the shift would be wrong
       */
      value = dayjs.tz(value.format('YYYY-MM-DD HH:mm'), fieldDef.timezone);

      field.suffix = value.date() === timeDate.date() ? '' : ' d+1';
    }

    // We then set the value in form of a number of ms since epoch in a field of this.value
    this.value[field.id] = value.utc().valueOf();
    // and lastly we set the value in his original form of moment in another field of this.value
    const fieldId = DatabaseHelper.dayJsField(field.id);
    /*
     * this field is the shown field so we set the locale moment for datetime that show date in local
     * and in utc for date that shown date in utc
     */
    this.value[fieldId] = value;
    this.afterUpdate(field.id);
  }

  public getDatetimeWithTimezone(field: EntityFieldDefinition): Dayjs {
    const valueRaw = this.getAnythingRawValue(field.id) as DatetimeWithTimezoneValue;
    if (!valueRaw || !valueRaw.datetimeUtc) return null;
    const valueDayjs = dayjs.utc(valueRaw.datetimeUtc).tz(valueRaw.timezone);
    return valueDayjs;
  }

  public setDatetimeWithTimezone(field: EntityFieldDefinition, inputEvent: InputEvent): void {
    const newValue: Dayjs = inputEvent.target.value as unknown as Dayjs;
    if (newValue == null) {
      delete this.value[field.id];
    } else {
      // dayjs objects in UTC don't have a tz
      this.value[field.id] = { timezone: newValue['$x']?.['$timezone'] ?? 'UTC', datetimeUtc: newValue.toISOString() };
    }
    this.afterUpdate(field.id);
  }

  public getTimezone(field: EntityFieldDefinition): string | undefined {
    return this.value?.[field.id]?.timezone ?? undefined;
  }

  public afterUpdate(fieldId: string | number): void {
    // removed the cached formatted value
    const formattedFieldId = 'formatted_' + fieldId;
    delete this.value[formattedFieldId];
    this.formattedValue[fieldId] = this.getAnythingDisplayValue(fieldId);
  }

  public get entity(): SomeEntity {
    return this.value;
  }

  public fieldValidity(field: EntityFieldDefinition, mappedBy: string | null = null): FieldValidityResult {
    if (!this.value) {
      return {
        valid: false,
      };
    }

    const value = this.getValue(field);

    if ((field.type === 'text' || field.type === 'number') && field.validTemplate && value) {
      let result;
      switch (field.validTemplate) {
        case 'url': {
          result = validateUrlEntry(value);
          break;
        }
        case 'imo': {
          result = validateDigitsLengthEntry(7, value);
          break;
        }
        case 'mmsi': {
          result = validateDigitsLengthEntry(9, value);
          break;
        }
        case 'camelcase': {
          result = validateCamelCaseEntry(value);
          break;
        }
        case 'emailDomain': {
          result = validateEmailDomainEntry(value);
          break;
        }
        case 'email': {
          result = validateEmailEntry(value);
          break;
        }
        case 'numberFormat': {
          result = validateNumberFormatEntry(value);
          break;
        }
        default: {
          break;
        }
      }
      if (result && !result.valid) {
        return result;
      }
    }

    if (field.type === 'entity' || field.type === 'client_entity') {
      /*
       * If we our parent is this entity we don't need to handle the field
       * because, event when it is not fill, it will be filled during save
       */
      if (mappedBy && field.id === mappedBy) {
        return { valid: true };
      }
      const entityId = this.value[field.id];
      if (field.req && !entityId) {
        return {
          valid: false,
          msg: field.title + ' is required',
        };
      }
      /*
       * We also need an error message when an entity that doesn't exist is entered in a autocomplete field
       * Even if this field is not marked as required
       */
      if (!field.req && !entityId && field.latestSearchValue?.length) {
        return { valid: false, msg: field.title + ' does not exist' };
      }
    }

    const isValueArray = field.type === 'collection' || field.type === 'files';

    if (
      field.req && (
        (!value && value !== 0 && field.nullItem === undefined)
        || (value === '__notChosen')
        || (isValueArray && !this.value?.[field.id]?.length)
        || (field.type === 'datetimeWithTimezone' && !this.value?.[field.id]?.['datetimeUtc'])
      )
    ) {
      return {
        valid: false,
        msg: field.title + ' is required',
      };
    }

    /** We want to check json validity */
    if (field.type === 'json') {
      const json = this.getValue(field);
      /** We need to check the length to avoid errors for empty field */
      if (json.length) {
        let parsedJson;
        try {
          parsedJson = JSON.parse(json);
          /** We want to empty the errors when valid format */
        } catch (e) {
          /** If the JSON.parse fail, we want to log the error in the front */
          return {
            valid: false,
            msg: e.toString(),
          };
        }
        if (typeof parsedJson !== 'object') {
          return {
            valid: false,
            msg: 'Not a JSON',
          };
        }
      }
    }

    if (this.definition && this.definition.coherencyChecks && this.definition.coherencyChecks[field.id]) {
      const checksForField = this.definition.coherencyChecks[field.id];
      for (const check of checksForField) {
        if (!check.function) {
          check.function = Function('value', 'return ' + check.check).bind(this) as (value: SomeEntity) => boolean;
        }

        // custom coherency errors
        this.errorMessageDetails = '';
        const result = check.function(this.value);

        if (result) {
          return {
            valid: false,
            msg: check.message + this.errorMessageDetails,
          };
        }
      }
    }

    return {
      valid: true,
    };
  }

  protected static toConstructForId(field: EntityFieldDefinition): string {
    return '__to_construct_for_' + field.id;
  }

  public inputValueChanges(field: EntityFieldDefinition, value: string): void {
    /*
     * anytime we type the value is removed.
     * correct value is set when option is selected
     */
    this.value[field.id] = null;

    const filteredValues = field.values.filter(d => d.title.toLowerCase().includes(value.toLocaleLowerCase()));
    DataHelpers.sortExactMatch(filteredValues, d => d.title.toLowerCase(), value);

    field.latestSearchValue = value;
    this.shouldFieldShowMoreVisible(field, filteredValues);
  }

  public shouldFieldShowMoreVisible(field: EntityFieldDefinition, values: OptionValue[]): void {
    if (values.length > this.itemsCountLimit) {
      if (field.orderField) {
        field.fieldState.filteredValues = orderBy(values, field.orderField, field.comboBoxOrder ?? 'asc')
          .slice(0, this.itemsCountLimit);
      } else {
        field.fieldState.filteredValues = values.slice(0, this.itemsCountLimit);
      }
      field.showMoreText = 'Show all ' + values.length + ' options';
      field.showMoreVisible = true;
    } else {
      if (field.orderField) {
        field.fieldState.filteredValues = orderBy(values, field.orderField, field.comboBoxOrder ?? 'asc');
      } else {
        field.fieldState.filteredValues = values;
      }
      field.showMoreVisible = false;
    }
  }

  public showAllOptionForField(field: EntityFieldDefinition): void {
    const value = field.latestSearchValue ? field.latestSearchValue : '';
    const filteredValues = field.values.filter(d => d.title.toLowerCase().includes(value.toLocaleLowerCase()));
    DataHelpers.sortExactMatch(filteredValues, d => d.title.toLowerCase(), value);
    field.fieldState.filteredValues = filteredValues;
    field.showMoreVisible = false;
  }

  public optionSelected(field: EntityFieldDefinition, option: OptionValue): void {
    // Because a comboBox can return null (when when choose to erase the selection) we must first test this case
    if (option) {
      let value = option.id;
      // If field updated is a composite we set child field w/ selected value and parent field w/ child id
      if ((field as ReportingField).reportingFieldType === 'composite' && option.childFieldId) {
        this.value[option.childFieldId] = value;
        value = option.childFieldId;
        this.value[DatabaseHelper.getReadableTitleFieldId(option.childFieldId)] = option.title;
      }
      this.value[field.id] = value;
      this.value[DatabaseHelper.getReadableTitleFieldId(field.id)] = option.title;
      if (field.id) {
        /*
         * we select an already existing option so if we had already selected the option of a new element
         * we mustn't create this new element because it's not necessary anymore
         */
        delete this.value[DataAccessor.toConstructForId(field)];
      }
    } else {
      // If option is undefined and that there is a nullItem it means that no choice has been made
      this.value[field.id] = field.nullItem ? '__notChosen' : null;
      this.value[DatabaseHelper.getReadableTitleFieldId(field.id)] = null;
    }
    this.afterUpdate(field.id);
  }

  public getValueByFieldId(fieldId: any, isOriginalData: boolean = false): any {
    if (isOriginalData) return getOriginalValue(this.value, fieldId);
    return this.value != null ? this.value[fieldId] : null;
  }

  public getModifiedGroups(): string[] {
    return this.modifiedGroups;
  }

  private getAnythingDisplayValue(fieldId: string | number): string {
    const fieldDef = this.definition.fields.find(f => f.id === fieldId);
    if (!fieldDef) return;
    if (['date', 'time', 'datetime'].includes(fieldDef.type)) {
      const value = this.value[fieldDef.id];
      if (value == null) return '';

      const valueDayjs = dayjs.utc(value).tz(fieldDef.type === 'date' ? 'UTC' : fieldDef.timezone);
      if (fieldDef.type === 'date') return valueDayjs.format('YYYY-MM-DD');
      if (fieldDef.type === 'datetime') return valueDayjs.format('YYYY-MM-DD HH:mm');
      if (fieldDef.type === 'time') return `${valueDayjs.format('HH:mm')}${fieldDef.suffix}`;
    } else if (fieldDef.type === 'datetimeWithTimezone') {
      const value: DatetimeWithTimezoneValue = this.value[fieldDef.id];
      if (value == null) return '';

      return dayjs.utc(value.datetimeUtc).tz(value.timezone).format('YYYY-MM-DD HH:mm (Z)');
    }
    if (['files', 'file'].includes(fieldDef.type)) {
      return !fieldDef.loadedFiles ? '' : fieldDef.loadedFiles.map(file => file.name).join(', ');
    }
    return String(DatabaseHelper.getFormatFieldPureValue(fieldDef, this.value, null, fieldDef.timezone));
  }

  public getAnythingRawValue(fieldId: string | number): FieldDataValue {
    const fieldDef = this.definition.fields.find(f => f.id === fieldId);

    if (['date', 'time', 'datetime'].includes(fieldDef.type)) {
      const value: number = this.value[fieldDef.id];
      if (value == null) return null;

      const valueDayjs = dayjs.utc(value).tz(fieldDef.type === 'date' ? 'UTC' : fieldDef.timezone);
      return valueDayjs.toISOString();
    }
    return this.value[fieldId];
  }
}
