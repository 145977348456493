<div *ngIf="chartSettings" class="chart-wrapper-container">
  <div class="component-timezone" *ngIf="config.appConfig.timezoneSelector">{{ timezoneService.title }}</div>
  <div class="chart-content" [ngSwitch]="chartSettings.type" [style.display]="showTable ? 'none' : 'block'">
    <spin-boxplot
      #graph
      *ngSwitchCase="'boxplot'"
      [componentSettings]="componentSettings"
      [noRawData]="noRawData"
      (onchartintervalchange)="chartIntervalChange($event)"
      (onselect)="onselect($event)"
      [loading]="isLoading"
      (showTable)="onTableShow()"
      [datatable]="table"
      (navigate)="onaction($event)"
      [isScheduleChart]="isScheduleChart" />

    <plotly-polar
      #graph
      *ngSwitchCase="'polar'"
      [componentSettings]="componentSettings"
      [loading]="isLoading"
      [noRawData]="noRawData"
      (onchartintervalchange)="chartIntervalChange($event)"
      (onselect)="onselect($event)"
      (showTable)="onTableShow()"
      [datatable]="table"
      (navigate)="onaction($event)"
      [isScheduleChart]="isScheduleChart" />

    <plotly-barpolar
      #graph
      *ngSwitchCase="'barpolar'"
      [componentSettings]="componentSettings"
      [loading]="isLoading"
      [noRawData]="noRawData"
      (onchartintervalchange)="chartIntervalChange($event)"
      (onselect)="onselect($event)"
      (showTable)="onTableShow()"
      [datatable]="table"
      [isScheduleChart]="isScheduleChart" />

    <plotly-multi
      #graph
      *ngSwitchCase="'multi'"
      [componentSettings]="componentSettings"
      [loading]="isLoading"
      [noRawData]="noRawData"
      (onchartintervalchange)="chartIntervalChange($event)"
      (onselect)="onselect($event)"
      (showTable)="onTableShow()"
      [datatable]="table"
      (navigate)="onaction($event)"
      [isScheduleChart]="isScheduleChart" />

    <plotly-waterfall
      #graph
      *ngSwitchCase="'waterfall'"
      [componentSettings]="componentSettings"
      [loading]="isLoading"
      [bridges]="chartSettings.bridges"
      [noRawData]="noRawData"
      (onchartintervalchange)="chartIntervalChange($event)"
      (onselect)="onselect($event)"
      (showTable)="onTableShow()"
      [datatable]="table"
      (navigate)="onaction($event)"
      [isScheduleChart]="isScheduleChart" />
  </div>

  <div *ngIf="tableSettings && showTable" class="chart-table">
    <entity-table-wrapper
      [componentSettings]="tableSettings"
      [onaction]="onaction"
      (onexport)="export($event)"
      #table />
  </div>
  <div class="chart-buttons" *ngIf="!uiService.isXSmallDisplay()">
    <field-button
      *ngFor="let button of chartSettings.buttons"
      class="chart-button"
      buttonType="secondary"
      [buttonConfig]="button"
      [values]="null"
      (buttonClick)="onaction($event)">
      {{ button.title }}
    </field-button>
  </div>
</div>
