import { ascending } from 'd3-array';

import { ScheduleChartOptions, ScheduleGraphSettings, ScheduleLayerOptions, ScheduleLike } from './schedule-types';
import { ScheduleLayerSettings } from '../helpers/config-types';
import { LayerId } from '../helpers/types';
import { Config } from '../config/config';

export const DEFAULT_STROKE_PATTERN_STROKE_WIDTH = 1;
export const DEFAULT_BAR_PADDING_LEFT = 5;
export const DEFAULT_DIAMOND_LABEL_MARGIN = 10;
export const COLLAPSIBLE_GROUP_ICON_SIZE = 24;
export const ITEM_CROSS_MARGIN = 2;

export class ScheduleTools {
  /*
   * Sort contracts sort by dateStart and by layer id
   */
  public static sortContracts(contracts: ScheduleLike[]): ScheduleLike[] {
    return contracts.sort((a, b) => {
      // sps layer is always drawn last so each polygon is in top of the other contract
      if (a.layerId === 'sps' && b.layerId !== 'sps') {
        return 1;
      }
      if (b.layerId === 'sps' && a.layerId !== 'sps') {
        return -1;
      }
      return ascending(a.dateStart, b.dateStart);
    });
  }

  /**
   * Return the layer options from a layer settings.
   */
  public static scheduleOptionsForLayerConfig(layer: ScheduleLayerSettings, layerId: LayerId): ScheduleLayerOptions {
    const allLayerFields = layer.fieldsets?.map(fieldset => fieldset.fields).reduce((a, b) => a.concat(b), []) ?? [];
    const layerOptions: ScheduleLayerOptions = {
      tooltip: layer.tooltip,
      shape: layer.shape,
      color: layer.colorParam,
      label: layer.locLabel,
      exportFields: allLayerFields,
      fill: layer.fill,
      stroke: layer.stroke,
      radius: layer.radius,
      toggleColor: layer.toggleColor,
      addBorder: layer.addBorder,
      filtering: layer.filtering,
      fixedColorBy: layer.fixedColorBy,
      title: layer.title,
      conditionedMessage: layer.conditionedMessage,
      punctualShapes: layer.punctualShapes,
      specificFilters: layer.specificFilters,
      idProperty: layer.idProperty,
      groupByProperty: layer.groupByProperty,
      fixedGroupOrder: layer.fixedGroupOrder,
      titleProperty: layer.titleProperty,
      opacity: layer.opacity ?? 0.95,
      filteredOpacity: layer.filteredOpacity ?? 0.04,
      drawingLayerId: layer.drawingLayerId ?? layerId,
      drawingLayerTitle: layer.drawingLayerTitle ?? layer.title,
      titleLink: layer.titleLink,
      groupId: layer.groupId,
      groupTitle: layer.groupTitle,
      overlay: layer.overlay,
      hideSidebarLayer: layer.hideSidebarLayer ? layer.hideSidebarLayer : false,
      sampling: layer.sampling ?? false,
      concatenateDescription: layer.concatenateDescription,
      ignoreSelectFilter: layer.ignoreSelectFilter,
      alwaysVisible: layer.alwaysVisible,
      partialLoading: layer.partialLoading,
      notHappeningContractProp: layer.notHappeningContractProp,
      highlightedContractProp: layer.highlightedContractProp,
      vesselOrderUrl: layer.vesselOrderUrl,
      rangeUrl: layer.rangeUrl,
      heavyDataUrl: layer.heavyDataUrl,
      updateWithInterval: layer.updateWithInterval,
      additionalProperties: layer.additionalProperties,
    };

    return layerOptions;
  }

  static getChartOptions(settings: ScheduleGraphSettings, isXSmallDisplay: boolean): ScheduleChartOptions {
    // Label width is a 5th of the window innerWidth, at least 240, at most 500
    const yWidth = Math.max(240, Math.min(window.innerWidth / 5, 500));
    return {
      yAxisWidth: yWidth,
      barHeight: 'auto',
      space: 2,
      drag: false,
      order: true,
      groupHeaderSize: 20,
      initLegendHeight: 0,
      dashLegendPercentage: 0.15,
      layerTitlePercentage: 0.15,
      itemsPerLegendLine: isXSmallDisplay ? 2 : 6,
      minLegendItemSize: isXSmallDisplay ? 20 : 100,
      labelFontSize: Config.SPIN_DEFAULT_FONT_SIZE,
      groupFooterSize: 5,
      xAxisHeight: 25,
      vesselFontSize: 14,
      legendFontSize: Config.SPIN_DEFAULT_FONT_SIZE,
      labelXMargin: DEFAULT_BAR_PADDING_LEFT,
      showLineSeparators: settings.showLineSeparators ? settings.showLineSeparators : true,
      layerFontSize: 14,
      nameWidthYAxis: 160,
    };
  }
}
