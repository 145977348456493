export class ChartsFormatting {
  public static formatNumber(value: number): string {
    if (value === null) {
      return null;
    }
    if (typeof value === 'string') {
      return value;
    }
    // if value < 1 we want at least two number precision
    if (value < 1 && value > -1) {
      return value.toFixed(2);
    }
    // otherwise we always display one number after the decimal point
    return value.toFixed(1);
  }

  public static roundNumber(value: number): number {
    if (value == null) {
      return value;
    }

    return parseFloat(ChartsFormatting.formatNumber(value));
  }
}
