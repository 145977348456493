<div class="graph-options">
  <!-- We used to listen to `selectedTabChange` event but it was causing infinite loop.
  -- I found the fix by going through Angular issues https://github.com/angular/components/issues/24096
  -- even though it's not a 'rapid' tab change -->
  @if (tabs && tabs.length) {
    <mat-tab-group class="chart-tabs" (selectedIndexChange)="indexChanged($event)" [selectedIndex]="selectedIndex">
      @for (tab of tabs; track $index; let i = $index) {
        <mat-tab [label]="tab.title">
          <ng-template mat-tab-label>
            <div>
              <span> {{ tab.title }}</span>
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  }
  <div class="graph-options-content">
    @if (!opts.hideSelects) {
      @if (uiService.isXSmallDisplay()) {
        <spin-filters-menu
          class="graph-selects-menu"
          [selectsgroup]="availableSelects"
          [selects]="selects"
          [display]="display"
          [multiple]="multiple"
          (onchange)="onChange($event.chartParam, $event.value)">
        </spin-filters-menu>
      } @else {
        @for (select of availableSelects; track select) {
          <!-- Display currently stores not onlu string values but also the "interval"
          -- in order to fix the bellow $any() call we would have to split the object -->
          @if (selects[select] && selects[select].values?.length) {
            <spin-filters-select
              (onchange)="onChange(select, $event)"
              [id]="getSelectId(select)"
              [multiple]="select == 'metric' && multiple"
              [message]="messages[select]"
              [value]="$any(display[select])"
              [field]="selects[select]"
              [minSelectable]="getMinSelectable(select)">
            </spin-filters-select>
          }
        }
        @if (opts.enableRangeSlider) {
          <div class="graph-checkbox-select">
            <mat-checkbox
              (change)="toggleTail($event.checked)"
              [checked]="display.showTail || !opts.enableToggleTail"
              [matTooltip]="display.aggregateTooltip"
              matTooltipPosition="above"
              [disabled]="!opts.enableToggleTail">
              Show all
            </mat-checkbox>
            <div class="caption">{{ display.aggregateDetails }}</div>
          </div>
        }
        @if (opts.enableModes) {
          <div class="graph-checkbox-select">
            <mat-checkbox
              (change)="toggleMode($event.checked)"
              [checked]="display.displayMode == 'all'"
              [matTooltip]="display.aggregateTooltip"
              matTooltipPosition="above">
              Show all
            </mat-checkbox>
            <div class="caption">{{ display.aggregateDetails }}</div>
          </div>
        }
        @if (labelCheckbox) {
          <div class="graph-checkbox-select">
            <mat-checkbox (change)="showLabels($event.checked)" [ngModel]="showLabel"> Show labels </mat-checkbox>
          </div>
        }
      }
    }

    @if (
      !opts.hideInterval &&
      intervalField &&
      (intervalField.filterType == "date" || intervalField.filterType == "datetime")
    ) {
      <spin-filters-interval
        class="graph-interval"
        [class.hidden]="(noData && !intervalHasValues()) || (intervalField && intervalField.fixedInterval)"
        #chartInterval
        [field]="intervalField"
        [enabled]="true"
        (onchange)="onChartIntervalChange($event, $event.changeEnded)"
        (onreleased)="onChartIntervalChange($event, true)">
      </spin-filters-interval>
    }

    @if (!opts.hideButtons) {
      <div class="graph-buttons">
        @if (
          !opts.hideInterval &&
          intervalField &&
          (intervalField.filterType == "doubledate" || intervalField.filterType == "intersection")
        ) {
          <spin-filters-doubledate
            class="graph-doubledate"
            [class.hidden]="intervalField && intervalField.fixedInterval"
            [field]="intervalField"
            #chartDoubleDate
            (onchange)="onChartIntervalChange($event, true)">
          </spin-filters-doubledate>
        }
        @if (showTableButton) {
          <pearl-button
            type="icon"
            icon="show_data_table"
            matTooltip="Show data table"
            matTooltipPosition="above"
            (click)="onShowTable()" />
        }
        <pearl-button
          type="icon"
          icon="download_png"
          matTooltip="Download PNG"
          matTooltipPosition="above"
          (click)="onpng($event)" />

        <pearl-button
          type="icon"
          icon="download"
          [disabled]="config.trialModeDownloadsDisabled"
          [matTooltip]="config.getXlsxDownloadTooltip()"
          matTooltipPosition="above"
          (click)="onXls($event)" />
      </div>
    }
  </div>
</div>
