import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch } from '@angular/common';

import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { FileUploadModule, FileUploader } from 'ng2-file-upload';

import { InternetStatusService } from '../helpers/internet-status.service';
import { DataLoader } from '../data-loader/data-loader';
import { EntityDefinition, EntityFieldDefinition, EntityUpdateDto, FieldsDefinition, LinkedTableQuery,
  SomeEntity } from '../helpers/types';
import { DialogManager } from './dialog-manager';
import { DisplaySize, ShortenUrlPipe } from '../helpers/pipes';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { PearlButtonComponent, PearlFormFieldComponent, PearlIconComponent } from '../shared/pearl-components';

export interface FileObject {
  id?: number;
  size?: string;
  name?: string;
  uploadDate?: string;
  s3Link: string;
  tempName?: string;
  removeMode?: boolean;
}

export interface UploadFileResponse {
  success: boolean;
  error: string;
  data?: FileObject;
}

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.html',
  styleUrls: ['file-uploader.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgIf,
    FileUploadModule,
    NgClass,
    PearlIconComponent,
    PearlButtonComponent,
    SpinTooltipDirective,
    NgFor,
    PearlFormFieldComponent,
    MatTooltipModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatIconModule,
    TextFieldModule,
    AsyncPipe,
    ShortenUrlPipe,
    DisplaySize,
  ],
})
export class FileUploaderComponent implements AfterViewInit {
  private dataLoader: DataLoader;
  private disabledSubject = new BehaviorSubject<boolean>(false);

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;

  public displayedColumns = [
    'fileName',
    'fileSize',
    'uploadDate',
    'fileAction',
  ];
  public files: MatTableDataSource<FileObject>;
  public definition: EntityDefinition = null;

  // Default maximum allowed file size in kilobytes. 2MB, aligned with backend server config
  static readonly defaultMaxFileSize = 2000;

  // the field of the parent entity pointing to a collection
  @Input()
  parentField: EntityFieldDefinition;
  @Input()
  parentEntity: SomeEntity;
  @Input()
  editMode: boolean;
  @Input()
  parentEntityDefinition: EntityDefinition | FieldsDefinition;
  // allow to upload multiple files or not
  @Input()
  multi: boolean = true;
  @Input()
  fieldTitle?: string = null;

  // Using the setter for the @Input to push new values to the BehaviorSubject
  @Input()
  set disabled(value: boolean) {
    this.disabledSubject.next(value);
  }

  @Output()
  fileUploaded = new EventEmitter<void>();

  public isUploading: boolean = false;
  private dialogManager: DialogManager;
  private cdRef: ChangeDetectorRef;
  private internetStatusService: InternetStatusService;

  public isFileActionPossible$ = new BehaviorSubject<boolean>(true);

  constructor(injector: Injector) {
    /*
     * default api url for the file uploader, actually we don't use it
     * because we want to upload with our dataloader
     */
    const URL = '';
    this.uploader = new FileUploader({ url: URL });
    this.dataLoader = injector.get(DataLoader);
    this.dialogManager = new DialogManager(injector);
    this.files = new MatTableDataSource<FileObject>();
    this.cdRef = injector.get(ChangeDetectorRef);
    this.internetStatusService = injector.get(InternetStatusService);
    this.subscribeIsFileActionPossible();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.loadFiles();
  }

  /**
   * Subscribes to the combined streams of `disabledSubject` and `internetUp$` to determine
   * if file actions are possible (delete/ edit file)
   *
   * @private
   */
  private subscribeIsFileActionPossible(): void {
    combineLatest([
      this.disabledSubject,
      this.internetStatusService.internetUp$,
    ]).pipe(
      map(([isDisabled, isOnline]) => !isDisabled && isOnline),
    ).subscribe(result => this.isFileActionPossible$.next(result));
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  /**
   * This function is called each time we want to upload one or more files.
   * It will call the backend to create an entity in the database and get the amazon s3 storage link
   * Be careful the collection between the parent entity and the files isn't save yet at this dayjs.
   */
  async uploadAllFiles(): Promise<void> {
    for (const item of this.uploader.queue) {
      const fileToUpload: File = item._file;
      // php conf prevent from uploading file over than 2MB to the server
      if (fileToUpload.size > this.maxFileSizeInBytes) {
        item.remove();
        this.isUploading = false;
        this.dialogManager
          .showMessage(
            `"${fileToUpload.name}" must be under ${this.maxFileSize} kB. Upload canceled`,
            'error',
          );
        continue;
      }
      const fileData = new FormData();
      fileData.append('file', fileToUpload, fileToUpload.name);
      // If a specific subFolder is defined in field config we set it as query parameter
      if (this.parentField.parentFilePath) {
        fileData.append('file_path', this.parentField.parentFilePath);
      }
      this.isUploading = true;
      const uploadResult = await this.dataLoader.postFile<UploadFileResponse>(
        this.dataLoader.uploadFileUrl(item.file.name),
        fileData,
      );
      item.remove();
      this.isUploading = false;
      this.cdRef.detectChanges();
      if (!uploadResult || !uploadResult.success) {
        this.dialogManager.showMessage(uploadResult ? uploadResult.error : "File couldn't be uploaded", 'error');
        continue;
      }
      const fileDetails = uploadResult.data;
      if (this.files.data.find(d => d.id === fileDetails.id)) {
        this.dialogManager.showMessage(
          `The file ${fileDetails.name} has already been uploaded`,
          'success',
        );
        continue;
      }
      const newFile: FileObject = {
        id: fileDetails.id,
        name: fileDetails.name,
        s3Link: fileDetails.s3Link,
        size: fileDetails.size,
        uploadDate: fileDetails.uploadDate,
      };

      /**
       * @TODO PB: This notion of multi is set to disappear (cf https://spinergie.atlassian.net/browse/SP-2602)
       * At first glance, it may seem to duplicate 'maxNumberOfFiles'.
       * Eventually, only 'maxNumberOfFiles' will be used, and there will only be one field type fields.
       * The biggest pain point of this multi is that we store values differently in the entity
       * (an array in the case of 'files' type and a single value for 'file' type).
       */
      if (this.multi) {
        // update display
        const newFilesData = this.files.data;
        newFilesData.push(newFile);
        this.files.data = newFilesData;

        /*
         * add file(s) to the parentEntity object (to be saved server side)
         * it's an array because we are in a multi upload case
         */
        if (!this.parentEntity[this.parentField.id]) {
          this.parentEntity[this.parentField.id] = [];
        }
        this.parentEntity[this.parentField.id].push(fileDetails.id);
      } else {
        // update display
        this.files.data = [newFile];

        /*
         * add file to the parentEntity object (to be saved server side)
         * it's a single value because we are not in a multi upload case
         */
        this.parentEntity[this.parentField.id] = fileDetails.id;
      }
      this.dialogManager.showMessage(
        `Success: File ${fileDetails.name} has been uploaded`,
        'success',
      );
      this.cdRef.detectChanges();
    }
    this.fileUploaded.emit();
  }

  async loadFiles(): Promise<void> {
    // If loadedFiles list is given, we are in standalone file uploader implementation
    if (this.parentField.loadedFiles) {
      this.files.data = this.parentField.loadedFiles;
      this.cdRef.detectChanges();
      return;
    }
    if (!this.dataLoader.getEntityDefinition) return;
    // get file entity definition
    this.definition = await this.dataLoader.getEntityDefinition(
      this.parentField.class,
      false,
    );

    // if multiple upload components, retrieve the files collection linked to the entity
    if (this.multi) {
      this.files.data = await this.getPossibleFiles();
    } else {
      // otherwise find the unique associated file from the field id
      const file = await this.getCurrentUploadedFile();
      if (file) {
        this.files.data = [file];
      }
    }

    // update displayed table data
    this.cdRef.detectChanges();
  }

  /**
   * Returns the list of all possible files (in the form { id: .., title: ...}) that can
   * be attached to this entity. By default the endpoint sends all event those already attached
   */
  private async getPossibleFiles(): Promise<FileObject[]> {
    // ensure we have a parentEntity
    if (!this.parentEntity) return;
    const linkedFiles = this.parentEntity[this.parentField.id];
    if (!linkedFiles || linkedFiles.length === 0) {
      return;
    }

    const query: LinkedTableQuery = {
      linkedEntity: this.parentField.class,
      parentEntity: (this.parentEntityDefinition as EntityDefinition).class,

      // Id of the file
      linkedCollection: this.parentField.id,
      idsSearched: linkedFiles.map(file => file.id ?? file),
      forOptions: false,
    };
    return this.dataLoader.getPossibleValues(query) as Promise<FileObject[]>;
  }

  private async getCurrentUploadedFile(): Promise<FileObject> {
    // ensure we have a parentEntity
    if (!this.parentEntity) return;
    const uploadedFileId = this.parentEntity[this.parentField.id];

    if (!uploadedFileId) {
      return;
    }
    const fileEntity: SomeEntity = await this.dataLoader.getEntity(this.parentField.class, { id: uploadedFileId });
    const file: FileObject = {
      id: fileEntity['id'] as number,
      name: fileEntity['name'],
      s3Link: fileEntity['s3Link'],
      size: fileEntity['size'],
      uploadDate: fileEntity['uploadDate'],
    };
    return file;
  }

  public removeFile(file: FileObject): void {
    // remove file from displayed table
    const existingFilesData = this.files.data;
    const index: number = existingFilesData.findIndex(d => d === file);
    existingFilesData.splice(index, 1);
    this.files.data = existingFilesData;

    // remove file from the parentEntity object (update call expect an array if multi, otherwise null if not multi)
    if (this.multi) {
      const allButTheRemovedElement = this.parentEntity[
        this.parentField.id
      ].filter(item => item.id !== file.id && item !== file.id);
      this.parentEntity[this.parentField.id] = allButTheRemovedElement;
    } else {
      this.parentEntity[this.parentField.id] = null;
    }

    // update display mode
    this.quitRemoveMode(file);
    // Notifiy that a file has been deleted
    this.fileUploaded.emit();
  }

  public displayFileSize(fileSize: number): string {
    const kiloOctet = 1024;
    let fileSizeString: string;

    if (fileSize < kiloOctet) {
      fileSizeString = `${this.roundFileSize(fileSize).toString()} B`;
    } else if (fileSize / kiloOctet < kiloOctet) {
      fileSizeString = `${
        this.roundFileSize(
          fileSize / kiloOctet,
        ).toString()
      } kB`;
    } else if (fileSize / (kiloOctet * kiloOctet) < kiloOctet) {
      fileSizeString = `${
        this.roundFileSize(
          fileSize / (kiloOctet * kiloOctet),
        ).toString()
      } MB`;
    } else {
      fileSizeString = `${
        this.roundFileSize(
          fileSize / (kiloOctet * kiloOctet * kiloOctet),
        ).toString()
      } GB`;
    }
    return fileSizeString;
  }

  private roundFileSize(size: number): number {
    return Math.round(size * 100) / 100;
  }

  /**
   * Return shorten file title. If file is in delete mode the maxSize is reduced
   * @param file
   * @returns shorten file title
   */
  public getCompactTitle(file: FileObject): string {
    const maxLength = file.removeMode ? 45 : 50;
    const shortenPipe = new ShortenUrlPipe();
    return shortenPipe.transform(file.name, maxLength);
  }

  public editFileName(file: FileObject): void {
    file.tempName = file.name;
    this.cdRef.detectChanges();
  }

  public async saveFileName(file: FileObject): Promise<void> {
    file.name = file.tempName;
    file.tempName = null;
    const entityName = this.parentField.class;
    const uploadResult = await this.dataLoader.post<
      FileObject,
      EntityUpdateDto
    >(this.dataLoader.updateUrl(entityName), file);
    this.cdRef.detectChanges();
    if (!uploadResult.success) {
      this.dialogManager.showMessage(uploadResult.error, 'error');
      return;
    }
    this.dialogManager.showMessage(
      `Success: File name  ${file.name} has been updated`,
      'success',
    );
  }

  public cancelFileName(file: FileObject): void {
    file.tempName = null;
    this.cdRef.detectChanges();
  }

  public changeName(file: FileObject, newName: string): void {
    file.tempName = newName;
  }

  public enterRemoveMode(file: FileObject): void {
    file.removeMode = true;
  }

  public quitRemoveMode(file: FileObject): void {
    file.removeMode = null;
  }

  public uploadAreaHidden(): boolean {
    return this.isStandaloneFile() || this.parentField.uploadAreaHidden;
  }

  /**
   * Returns true if a file in the html
   * should be displayed as a classic field (field box without upload area).
   * The conditions for this to be the case are that the maxNumberOfFiles is 1 and that the file is selected.
   * @returns boolean
   */
  public isStandaloneFile(): boolean {
    return this.parentField.maxNumberOfFiles === 1 && this.files.data?.length >= 1;
  }

  /**
   * Returns true if uploading a new file is possible.
   * The condition is that the field is not disabled, internet status is up
   * and that the number of uploaded files < maxNumberOfFiles
   */
  public canUploadFile(): boolean {
    return !this.isMaxNumberOfFilesReached() && this.isFileActionPossible$.value;
  }

  /**
   * return true if maxNumberOfFiles is defined and has been reached
   * @returns boolean
   */
  private isMaxNumberOfFilesReached(): boolean {
    return this.parentField.maxNumberOfFiles && (this.parentField.maxNumberOfFiles <= this.files.data?.length);
  }

  /**
   * Return the attach file button tooltip
   * If maxNumberOfLines has been reached we display a specific message
   * Otherwise we display 'Attached files' if field isn't disabled
   *
   * @returns tooltip
   */
  public getAttachedFilesTooltip(): string {
    if (!this.internetStatusService.isOnline()) {
      return 'You have to be online to attach a file';
    }
    if (this.isMaxNumberOfFilesReached()) {
      return 'You have reached the maximum number of files for this field';
    }
    return !this.disabled ? 'Attach a file' : '';
  }

  // Return a string of the allowed file extensions
  public get allowedFileExtensionsList(): string {
    return this.parentField?.allowedFileExtensions ? `.${this.parentField.allowedFileExtensions.join(', .')}` : '';
  }

  // Calc maxFileSize, maxFileSize is in kB by default
  public get maxFileSizeInBytes(): number {
    return this.maxFileSize * 1024;
  }

  // Getter for the maximum allowed file size in kilobytes
  public get maxFileSize(): number {
    // Avoid maxFileSize misconfiguration
    if (
      !this.parentField?.maxFileSize
      || this.parentField?.maxFileSize > FileUploaderComponent.defaultMaxFileSize
    ) {
      return FileUploaderComponent.defaultMaxFileSize;
    }
    return this.parentField.maxFileSize;
  }
}
