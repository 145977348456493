import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, inject, input,
  output, signal, viewChild } from '@angular/core';

import { AutocompleteFilterComponent } from '../filters/autocomplete-filter.component';
import { EntityDefinition, EntityFieldDefinition, FieldSettings, FilterApplied, LinkedTableQuery, OptionValue,
  SomeEntity } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { PearlSelectOptionValueType } from '../shared/pearl-components';

@Component({
  selector: 'multi-form-field',
  templateUrl: 'multi-form-field.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutocompleteFilterComponent],
})
export class MultiFormFieldComponent implements AfterViewInit {
  public readonly compatibleField = signal<FieldSettings>(null);
  public readonly selectedValues = signal<PearlSelectOptionValueType[]>([]);

  private readonly dataLoader = inject(DataLoader);
  private readonly cdRef = inject(ChangeDetectorRef);

  public readonly $field = viewChild(AutocompleteFilterComponent);

  public readonly small = input<boolean>(true);
  public readonly parentField = input<EntityFieldDefinition>();
  public readonly parentEntityDefinition = input<EntityDefinition>();
  public readonly entity = input<SomeEntity>();
  public readonly disabled = input<boolean>(false);

  public readonly loadingStart = output();
  public readonly loadingEnd = output();

  @Output()
  onchange = new EventEmitter<OptionValue[]>();

  ngAfterViewInit(): void {
    this.loadingStart.emit();
    void this.setPossibleValues().then(async () => {
      this.cdRef.detectChanges();
      this.$field()?.update();
      await this.setSelectedValues();
      this.loadingEnd.emit();
    });
  }

  /** Return selected options object (with title and id) */
  public get selectedOptions(): OptionValue[] {
    return this.compatibleField().values.filter(value => this.selectedValues().includes(value.title));
  }

  private async setPossibleValues(): Promise<void> {
    const query: LinkedTableQuery = {
      linkedEntity: this.parentField().class,
      parentEntity: this.parentEntityDefinition().class,

      /*
       * Id of the fields, suppose we navigate from contract to it's sources
       * then it will be sources
       */
      linkedCollection: this.parentField().id,
      forOptions: true,
    };

    const values = (await this.dataLoader.getPossibleValues(query) as OptionValue[]).map(value => (
      { id: value.id as string, title: value.title, value: value.title, propOrder: value.title }
    ));

    this.compatibleField.set({
      ...this.parentField(),
      filterType: 'entity',
      values,
    });
  }

  public optionsChanged(event: FilterApplied): void {
    this.selectedValues.set(event.values as string[]);
    this.onchange.emit(this.selectedOptions);
  }

  public async setSelectedValues(): Promise<void> {
    if (!this.compatibleField()?.values?.length) await this.setPossibleValues();

    const values = this.entity()[this.parentField().id] as string[];
    if (values?.length) {
      this.selectedValues.set(values
        .map(item => {
          item = String(item);
          item = item.trim();
          const optionValue = this.compatibleField().values.find(val => Number(val.id) === Number(item));
          return optionValue.title;
        }));
      this.$field().set(this.selectedValues());
    }
  }

  public setValues(values: PearlSelectOptionValueType[]): void {
    this.$field().set(values);
  }
}
