import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef,
  MatDialogTitle } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgForOf } from '@angular/common';
import { MatInput } from '@angular/material/input';

import { Dayjs } from 'dayjs';

import { PearlButtonComponent, PearlDatepickerComponent, PearlDatepickerToggleComponent,
  PearlFormFieldComponent } from '../../shared/pearl-components';
import { PearlDatepickerInputDirective } from '../../shared/pearl-components/components/datepicker/datepicker-input';
import { DeepReadonly, OptionValue } from '../../helpers/types';

interface CheckConfigData {
  documentTypes: DeepReadonly<OptionValue[]>;
  vessels: DeepReadonly<OptionValue[]>;
  projects: DeepReadonly<OptionValue[]>;
  initialFormData: ReportingCheckConfigParams;
}

export interface ReportingCheckConfigParams {
  from: Dayjs;
  to: Dayjs;
  documentTypeIds: number[];
  vesselIds: number[];
  projectIds: number[];
}

@Component({
  selector: 'reporting-check-config-dialog',
  templateUrl: './reporting-check-config-dialog.component.html',
  styleUrls: ['./reporting-check-config-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatOption,
    MatSelect,
    NgForOf,
    FormsModule,
    PearlFormFieldComponent,
    PearlButtonComponent,
    MatDialogActions,
    MatDialogContent,
    MatInput,
    PearlDatepickerComponent,
    PearlDatepickerInputDirective,
    PearlDatepickerToggleComponent,
  ],
})
export class ReportingCheckConfigDialogComponent {
  protected formData: ReportingCheckConfigParams;

  constructor(
    public dialogRef: MatDialogRef<ReportingCheckConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CheckConfigData,
  ) {
    this.formData = data.initialFormData;
  }

  protected onClosedClicked(): void {
    this.dialogRef.close();
  }

  protected onValidateConfigClicked(): void {
    this.dialogRef.close(this.formData);
  }
}
