import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  template: `
    <h1 mat-dialog-title>Unsaved changes</h1>
    <div mat-dialog-content class="apply-dialog">
      <p>You have unsaved changes that will be lost if you decide to continue</p>
      <p>Are you sure you want to leave this page</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button class="delete-button" [mat-dialog-close]="true">Yes, leave</button>
    </div>
  `,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class UnsavedEntityDialog {}
