import { Component, Inject, OnInit, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { DataLoader } from '../../../data-loader/data-loader';
import { UserInfo } from '../../../helpers/types';
import { PearlButtonComponent } from '../../pearl-components/components/buttons/pearl-button.component';
import { JobCategories } from '../../../dashboards/admin/admin-model';
import { DialogManager } from '../../../database/dialog-manager';
import { PearlFormFieldComponent } from '../../pearl-components';

@Component({
  selector: 'job-category-dialog',
  standalone: true,
  templateUrl: './job-category-dialog.component.html',
  styleUrl: './job-category-dialog.component.scss',
  imports: [
    PearlFormFieldComponent,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    KeyValuePipe,
    JsonPipe,
    PearlButtonComponent,
  ],
})
export class JobCategoryDialogComponent implements OnInit {
  private static readonly UPDATE_CURRENT_USER_URL = '/spindjango/user/update-current-user';
  private static readonly GET_JOB_CATEGORIES_URL = '/base/saving/job-categories';

  private readonly dataLoader = inject(DataLoader);
  private readonly dialogManager = inject(DialogManager);

  public readonly DEFAULT_JOB_CATEGORY_VALUE = 0;
  public readonly OTHER_JOB_CATEGORY_VALUE = 8;
  public readonly jobForm = new FormControl<number>(this.DEFAULT_JOB_CATEGORY_VALUE);
  public readonly loaded = signal<boolean>(false);
  public readonly jobCategories = signal<JobCategories>({});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: UserInfo },
  ) {}

  ngOnInit(): void {
    this.dataLoader.get(JobCategoryDialogComponent.GET_JOB_CATEGORIES_URL).then((response: JobCategories) => {
      this.jobCategories.set(response);
      this.loaded.set(true);
    });
  }

  save(skip: boolean = false): void {
    let job_category = this.jobForm.value;
    if (this.jobForm.value === 0 || skip) job_category = this.OTHER_JOB_CATEGORY_VALUE;

    const user = { job_category };

    this.dataLoader.post(JobCategoryDialogComponent.UPDATE_CURRENT_USER_URL, user)
      .then(() => {
        if (skip) return;
        this.dialogManager.showMessage('Success: User job category has been updated', 'success');
      }).catch(error => {
        this.dialogManager.showMessage(`Error: ${error}`, 'error');
      });
  }
}
