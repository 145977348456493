import { inject, Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EntityInformation } from '../helpers/types';
import { EntityDetailComponent } from './entity-detail';

@Injectable()
export class EntityDialogManager {
  private dialog = inject(MatDialog);
  private ngZone = inject(NgZone);

  openEntityDialog(entityInformation: EntityInformation): MatDialogRef<EntityDetailComponent> {
    const dialogRef = this.dialog.open(EntityDetailComponent, {
      width: '95%',
      height: '85%',
      disableClose: true,
      panelClass: 'entity-dialog',
      autoFocus: false,
    });

    this.ngZone.run(() => {
      const component = dialogRef.componentInstance;
      void component.setEntityInformation(entityInformation);
    });

    return dialogRef;
  }
}
