<div class="chart-query-header">
  <div class="chart-query-header-buttons">
    <pearl-button
      *ngIf="tableShown"
      type="icon"
      icon="show_data_table"
      aria-label="show chart"
      (click)="goBackToChart()" />
    <pearl-button type="icon" icon="close" aria-label="close" class="dialog-button" (click)="close()" />
  </div>
</div>
<mat-dialog-content class="chart-dialog-content">
  <chart-wrapper
    #chart
    [componentSettings]="config.component"
    [onaction]="forwardAction"
    [configFiltersPath]="getConfigFilterPath()"
    [externalData]="externalData"
    (onShowTable)="showTable()" />

  <div class="modal-buttons">
    <pearl-button-link
      *ngFor="let button of config.component.modalButtons ?? []"
      type="primary"
      [href]="button.href"
      (click)="onButtonClicked($event, button)"
      [icon]="button.icon">
      {{ button.title }}
    </pearl-button-link>
  </div>
</mat-dialog-content>
