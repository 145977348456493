import { StringHelper } from '../helpers/string-helper';
import { ChartSelectOptions } from '../helpers/types';
import { ChartAnalysis, ChartSettings } from './chart-types';

/**
 * Return the default analysis for the given chart settings, i.e.:
 * 1- the first analysis with `default: true`
 * 2- if none is defined, the first analysis
 * 3- if none is defined, the union of `settings.selects.<selectType>.value`
 */
export function getDefaultAnalysis(settings: ChartSettings): ChartAnalysis {
  return settings.analyses?.find(a => a.default)
    ?? settings.analyses?.[0]
    ?? getFallbackAnalysis(settings);
}

/**
 * Find the analysis with id `analysisId` from `settings`
 * @returns the analysis, null if the id matches none
 */
export function findAnalysis(analysisId: string, settings: ChartSettings): ChartAnalysis | null {
  return settings.analyses?.find(a => a.id === analysisId) ?? null;
}

/**
 * Replace the chart settings of `baseAnalysis` by those of `overrides` if they are defined.
 * This only overrides the chart settings, not the analysis id, title, etc.
 */
export function getAnalysisWithOverrides(
  baseAnalysis: ChartAnalysis,
  overrides: Partial<ChartAnalysis>,
): ChartAnalysis {
  const resultingAnalysis: ChartAnalysis = { ...baseAnalysis };

  for (const field of ['metrics', 'groupby', 'splitby', 'size'] as const) {
    if (overrides[field] !== undefined) {
      resultingAnalysis[field] = overrides[field] as string & string[]; // we know both sides of the `=` have the same type
    }
  }

  return resultingAnalysis;
}

/**
 * Get the analysis from `selectOptions.analysis` and replace its chart settings by those defined in `selectOptions`.
 */
export function getAnalysisWithOverridesFromChartSelectOptions(
  selectOptions: ChartSelectOptions,
  settings: ChartSettings,
): ChartAnalysis {
  const { analysis, metric, groupby, splitby, size } = selectOptions;
  const metrics = metric ? StringHelper.splitByCommas(metric) : undefined;

  const baseAnalysis = findAnalysis(analysis, settings) ?? getFallbackAnalysis(settings);

  return getAnalysisWithOverrides(baseAnalysis, { metrics, groupby, splitby, size });
}

/**
 * Build an analysis from the `settings.selects.<selectType>.value`.
 *
 * Temporary fallback until analyses are mandatory and `<selectType>.value` are deprecated.
 */
function getFallbackAnalysis(settings: ChartSettings): ChartAnalysis {
  return {
    id: 'default',
    title: 'Default analysis',
    metrics: StringHelper.splitByCommas(settings.selects.metric.value),
    groupby: settings.selects.groupby.value,
    splitby: settings.selects.splitby?.value,
    size: settings.selects.size?.value,
  };
}

/*
 * Helper functions for context requiring metrics as a comma separated string
 * TODO: delete once comma separated metric strings are removed from config
 */

type AnalysisWithMetricString = ChartAnalysis & { metric: string };
/** Adds a `metric` key to the analysis containing the values in `metrics` as a comma separated string */
function addMetricStringToAnalysis(analysis: ChartAnalysis): AnalysisWithMetricString {
  return {
    ...analysis,
    metric: analysis.metrics.join(','),
  };
}

/**
 *  @deprecated Use {@link getDefaultAnalysis}
 */
export const getDefaultAnalysisWithMetricsAsString = (settings: ChartSettings): AnalysisWithMetricString =>
  addMetricStringToAnalysis(getDefaultAnalysis(settings));

/**
 * @deprecated use {@link getAnalysisWithOverridesFromChartSelectOptions}
 */
export const getAnalysisWithOverridesFromChartSelectOptionsWithMetricAsString = (
  selectOptions: ChartSelectOptions,
  settings: ChartSettings,
): AnalysisWithMetricString =>
  addMetricStringToAnalysis(getAnalysisWithOverridesFromChartSelectOptions(selectOptions, settings));
