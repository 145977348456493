import { Injectable, Injector } from '@angular/core';

import { datadogLogs } from '@datadog/browser-logs';
import dayjs from 'dayjs';

import { DeepReadonly } from './../../helpers/types';
import { AppInfoService } from '../../app/app-info-service';
import { Config } from '../../config/config';
import { DateHelper } from '../../helpers/date-helper';
import { ReportMetadata, ReportUploadQuery } from '../models/reporting-types';
import { SelectedVesselConfig } from '../models/reporting-config-types';

@Injectable({ providedIn: 'root' })
export class DprLoggingService {
  public selectedVesselConfig: DeepReadonly<SelectedVesselConfig>;
  public appConfig: Config;
  private appInfoService: AppInfoService;

  constructor(injector: Injector) {
    this.appConfig = injector.get(Config);
    this.appInfoService = injector.get(AppInfoService);

    datadogLogs.setLoggerGlobalContext({
      user: this.appInfoService.config.spinergieUser,
    });
  }

  public setDprConfig(selectedVesselConfig: DeepReadonly<SelectedVesselConfig>): void {
    this.selectedVesselConfig = selectedVesselConfig;
  }

  /**
   * Log report commit info to datadog
   */
  public logDprCommit(reportUploadQuery: ReportUploadQuery, createdOffline: boolean): void {
    datadogLogs.logger.info('Commit offline report', {
      vessel: this.selectedVesselConfig.vessel.title,
      vesselId: this.selectedVesselConfig.vessel.id,
      reportId: reportUploadQuery.DailyReport.dailyReportId,
      reportDate: reportUploadQuery.DailyReport.date,
      lastUpload: reportUploadQuery.ReportStateInfo.lastUpload,
      modifyBy: reportUploadQuery.ReportStateInfo.modifiedBy,
      createdOffline: createdOffline,
    });
  }

  /**
   * Log report creation
   */
  public logCreateReport(currentUploadQuery: ReportUploadQuery): void {
    datadogLogs.logger.info('Create report', {
      reportDate: currentUploadQuery.DailyReport.date,
      vesselId: this.selectedVesselConfig.vessel.id,
      vessel: this.selectedVesselConfig.vessel.title,
      uploadDate: DateHelper.nowIsoString(),
      createdBy: this.appConfig.spinergieUser,
    });
  }

  /**
   * Log report delete info to datadog
   */
  public logDeleteReport(documentId: number): void {
    datadogLogs.logger.info('Delete report', {
      vessel: this.selectedVesselConfig.vessel.title,
      vesselId: this.selectedVesselConfig.vessel.id,
      reportId: documentId,
      deletedDate: DateHelper.nowIsoString(),
      deletedBy: this.appConfig.spinergieUser,
    });
  }

  /**
   * Log report loading (and information about previous report loaded at same time)
   */
  public logLoadReport(documentId: number, previousReportId?: number): void {
    datadogLogs.logger.info('Load report', {
      vessel: this.selectedVesselConfig.vessel.title,
      vesselId: this.selectedVesselConfig.vessel.id,
      reportId: documentId,
      previousReportId: previousReportId,
      loadingDate: DateHelper.nowIsoString(),
    });
  }

  /**
   * Log report sync from offline to online to datadog
   */
  public logMessage(message: string): void {
    datadogLogs.logger.info(`[${dayjs().format('YYYY-MM-DDTHH:mm')}] ${message}`);
  }

  /**
   * Log the detection of an invalid report insert in the local db
   * @param report Report to be inserted in db local
   * @param invalidFields List of invalid fields inside report
   */
  public logInvalidLocalReportInsert(report: ReportMetadata, invalidFields: string[]): void {
    datadogLogs.logger.error(
      `Error when inserting a report in local DB. Following fields are invalid: [${invalidFields.join(',')}]`,
      {
        reportDetails: report,
      },
    );
  }
}
