import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';

import { PlotData } from 'plotly.js';
import { merge } from 'lodash-es';

import { ChartExportData, ChartSeries, SeriesHeader } from './chart-types';
import { ChartingHelpers } from './charting-helpers';
import { PlotlyMulti } from './plotly-multi';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { GraphOptionsComponent } from './graph-options';
import { DescriptionButtonComponent } from '../shared/description-button';

@Component({
  selector: 'plotly-polar',
  templateUrl: 'plotly-polar.html',
  styleUrls: ['nvgraph.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DescriptionButtonComponent,
    GraphOptionsComponent,
    MatProgressSpinnerModule,
    NgClass,
    ChartTooltipComponent,
  ],
})
export class PlotlyPolar extends PlotlyMulti {
  constructor(elementRef: ElementRef) {
    super(elementRef);
    this.chartType = 'polar';
  }

  /**
   * Removes data out of the specified sector (otherwise Plotly still plots it)
   */
  private truncateToSector(data: any[], angleVariable: string): any[] {
    const polar = this.selectedGroup.polar;
    if (polar?.sector) {
      const minAngle = polar.sector[0] + 90;
      const maxAngle = polar.sector[1] + 90;
      data = data.filter(d => d[angleVariable] >= minAngle && d[angleVariable] <= maxAngle);
    }
    return data;
  }

  public override async chartSpecificPlot(head: any, multiSeries: ChartSeries[]): Promise<void> {
    // Init series (get min/max) & prepare axis layouts
    this.init(multiSeries);
    const yAxisLayout = this.getYAxisLayout();

    // Init series (get min/max) & prepare axis layouts
    this.layout = merge(this.mapChartOptsInPlotlyLayout(), yAxisLayout);
    this.layout.legend.traceorder = 'normal';

    // Prepare standard traces FIXME: should be done before calculating layout, @see `plotly-multi.ts`
    this.prepareStandardTraces();

    // Perform only one change detection for this class, after every calculations have been made
    this.cdRef.detectChanges();

    await this.plotlyPlot();
    // Bug with the custom tooltip when hovering a point - plotly tooltip is working fine
    this.addPlotlyTooltip();
  }

  /**
   * Return the prepared chart data for XLSX export.
   * It creates unique ID for each series split.
   */
  public override prepareCsvData(_: SeriesHeader, data: ChartSeries[]): ChartExportData {
    /** The mapping series split ID -> series split title */
    const seriesHeader: SeriesHeader = {};
    /** All series data per x group */
    const dataSeries = {};
    const singleSeries = data.length === 1;
    for (let seriesInd = 0; seriesInd < data.length; ++seriesInd) {
      const series = data[seriesInd];
      const splits = [];

      for (const splitId in series.header) {
        const splitTitle = this.getSeriesSplitName(series, singleSeries, series.header[splitId]);
        /*
         * We prefix each split ID with the series ID to have an unique overall split ID
         * (multiple series might have the same split ID)
         */
        seriesHeader[`${seriesInd}_${splitId}`] = splitTitle;
        splits.push(splitId);
      }

      for (const d of series.data) {
        if (!dataSeries[d[series.angleVariable]]) {
          dataSeries[d[series.angleVariable]] = {};
        }

        for (const splitId of splits) {
          dataSeries[d[series.angleVariable]] = {
            ...dataSeries[d[series.angleVariable]],
            [`${seriesInd}_${splitId}`]: d[splitId],
          };
        }
      }
    }

    const exportedData = Object.keys(dataSeries).map(groupByKey => ({
      ...dataSeries[groupByKey],
      /*
       * we are iterating over object keys here so the timestamps have been
       * converted to strings - so we have to put them back eventually
       */
      x: parseFloat(groupByKey),
    }));

    // Add label for X axis
    seriesHeader.x = 'Angle';

    return {
      header: seriesHeader,
      data: exportedData,
    };
  }

  public override buildSeriesTraces(series: ChartSeries, singleSeries: boolean): PlotData[] {
    const traces = [];
    const data = this.truncateToSector(series.data, series.angleVariable);

    const theta = data.map(d => {
      return d[series.angleVariable];
    });

    if (!(series.title in this.namesBySeries)) {
      this.namesBySeries[series.title] = [];
    }

    this.splitByColorService.resetColorsIfDuplicatedValues(series.header, this.selectedSplit);

    let i = 0;
    for (const split in series.header) {
      const splitTitle = series.header[split];
      const r = data.map(d => {
        const value = d[split];
        if (!value) {
          return series.connectGaps === 'tozero' ? 0 : value;
        }
        return value;
      });

      const color = this.getColor(series, splitTitle, i);
      const name = this.getSeriesSplitName(series, singleSeries, splitTitle);

      if (!this.namesBySeries[series.title].includes(name)) {
        this.namesBySeries[series.title].push(name);
      }

      const id = this.getTraceId(series, split);
      const visible = this.isTraceVisible(id) || 'legendonly';
      const trace: PlotData = {
        id,
        r,
        theta,
        name,
        // every variable that we add here is available on the tooltip data
        split,
        splitTitle,
        series: series.title ?? '',
        type: 'scatterpolar',
        mode: 'lines',
        line: { color, width: series.width },
        connectgaps: series.connectGaps === 'link',
        visible,
      };

      traces.push(trace);
      i++;
    }

    /*
     * Sort series splits
     * We always put the null split on top
     * If colors are specified, we sort according to the order of the colors
     */
    const orderObject = this.selectedSplit?.colors || series?.splitby?.colors;
    const splitOrder = orderObject ? orderObject.map(object => object.id) : null;
    return traces.sort((a, b) =>
      ChartingHelpers.sortTraces(
        a.splitTitle,
        b.splitTitle,
        this.splitNullTitle,
        splitOrder,
      )
    );
  }

  /**
   * Disable default plotly-multi afterPlot
   */
  protected override afterPlotActions(): void {
  }
}
