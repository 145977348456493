export const codeMirrorHintFunction = (cm, options?) => {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);
  const start = token.start;
  const end = cursor.ch;
  const line = cursor.line;
  const currentWord = token.string;
  // The attribute name "list" below is required by CodeMirror, do not change it
  const list: string[] = [];
  codeMirrorConfigItems.forEach(item => {
    const isHintMatched = item.hint.startsWith(currentWord);
    const isCurrentWordMatched = currentWord.startsWith('"')
      && item.hint.startsWith(currentWord.substring(1, currentWord.length - 1));

    if (isHintMatched || isCurrentWordMatched) {
      let hint = item.hint;
      switch (item.type) {
        case 'key':
          hint = `"${hint}": ""`;
          break;
        case 'array':
          hint = `"${hint}": []`;
          break;
        case 'dict':
          hint = `"${hint}": {}`;
          break;
        case 'raw_value':
          hint = `${hint}`;
          break;
        default: // value
          hint = `"${hint}"`;
          break;
      }
      list.push(hint);
    }
  });

  // + 1 to grab the ending double quote and not have 3 double quotes at the end of the line after autocompleting
  return { list, from: { line, ch: start }, to: { line, ch: end + 1 } };
};

const codeMirrorConfigItems: { hint: string; type: 'key' | 'value' | 'raw_value' | 'dict' | 'array' }[] = [
  // Field config.
  { hint: 'calc', type: 'key' },
  { hint: 'prefill_behaviour', type: 'dict' },
  { hint: 'behaviour', type: 'key' },
  { hint: 'upload_area_hidden', type: 'key' },
  { hint: 'compact_file_uploader', type: 'key' },
  { hint: 'max_number_of_files', type: 'key' },
  { hint: 'default', type: 'key' },
  { hint: 'timezone_from_poi', type: 'key' },
  { hint: 'req', type: 'key' },
  { hint: 'description', type: 'key' },
  { hint: 'hint', type: 'key' },
  { hint: 'no_time_coherency', type: 'key' },
  { hint: 'order', type: 'key' },
  { hint: 'items', type: 'array' },
  { hint: 'title', type: 'key' },
  { hint: 'hidden', type: 'key' },
  { hint: 'null_item', type: 'key' },
  { hint: 'aois', type: 'array' },
  { hint: 'vessel_types', type: 'array' },
  { hint: 'poi_types', type: 'array' },
  { hint: 'hide_in_excel', type: 'key' },
  { hint: 'standalone_col', type: 'key' },
  { hint: 'visibility', type: 'key' },
  { hint: 'conditional_req', type: 'key' },
  { hint: 'conditional_editable', type: 'key' },
  { hint: 'items_filter', type: 'key' },
  { hint: 'coherency_checks', type: 'key' },
  { hint: 'syncable', type: 'dict' },
  { hint: 'endpoint', type: 'key' },
  { hint: 'resp_key', type: 'key' },
  { hint: 'parameters', type: 'array' },
  { hint: 'auto_update_disabled', type: 'key' },
  { hint: 'metric', type: 'key' },
  { hint: 'metric_title', type: 'key' },
  { hint: 'colorable', type: 'key' },
  { hint: 'groupable', type: 'key' },
  { hint: 'sidebar_filter', type: 'key' },
  { hint: 'external_transfer_tank', type: 'key' },
  { hint: 'loaded_quantity', type: 'key' },
  { hint: 'unloaded_quantity', type: 'key' },
  { hint: 'starting_tank', type: 'key' },
  { hint: 'ending_tank', type: 'key' },
  { hint: 'transfer_quantity', type: 'key' },
  { hint: 'line_pos', type: 'key' },
  { hint: 'line', type: 'key' },
  { hint: 'scale', type: 'key' },
  { hint: 'editable', type: 'key' },
  { hint: 'previous_field_code', type: 'key' },
  { hint: 'report_highlight', type: 'dict' },
  { hint: 'input_unit', type: 'key' },
  { hint: 'hide_number_buttons', type: 'key' },
  { hint: 'fields', type: 'array' },
  { hint: 'activity_types', type: 'array' },
  { hint: 'tab_code', type: 'key' },
  { hint: 'operation', type: 'key' },
  { hint: 'code', type: 'key' },
  { hint: 'filter', type: 'key' },
  { hint: 'style', type: 'value' },
  { hint: 'format', type: 'key' },
  { hint: 'true', type: 'raw_value' },
  { hint: 'false', type: 'raw_value' },
  { hint: 'initialFromPrevious', type: 'value' },
  { hint: 'equalFromPrevious', type: 'value' },
  { hint: '@startTime', type: 'value' },
  { hint: '@endTime', type: 'value' },
  { hint: '@duration', type: 'value' },
  { hint: '@previousReportDatetimeStart', type: 'value' },
  { hint: '@reportDatetimeStart', type: 'value' },
  { hint: 'previous_report_type_codes', type: 'array' },
  { hint: 'closest', type: 'value' },
  { hint: 'closestFrom', type: 'value' },
  { hint: 'closestTo', type: 'value' },
  { hint: 'aggregated', type: 'value' },
  { hint: 'ddm', type: 'key' },
  { hint: 'dms', type: 'key' },
  { hint: 'searchBar', type: 'key' },
  { hint: 'dropdown', type: 'key' },
  // Voyage & phase fields rule parameters
  { hint: 'phase_type_code_to_field_codes', type: 'dict' },
  { hint: 'portCall', type: 'key' },
  { hint: 'seaPassage', type: 'key' },
  { hint: 'aggregation', type: 'key' },
  { hint: 'sum', type: 'value' },
  { hint: 'avg', type: 'value' },
  { hint: 'min', type: 'value' },
  { hint: 'max', type: 'value' },
  { hint: 'count', type: 'value' },
  { hint: 'only', type: 'value' },
  { hint: 'list_of_unique', type: 'value' },
  { hint: 'phase_filtering', type: 'key' },
  { hint: 'earliest', type: 'value' },
  { hint: 'latest', type: 'value' },
  { hint: 'field_code', type: 'key' },
  { hint: 'left_field_code', type: 'key' },
  { hint: 'right_field_code', type: 'key' },
  { hint: 'right_value', type: 'key' },
  { hint: 'operation', type: 'key' },
  { hint: 'sub', type: 'value' },
  { hint: 'div', type: 'value' },
  { hint: 'mul', type: 'value' },
  { hint: 'tab_codes_to_field_codes', type: 'dict' },
  { hint: 'report_filtering', type: 'key' },
  { hint: 'field_codes_to_allowed_column_values', type: 'dict' },
  { hint: 'field_codes', type: 'array' },
];

/*
 * When changes are made don't forget to update CustomField shortcutsDescription and the notion doc
 * https://www.notion.so/spinergie/Config-Shortcuts-d986333c57af4c00b158dfd55d115d59?pvs=4
 * The cursor is set in order to be placed where the PJMs are the most likely to make changes
 */
export const codeMirrorExtraKeys = {
  'Tab': 'autocomplete',
  'Ctrl-F': 'findPersistent',
  'Alt-I': function(cm) {
    cm.replaceSelection(
      '"items": [\n    {\n      "id": "id_1",\n      "title": "item1"\n    },\n    {\n      "id": "id_2",\n      "title": "item2"\n    }\n  ]',
    );
    cm.setCursor({ line: cm.getCursor().line - 7, ch: cm.getCursor().ch + 14 });
  },
  'Alt-P': function(cm) {
    cm.replaceSelection('"prefill_behaviour": {\n    "behaviour": "initialFromPrevious"\n  }');
    cm.setCursor({ line: cm.getCursor().line - 1, ch: cm.getCursor().ch + 34 });
  },
  'Alt-L': function(cm) {
    cm.replaceSelection('"line": 1,\n  "scale": 0.33,\n  "line_pos": 1');
    cm.setCursor({ line: cm.getCursor().line - 2, ch: cm.getCursor().ch - 4 });
  },
  'Alt-C': function(cm) {
    cm.replaceSelection('"calc": "{{code1}} + {{code2}}"');
    cm.setCursor({ line: cm.getCursor().line, ch: cm.getCursor().ch - 14 });
  },
  'Alt-D': function(cm) {
    cm.replaceSelection('"default": "0"');
    cm.setCursor({ line: cm.getCursor().line, ch: cm.getCursor().ch - 1 });
  },
  'Alt-R': function(cm) {
    cm.replaceSelection('"req": true');
  },
  'Alt-H': function(cm) {
    cm.replaceSelection(
      '"report_highlight": {\n    "tab_code": "activitytab",\n    "activity_types": [\n      "acttypecode"\n    ],\n    "fields": [\n      {\n        "code": "fieldcode"\n      }\n    ],\n    "operation": "sum"\n  }',
    );
    cm.setCursor({ line: cm.getCursor().line - 10, ch: cm.getCursor().ch + 25 });
  },
  'Alt-O': function(cm) {
    cm.replaceSelection('"order": 1');
  },
  'Alt-S': function(cm) {
    cm.replaceSelection(
      '"syncable": {\n    "endpoint": "aggregated",\n    "resp_key": "poiId",\n    "parameters": ["@startTime", "@endTime"],\n    "auto_update_disabled": true\n  }',
    );
    cm.setCursor({ line: cm.getCursor().line - 4, ch: cm.getCursor().ch + 24 });
  },
  'Alt-N': function(cm) {
    cm.replaceSelection('"no_time_coherency": true');
  },
  'Alt-V': function(cm) {
    cm.replaceSelection('"visibility": "{{masterfield}} == 1"');
    cm.setCursor({ line: cm.getCursor().line, ch: cm.getCursor().ch - 7 });
  },
  'Alt-M': function(cm) {
    cm.replaceSelection('"metric": "avg"');
    cm.setCursor({ line: cm.getCursor().line, ch: cm.getCursor().ch - 1 });
  },
  'Alt-W': function(cm) {
    cm.replaceSelection('"colorable": true,\n  "groupable": true,\n  "sidebar_filter": true');
  },
};
