import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, NgZone, OnInit, Output,
  QueryList, ViewChild, ViewChildren, input } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { Params } from '@angular/router';

import { orderBy } from 'lodash-es';

import { ActionEvent, AfterSave, Button, CollectionType, CompleteExport, EntityDefinition, EntityFieldDefinition,
  EntityInformation, ExportConfig, LinkBy, LinkEntitiesQuery, LinkType, LinkedTableQuery, OptionValue, SomeEntity,
  UpdateEventType } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { EntityTableComponent } from './entity-table';
import { DialogManager } from './dialog-manager';
import { DatabaseHelper } from './database-helper';
import { BrowserHelper } from '../helpers/browser-helper';
import { MultiFormFieldComponent } from './multi-form-field';
import { EntityDataAccessor } from './entity-data-accessor';
import { SearchBarComponent } from '../shared/search-bar';
import { PearlButtonComponent } from '../shared/pearl-components';
import { SelectorHelper } from '../selector/selector.helper';
import { UpperFirstLetterPipe } from '../helpers/pipes';

@Component({
  selector: 'entity-linked-collection',
  templateUrl: './entity-linked-collection.html',
  styleUrls: ['entity-linked-collection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PearlButtonComponent,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MultiFormFieldComponent,
    SearchBarComponent,
    EntityTableComponent,
    NgClass,
    UpperFirstLetterPipe,
  ],
})
export class EntityLinkedCollectionComponent implements OnInit {
  private dataLoader;
  private loading: number = 0;
  private browserHelper: BrowserHelper;
  private readonly cdRef: ChangeDetectorRef;
  private readonly dialogManager: DialogManager;
  public message: string;
  public needAddConfirm: boolean = false;
  public confirmMessage: string;
  public linkableValuesNotLinked: OptionValue[];
  public linkableValues: OptionValue[] = [];
  private selectedItem: OptionValue;
  public linkedEntities: SomeEntity[] = [];
  public definition: EntityDefinition = null;
  private zone: NgZone;
  public linkBy: LinkBy;

  private _editMode: boolean;

  @ViewChildren('matAutoComplete')
  $autocompleteFields: QueryList<MatAutocomplete>;
  @ViewChildren('collectionMulti')
  $collectionMultis: QueryList<MultiFormFieldComponent>;
  @ViewChild(EntityTableComponent)
  public table: EntityTableComponent;

  public readonly small = input<boolean>(false);

  // the field of the parent entity pointing to a collection
  @Input()
  parentField: EntityFieldDefinition;
  @Input()
  parentEntity: SomeEntity;
  @Input()
  parentEntityDefinition: EntityDefinition;
  @Input()
  parentTitle: string;
  @Input()
  parentFullyLoaded: boolean;
  @Input()
  afterCloseAction: () => void;
  @Input()
  alwaysNotifyParent: boolean;

  // anytime a change is performed in one of the attached elements the parent can be notified
  @Output()
  childElementChanges = new EventEmitter<AfterSave>();
  @Output()
  valuesChange = new EventEmitter<any>();

  /**
   * Notifies the parent that this component would like to open entity details
   */
  @Output()
  openEntityRequest = new EventEmitter<EntityInformation>();

  /**
   * Subscribes to the requests of entity-table and bubbles them up to entity-detail
   */
  public bubbleOpenEntityRequest(info: EntityInformation) {
    this.openEntityRequest.emit(info);
  }

  @Input()
  set editMode(val: boolean) {
    /*
     * if the user has the rights on the linked collection and in the same time
     * he does not have rights on the parent collection we show always the edit buttons.
     */
    if (!val && this.definition && this.hasWriteRightOnCollection()) {
      val = true;
    }
    this._editMode = val;

    this.cdRef.detectChanges();
    if (this.table) {
      this.table.update();
    }
  }
  get editMode() {
    return this._editMode;
  }

  @Output()
  onexport = new EventEmitter<CompleteExport>();

  public buttonsUnlink: Button[] = [];

  /**
   * Table is editable only if we are in edit mode (the user has switched to edit mode)
   * or the user does not have rights to go to edit mode on the parent entity, but has rights
   * on the children entity
   */
  get isTableEditable() {
    return this.editMode || this.hasWriteRightOnCollection();
  }

  constructor(private injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
    this.dataLoader = injector.get(DataLoader);
    this.dialogManager = injector.get(DialogManager);
    this.browserHelper = injector.get(BrowserHelper);
    this.zone = injector.get(NgZone);
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() =>
      document.addEventListener('keydown', ev => {
        DatabaseHelper.handleKeyEventForPanel(this.$autocompleteFields, ev);
      })
    );
  }

  public setTableDefinition() {
    if (!this.definitionLoaded) {
      return;
    }

    if (this.parentField.removeLink) {
      this.buttonsUnlink = [
        {
          type: 'removeLink',
          icon: 'edit_off',
          title: 'Remove relation',
          params: { id: ':id' },
        },
      ];
    }

    this.table.setDefinition(this.definition);

    this.linkBy = this.getLinkedBy();
  }

  public passDataToTable(): void {
    /*
     * If parentField has a prefill in this definition
     * We iterate trough our entities and set mapped values if we don't have
     * value in the entity
     */
    if (this.parentField.prefill) {
      for (const entity of this.linkedEntities) {
        EntityDataAccessor.fillEntityWithParentPrefill(this.parentField, this.parentEntity, entity);
      }
    }

    if (
      this.parentField.collectionOrders
      && this.parentField.collectionOrderFields
      && this.table
    ) {
      this.table.setData(
        DatabaseHelper.sortBy(
          this.linkedEntities,
          this.parentField.collectionOrderFields,
          this.parentField.collectionOrders,
          this.definition,
        ),
      );
    } else {
      this.table.setData(
        DatabaseHelper.sortByDefinition(
          this.linkedEntities,
          this.definition,
        ),
      );
    }
  }

  public onaction(actionEvent: ActionEvent) {
    // The table sends an event about the user clicking on the New button and requesting new item creation
    const { action, data } = actionEvent;
    const { type } = action;
    if (type === 'addModal') {
      this.createNewForRelation('OneToMany', data);
    }

    if (type === 'openModal' || type === 'editModal') {
      if (!data['id']) {
        this.createNewForRelation('ManyToMany', data);
      }

      const entity = { id: data['id'] };
      const entityInformation: EntityInformation = {
        entityName: action.entityName,
        entity: entity,
        editMode: type === 'editModal',
        idField: 'id',
        closeAfterSave: true,
        afterCloseAction: this.afterCloseAction,
        afterSaveAction: this.afterEntitySave,
        creation: false,
        layerId: action.layer,
        idToReload: actionEvent.idOfOriginalItem,
        prefill: data,
      };

      this.openEntityRequest.emit(entityInformation);
    }

    /*
     * The entity table sends an action to prevent that an entity was save that wasn't yet created,
     * it can be an inline add so in which cases entity-linke-collection has to emit `afeterEntitySave`
     * because `entity-table` won't do that.
     */
    if (type === 'afterCreation') {
      const afterSaveData: AfterSave = {
        entityName: this.definition.class,
        entity: data,
        eventType: UpdateEventType.New,
        layerId: null,
        idToReload: data['id'],
        modifiedEntityId: data['id'],
      };

      if (
        !this.parentEntity[this.toConstructField]
        || !(this.parentEntity[this.toConstructField] as SomeEntity[]).find(
          item => item === data,
        )
      ) {
        // the updated element isn't in our element to create so it's a new element to create
        this.afterEntitySave(afterSaveData);
      }
    }

    if (type === 'removeLink') {
      this.removeLink(data);
    }
  }

  get searchExistingAllowed() {
    return this.parentField && this.parentField.addExisting;
  }

  get collectionType(): CollectionType {
    // by default the collectiton type is one to many
    return this.parentField.collectionType
      ? this.parentField.collectionType
      : 'OneToMany';
  }

  get isManyToMany(): boolean {
    return this.collectionType === 'ManyToMany';
  }

  get isOneToMany(): boolean {
    return this.collectionType === 'OneToMany';
  }

  get isEditableManyToMany(): boolean {
    return this.editMode && this.isManyToMany;
  }

  get isEditableOneToMany(): boolean {
    return this.editMode && this.isOneToMany;
  }

  get addNewEnabled(): boolean {
    return this.editMode && this.parentField.addNew;
  }

  get addExistingEnabled(): boolean {
    return this.parentField && this.searchExistingAllowed;
  }

  public get toConstructField(): string {
    return '__to_construct_for_' + this.parentField.id;
  }

  public removeLink(entity: SomeEntity) {
    const id = entity[this.definition.idField];

    /*
     * if we remove an element from an already existing list of elements
     * eg a CVR that already has some contacts and we remove one
     */
    if (this.parentField.id in this.parentEntity) {
      const allButTheRemovedElement = (this.parentEntity[
        this.parentField.id
      ] as number[]).filter(item => item != id);

      this.parentEntity[this.parentField.id] = allButTheRemovedElement;
    }
    /*
     * if we remove an element from a collection that is being constructed
     * eg. there have been no items in contacts of CVR and we are adding new an removing it right after
     */
    if (this.toConstructField in this.parentEntity) {
      const allButFilteredOutEntity = (this.parentEntity[
        this.toConstructField
      ] as SomeEntity[]).filter(item => item !== entity);
      this.parentEntity[this.toConstructField] = allButFilteredOutEntity;
    }

    this.getValues();
  }

  get parentId() {
    return this.parentEntity[this.parentEntityDefinition.idField];
  }

  public async parentEntityFullyLoaded() {
    this.parentFullyLoaded = true;
    await this.loadDefinitionAndData();
  }

  public loadDefinitionAndData = async () => {
    // unless the parent entity is fully loaded, it does not make sense to load the definition
    if (!this.parentFullyLoaded) {
      return;
    }

    // show loading indicator
    this.loading++;
    this.cdRef.detectChanges();

    /*
     * If parentField is a multi, we don't need to get definition and values.
     * Definition is useless in this case and values will be load directly by multi field
     */
    if (this.parentField && this.parentField.formType !== 'multi') {
      // load definition and all values (currently linked and all linkable values)
      this.definition = await this.dataLoader.getEntityDefinition(
        this.parentField.class,
        false,
        (message: string) => this.handleGetEntityDefinitionError(message),
      );
    }

    // detect changes to make the table visibile
    this.cdRef.detectChanges();

    // load all possible values
    await this.getValues();

    // hide loading indicator
    this.loading--;

    if (this.table && (this.parentField.defaultAdd || this.parentField.style === 'integrated')) {
      this.table.defaultAdd();
    }
    this.cdRef.detectChanges();
  };

  /**
   * If the error message is broadly about user rights (.includes('rights')), display a warning and gracefully stop
   * loading.
   */
  private handleGetEntityDefinitionError(errorMessage: string): void {
    if (!errorMessage.includes('rights')) {
      return;
    }

    this.dialogManager.showMessage(errorMessage, 'warn');
    this.loading--;
    this.cdRef.detectChanges();
  }

  /**
   * Returnsthe list of all  posible entities (in the form { id: .., title: ...}) that can
   * be attached to this entity. By default the endpoint sends all event those already attached
   */
  private async getPossibleValues() {
    // if the addExisting button and search box is hidden, no need to get the existing values
    if (!this.parentField.addExisting) {
      return;
    }

    this.loading++;
    const query: LinkedTableQuery = {
      linkedEntity: this.parentField.class,
      parentEntity: this.parentEntityDefinition.class,

      /*
       * Id of the fields, suppose we navigate from contract to it's sources
       * then it will be sources
       */
      linkedCollection: this.parentField.id,
      forOptions: true,
    };

    const values = (await this.dataLoader.getPossibleValues(query)) as OptionValue[];
    if (this.parentField.comboBoxOrder) {
      this.linkableValues = orderBy(values, [this.parentField.orderField], [this.parentField.comboBoxOrder]);
    } else {
      this.linkableValues = values;
    }
    this.loading--;
  }

  optionSelected(option: OptionValue) {
    this.selectedItem = option;
  }

  getLinkedBy(): LinkBy {
    if (
      !this.parentEntity
      || !this.parentField
      || !this.parentEntityDefinition
    ) {
      return null;
    }
    if (this.isManyToMany) {
      const linkQuery: LinkEntitiesQuery = {
        parentEntity: this.parentEntityDefinition.class,
        linkedEntity: this.parentField.class,
        parentEntityId: this.parentId,
        /*
         * linked Entity ID is null an will be filled when the entity is created
         * inside entity-details component
         */
        linkedEntityId: null,
        linkedCollection: this.parentField.id,
      };
      return {
        linkType: 'ManyToMany',
        linkQuery: linkQuery,
        parentWillSave: false,
      };
    }

    return {
      linkType: 'OneToOne',
      fieldName: this.parentField.mappedBy,
      id: this.parentId,
      /*
       * if the parent is an existing element, we can directly call the backend and save the relations as changes are made
       * if the parent is a new element (does not have ID), it will perform the save once or childern on the backend
       */
      parentWillSave: this.parentId ? false : true,
    };
  }

  public createNewForRelation(linkType: LinkType, prefill?: Params) {
    // entity id is null, because it will be filled by entity details after the save
    const linkQuery: LinkEntitiesQuery = {
      parentEntity: this.parentEntityDefinition.class,
      linkedEntity: this.parentField.class,
      parentEntityId: this.parentId,
      /*
       * linked Entity ID is null an will be filled when the entity is created
       * inside entity-details component
       */
      linkedEntityId: null,
      linkedCollection: this.parentField.id,
    };
    const entityInformation: EntityInformation = {
      entityName: this.definition.class,
      editMode: true,
      linkBy: {
        linkType: linkType,
        linkQuery: linkQuery,
        fieldName: this.parentField.mappedBy,
        id: this.parentId,
        /*
         * if the parent is an existing element, we can directly call the backend and save the relations as changes are made
         * if the parent is a new element (does not have ID), it will perform the save once or childern on the backend
         */
        parentWillSave: this.parentId ? false : true,
      },
      entity: null,
      idField: this.definition.idField,
      closeAfterSave: true,
      afterCloseAction: () => this.cdRef.detectChanges(),
      afterSaveAction: this.afterEntitySave,
      creation: true,
      layerId: null,
      prefill,
    };
    this.openEntityRequest.emit(entityInformation);
  }

  /**
   * Default after save handler passed to modals which are creating new relation. By default we just push the
   * new element created inside the element to the values to be created after the WP creation
   */
  afterEntitySave = async (afterSaveData: AfterSave): Promise<void> => {
    if (!this.parentEntity[this.toConstructField]) {
      this.parentEntity[this.toConstructField] = [];
    }

    const isNewEntity = afterSaveData.eventType === UpdateEventType.New
      || afterSaveData.eventType === UpdateEventType.InlineNew;

    // If it is a new element, it may need to be added to the parent
    if (isNewEntity) {
      const newEntity = afterSaveData.entity;

      /*
       * The new entity should be added to the parent if:
       * - the relation is a many to many: because the newly created entity is automatically linked to the current one
       *   right after it has been successfully created (see EntityDetail.saveEntity)
       * or
       * - the new entity is actually linked to the current parent: this is not always the case, for instance when
       *   duplicating the linked entity and changing the parent field in the new duplicated entity
       */
      const shouldAddToParent = this.isManyToMany || newEntity[this.parentField.mappedBy] === this.parentId;
      if (shouldAddToParent) {
        /*
         * If the new element has already been saved (based on idToReload),
         * add it to the list of already saved entities.
         * Otherwise add it to the list of entities to be created.
         */
        const isAlreadySaved = afterSaveData.idToReload != null;
        if (isAlreadySaved) {
          this.parentEntity[this.parentField.id].push(afterSaveData.idToReload);
        } else {
          (this.parentEntity[this.toConstructField] as SomeEntity[]).push(newEntity);
        }
      }
    }

    // We notify the parent so he can save itself, saving the linked entity at the same time
    this.childElementChanges.emit(afterSaveData);

    await this.getValues();
  };

  public addNewOneToMany() {
    // TODO handle the add new

    /*
     * adding a one-to-many link it's like adding OneToOne link from the other side
     * eg. the OneToMany relationship from Workpacakge to WpActivities is mapped by the workpackage field
     * on each wp activity
     */
    const entityInformation: EntityInformation = {
      entityName: this.definition.class,
      editMode: true,
      linkBy: {
        linkType: 'OneToOne',
        fieldName: this.parentField.mappedBy,
        id: this.parentId,
        /*
         * if the parent is an existing element, we can directly call the backend and save the relations as changes are made
         * if the parent is a new element (does not have ID), it will perform the save once or childern on the backend
         */
        parentWillSave: this.parentId ? false : true,
      },
      entity: null,
      idField: this.definition.idField,
      closeAfterSave: true,
      afterCloseAction: () => this.cdRef.detectChanges(),
      afterSaveAction: this.afterEntitySave,
      creation: true,
      layerId: null,
    };
    this.openEntityRequest.emit(entityInformation);
  }

  public addSelected() {
    if (!this.selectedItem) {
      this.dialogManager.showMessage('Please choose one...', 'error');
      return;
    }

    (this.parentEntity[this.parentField.id] as number[]).push(this.selectedItem.id as number);
    this.getValues();
    this.selectedItem = null;
  }

  public addExistingOneToMany() {
    if (!this.selectedItem) {
      this.dialogManager.showMessage('Please choose one...', 'error');
      this.needAddConfirm = false;
      this.selectedItem = null;
      return;
    }

    (this.parentEntity[this.parentField.id] as number[]).push(this.selectedItem.id as number);
    this.getValues();

    this.needAddConfirm = false;
    this.selectedItem = null;
  }

  public async prepareAddExistingOneToMany() {
    if (!this.selectedItem) {
      this.dialogManager.showMessage('Please choose one...', 'error');
      return;
    }
    const selectEntity = this.linkableValues.find(
      item => item[this.definition.idField] == this.selectedItem.id,
    );
    const otherId = selectEntity[this.parentField.mappedBy];
    if (otherId) {
      this.needAddConfirm = true;
      const otherParentEntityTitle = await this.dataLoader.getEntityTitle(
        this.parentEntityDefinition.class,
        otherId,
      );
      this.confirmMessage =
        `The  ${this.parentField.class.split(':')[1]} ${this.selectedItem.title} is already linked to the ${
          this.parentEntityDefinition.class.split(':')[1]
        }`
        + ` ${otherParentEntityTitle}, do you want to change it to ${this.parentTitle}?`;
      this.cdRef.detectChanges();
    } else {
      this.addExistingOneToMany();
    }
  }

  cancelAddExistingOneToMany() {
    this.needAddConfirm = false;
    this.selectedItem = null;
  }

  public get showLoading() {
    return this.loading > 0;
  }

  private getValues = async () => {
    this.loading++;
    await this.getPossibleValues();

    // handle the case of a new entity
    if (!this.parentEntity[this.parentField.id]) {
      this.parentEntity[this.parentField.id] = [];
    }

    // list of IDs of entities connected to the parent entity
    const linkedEntities = this.parentEntity[this.parentField.id] as number[];

    const query: LinkedTableQuery = {
      linkedEntity: this.parentField.class,
      parentEntity: this.parentEntityDefinition.class,
      linkedCollection: this.parentField.id,
      forOptions: false,
      idsSearched: linkedEntities,
    };

    // only if there are some linked entities, it makes sense to load the values
    if (linkedEntities.length > 0) {
      this.linkedEntities = await this.dataLoader.getPossibleValues(query);
    } // otherwise the list of the currently linked entities is empty
    else {
      this.linkedEntities = [];
    }

    // add the entities that are in creation to the list
    if (this.parentEntity[this.toConstructField]) {
      const entitiesToConstruct = this.parentEntity[this.toConstructField] as SomeEntity[];
      this.linkedEntities.push(...entitiesToConstruct);
    }

    this.filterAlreadyLinkedEntities(linkedEntities);

    /*
     * entity-linked-collection can either have a form of table
     * or it might be just a multi with list of tags
     */
    if (this.table) {
      this.setTableDefinition();
    }

    this.$collectionMultis.forEach(multi => multi.setSelectedValues());
    this.loading--;
    this.cdRef.detectChanges();

    // passing data to the table must be the last step after the UI has been up to date
    if (this.table) {
      this.passDataToTable();
    }
  };

  /**
   * Filter the list of available entities that can be potentially added to
   * the parent entity. If there are no currently linked entities, all that are linkable can be linked
   */
  filterAlreadyLinkedEntities(linkedIds: number[]): void {
    // get list of already linked IDs and filter those that are linkable (not already linked)
    this.linkableValuesNotLinked = this.linkableValues.filter(
      e => !linkedIds.some(linked => linked == e.id),
    );
  }

  public get definitionLoaded(): boolean {
    return this.definition != null;
  }

  onExport(data: SomeEntity[]) {
    const exportConfig: ExportConfig = {
      definition: this.definition,
      layerId: null,
    };

    const completeExport: CompleteExport = {
      config: exportConfig,
      exportData: {
        data: data,
        trackingInfo: { exportSource: 'entity-linked-collection', componentTitle: this.definition.titleString },
      },
    };

    const exportData = this.browserHelper.prepareExport(completeExport);
    this.browserHelper.exportXls(exportData, this.parentTitle);
  }

  public getOpenSelectorTooltip(field: EntityFieldDefinition): string {
    return SelectorHelper.getSelectorButtonLabel(field.selector);
  }

  public advancedFiltersClick(field: EntityFieldDefinition) {
    EntityTableComponent.openSelector(
      this.injector,
      field,
      this.$collectionMultis,
      this.dialogManager,
      this.cdRef,
      this.linkableValues,
    );
  }

  public setCollection(event: any[]) {
    const values = event.map(v => v.id);
    this.parentEntity[this.parentField.id] = values;
    if (this.alwaysNotifyParent) {
      this.valuesChange.emit(values);
    }
  }

  public leaveEditMode() {
    this.editMode = false;
    if (this.table) {
      this.table.tableLeaveEditMode();
    }
  }

  public hasWriteRightOnCollection() {
    return this.definition && DatabaseHelper.hasEditRight(this.definition);
  }
}
