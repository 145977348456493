<div class="entity-table-outer-container">
  <div class="table-tabs" *ngIf="tabs?.length > 1">
    <mat-tab-group
      class="graph-options-tabs"
      *ngIf="tabs && tabs.length"
      (selectedTabChange)="tabChanged($event)"
      [(selectedIndex)]="selectedTabIndex">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <mat-tab [label]="tab.title">
          <ng-template mat-tab-label>
            <div>
              <span>{{ tab.title }}</span>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
  <div class="table-header {{ headerClass }}" *ngIf="!hideHeader && !formTable">
    <mat-paginator
      #paginator
      [pageSize]="50"
      (click)="scrollTop()"
      [pageSizeOptions]="[10, 20, 50, 100, 200]"
      [showFirstLastButtons]="true" />
    <div class="table-header-actions">
      <pearl-form-field *ngIf="!hideSearchbar" label="Search" [small]="true" iconPrefix="search">
        <input matInput (keyup)="applyFilter($any($event.target).value)" class="search-input" autocomplete="off" />
      </pearl-form-field>
      <ng-container *ngIf="headerButtonList">
        <pearl-button
          *ngFor="let button of headerButtonList"
          [spinTooltip]="button.title"
          type="icon"
          [icon]="button.icon ?? 'information'"
          (click)="onclick($event, button, null)" />
      </ng-container>
      <div class="table-modes" *ngIf="tableHasModes()">
        <mat-button-toggle-group
          class="metric-mode-toggle"
          [ngModel]="selectedTableMode"
          (change)="onTableModeChange($event.value)">
          <mat-button-toggle *ngFor="let mode of tableModes" class="metric-mode" [value]="mode.value">
            {{ mode.title }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-checkbox
        *ngIf="entityTableConfig.canChooseColumns"
        [(ngModel)]="showDescriptionColumn"
        (change)="onCheckBoxShowDescriptionColumnChange()"
        [spinTooltip]="descriptionField.tooltip">
        {{ descriptionField.checkBoxTitle }}
      </mat-checkbox>
      <div class="validity-date-checkbox" *ngIf="isValidityDateEntity()">
        <mat-checkbox [checked]="validityDateFilter" (change)="setDateValidityVisibility($event)">
          Hide invalid entities
        </mat-checkbox>
      </div>
      <pearl-button
        type="icon"
        icon="drag_handle"
        *ngIf="hasAnOrder() && this.sortFromHeaderCellActivated"
        (click)="resetToDefaultSorting()"
        spinTooltip="Activate drag and drop" />
      <pearl-button
        type="icon"
        icon="splitscreen_right"
        *ngIf="entityTableConfig.canChooseColumns"
        (click)="openFilterColumnsDialog()"
        spinTooltip="Add/remove columns" />
      <span [spinTooltip]="config.getXlsxDownloadTooltip(entityTableConfig.noExport)">
        <pearl-button
          icon="download"
          type="icon"
          [disabled]="config.trialModeDownloadsDisabled || entityTableConfig.noExport"
          (click)="exportCsv()" />
      </span>
      <pearl-button
        style="padding-right: 10px"
        *ngIf="canAddInModal"
        icon="add"
        type="alternative"
        spinTooltip="New"
        (click)="newEntity()">
        New
      </pearl-button>
    </div>
  </div>
  <div class="entity-table-container" cdkScrollable>
    <mat-spinner [diameter]="20" *ngIf="loading > 0" class="table-loading"> </mat-spinner>
    <table
      mat-table
      #table
      [dataSource]="dataSource"
      matSort
      *ngIf="showTable"
      [trackBy]="trackRow"
      class="entity-table"
      [ngClass]="{ hidden: loading > 0 }"
      cdkDropList
      (cdkDropListDropped)="drop($event)">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="selectRow" *ngIf="entityTableConfig.canChooseRows && selectedRows">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'first-cell-of-category last-cell-of-category  category-cell': hasCategory }">
          <mat-checkbox
            (change)="toggleAllRows()"
            [checked]="selectedRows.hasValue() && isAllSelected()"
            [indeterminate]="selectedRows.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()" />
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [ngClass]="{ 'first-cell-of-category last-cell-of-category  category-cell': hasCategory }">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onToggleRow(row)"
            [checked]="selectedRows.isSelected(row)"
            [aria-label]="checkboxLabel(row)" />
        </td>
      </ng-container>

      <ng-container *ngFor="let field of visibleColumns; trackBy: trackField" matColumnDef="{{ field.id }}">
        <th
          mat-header-cell
          [class]="field.class"
          *matHeaderCellDef
          [spinTooltip]="field.description"
          mat-sort-header
          id="{{ field.id }}">
          {{ getFieldHeaderTitle(field) }}
        </th>
        <td mat-cell [class]="field.class" *matCellDef="let row">
          <field-button
            *ngIf="field.button && shouldShowButton(field.button, row, field); else readOnlyRow"
            [buttonConfig]="field.button"
            [values]="row"
            (buttonClick)="onFieldButton($event)">
            <ng-container *ngTemplateOutlet="readOnlyRow"></ng-container>
          </field-button>

          <ng-template #readOnlyRow>
            <!-- readonly version of the row -->
            <ng-container *ngIf="!isRowEditable(row) || !field.editable; else editRow">
              <ng-container [ngSwitch]="fieldsHelperService.getFieldType(field, row)">
                <div [ngStyle]="fieldsHelperService.getCustomCssStyle(field, row)">
                  <pearl-icon
                    *ngSwitchCase="'icon-field'"
                    [icon]="fieldsHelperService.getResultFieldIcon(field, row)"
                    [size]="24"
                    spinTooltip="{{ fieldsHelperService.getResultFieldTooltip(field, row) }}" />
                </div>

                <ng-container *ngSwitchCase="'entity'">
                  <a
                    *ngIf="field.link"
                    href="{{ urlFromLink(row, field) }}"
                    class="linked-entity"
                    (click)="linkedEntityDetail(row, field, $event)">
                    {{ formattedFieldValue(row, field) }}
                  </a>
                  <div *ngIf="field.link !== true" class="non-linked-entity">{{ formattedFieldValue(row, field) }}</div>
                </ng-container>

                <ng-container *ngSwitchCase="'client_entity'">
                  <a
                    *ngIf="field.link"
                    href="{{ urlFromLink(row, field) }}"
                    class="linked-entity"
                    (click)="linkedEntityDetail(row, field, $event)">
                    {{ formattedFieldValue(row, field) }}
                  </a>
                  <div *ngIf="field.link !== true" class="non-linked-entity">{{ formattedFieldValue(row, field) }}</div>
                </ng-container>

                <spin-link
                  *ngSwitchCase="'link'"
                  [link]="externalUrlFromLink(row, field)"
                  [linkTitle]="externalLinkTitle(row, field)"
                  [icon]="field.icon" />

                <a
                  *ngSwitchCase="'pageLink'"
                  [spinTooltip]="getFieldTooltip(row, field)"
                  [href]="getPageRouterLink(row, field)"
                  [ngStyle]="getPageLinkStyle(field, row)"
                  queryParamsHandling="preserve"
                  class="item-value"
                  (click)="onPageLinkClick($event, field, row)">
                  {{ formattedFieldValue(row, field) }}</a
                >

                <ng-container *ngSwitchCase="'pageLinkList'">
                  <ng-container *ngFor="let pageLink of getPageLinkList(row, field)">
                    <a
                      [href]="pageLink.href"
                      class="item-value"
                      [ngStyle]="getPageLinkStyle(field, row, pageLink.index)"
                      queryParamsHandling="preserve"
                      (click)="onPageLinkClick($event, field, row, pageLink.index)">
                      {{ pageLink.title }}</a
                    >
                    <span *ngIf="pageLink.index != pageLink.linkListLength - 1">, </span>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'externalLink'">
                  <a
                    *ngIf="getExternalLink(field, row)"
                    [href]="getExternalLink(field, row)"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="external-link-text"
                    >{{ formattedFieldValue(row, field) }}<i class="material-icons external-link">launch</i>
                  </a>
                  <span *ngIf="getValue(field) && !getExternalLink(field, row)">
                    {{ formattedFieldValue(row, field) }}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'html'">
                  <div [innerHTML]="formattedFieldValue(row, field) | safeHtml"></div>
                </ng-container>

                <div *ngSwitchCase="'colorPicker'" class="color-field" [ngStyle]="getColorFieldStyle(field, row)">
                  <mat-label> {{ formattedFieldValue(row, field) }} </mat-label>
                </div>

                <!-- Files -->
                <ng-container *ngSwitchCase="'files'">
                  <ng-container *ngFor="let file of row[field.id]; let i = index; let last = last">
                    <a href="{{ file.link }}" target="_blank" class="link"> {{ file.title }} </a>
                    {{ last ? "" : "," }}
                  </ng-container>
                </ng-container>

                <!-- Default field -->
                <div *ngSwitchDefault [spinTooltip]="getFieldTooltip(row, field)">
                  <div class="default-field">
                    <span>{{ formattedFieldValue(row, field) }}</span>
                    <pearl-icon
                      *ngIf="getComparison(row, field) as comparison"
                      [ngStyle]="{ color: comparison.arrow.color }"
                      [icon]="getMaterialArrowIcon(comparison.arrow)"
                      [attr.icon]="getMaterialArrowIcon(comparison.arrow)"
                      [size]="24">
                    </pearl-icon>
                  </div>
                  <ng-container *ngIf="isOverridden(row, field)">
                    &nbsp;
                    <span class="spin-value"> {{ getSpinergieValue(row, field) }} </span>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
          <!--  Editable version of the row -->
          <ng-template #editRow>
            <ng-container [ngSwitch]="true">
              <div *ngSwitchCase="field.type === 'entity' || field.type === 'timezone'">
                <search-bar
                  #search_bar
                  [searchLabel]="field.title"
                  [searchItemsAvailable]="field.values"
                  [searchBarStyle]="'outline'"
                  [hasLabel]="false"
                  [searchBarInput]="getValue(field)"
                  (onoptionselected)="optionSelected(field, $event)">
                </search-bar>
                <mat-hint class="error-hint" *ngIf="fieldInvalid(field)"> {{ getErrorMessage(field) }} </mat-hint>
              </div>
              <div *ngSwitchCase="field.type === 'client_entity'">
                <search-bar
                  #search_bar
                  [searchLabel]="field.title"
                  [hasLabel]="false"
                  [searchItemsAvailable]="field.values"
                  [searchBarStyle]="'outline'"
                  [searchBarInput]="getValue(field)"
                  (onoptionselected)="optionSelected(field, $event)">
                </search-bar>
                <mat-hint class="error-hint" *ngIf="fieldInvalid(field)"> {{ getErrorMessage(field) }} </mat-hint>
              </div>
              <pearl-form-field
                *ngSwitchCase="field.type === 'choice'"
                [hintError]="fieldInvalid(field)"
                [small]="true"
                [hasLabel]="false"
                [errorMessage]="getErrorMessage(field)">
                <mat-select
                  #matSelect
                  [value]="getChoice(field)"
                  [required]="field.req"
                  [disabled]="!field.editable"
                  [placeholder]="field.title"
                  [ngClass]="getFieldClass(field)"
                  (selectionChange)="optionSelected(field, $event.value)">
                  <mat-option [value]="null"></mat-option>
                  <mat-option
                    *ngFor="
                      let option of field.values
                        | OrderBy: (field.orderField ? field.orderField : 'id') : field.comboBoxOrder
                    "
                    [value]="option">
                    {{ option.title }}
                  </mat-option>
                </mat-select>
              </pearl-form-field>

              <pearl-form-field
                *ngSwitchCase="field.type === 'date' || field.type === 'datetime'"
                [hintError]="fieldInvalid(field)"
                [small]="true"
                [hasLabel]="false"
                [label]="field.title"
                [errorMessage]="getErrorMessage(field)">
                <input
                  matInput
                  [pearlDatepicker]="datepicker"
                  [value]="getDate(field)"
                  [timezone]="field.type === 'date' ? 'UTC' : null"
                  [disabled]="!field.editable"
                  (dateChange)="setDate(field, $event.value)" />
                <ng-container pearlFormSuffix>
                  <pearl-datepicker-toggle size="small" matIconSuffix [for]="datepicker" />
                  <pearl-datepicker #datepicker [precision]="field.type === 'date' ? 'day' : 'second'" />
                </ng-container>
              </pearl-form-field>

              <pearl-number
                *ngSwitchCase="field.type === 'number'"
                [hideNumberButtons]="field.hideNumberButtons"
                [hintError]="fieldInvalid(field)"
                [small]="true"
                [hasLabel]="false"
                [label]="field.title"
                [value]="$any(getNumber(field))"
                (valueChange)="setNumber(field, $event)"
                [errorMessage]="getErrorMessage(field)" />

              <mat-checkbox
                *ngSwitchCase="field.type === 'checkbox'"
                (change)="setCheckbox(field, $event)"
                [checked]="isChecked(field)">
                {{ field.title }}
              </mat-checkbox>

              <div *ngSwitchCase="field.type === 'collection'" [ngClass]="getCollectionFieldClass(field)">
                <pearl-button
                  *ngIf="field.selector"
                  type="icon"
                  icon="sort"
                  [spinTooltip]="getOpenSelectorTooltip(field)"
                  class="advanced-filters-button"
                  (click)="advancedFiltersClick(field)" />
                <multi-form-field
                  #collectionMulti
                  matInput
                  [disabled]="!field.editable"
                  class="multi-form-field"
                  [ngClass]="getFieldClass(field)"
                  [parentField]="field"
                  [parentEntityDefinition]="definition"
                  (onchange)="setCollection(field, $event)"
                  (loadingStart)="startLoad()"
                  (loadingEnd)="endLoad()"
                  [entity]="row" />
                <mat-hint class="error-hint" *ngIf="fieldInvalid(field)"> {{ getErrorMessage(field) }} </mat-hint>
              </div>

              <!-- Files -->
              <ng-container *ngSwitchCase="field.type === 'files'">
                {{ "[" }}
                <ng-container *ngFor="let file of row[field.id]; let i = index; let last = last">
                  <a href="{{ file.link }}" target="_blank" class="link"> {{ file.title }} </a>
                  {{ last ? "" : "," }}
                </ng-container>
                {{ "]" }}
              </ng-container>

              <!-- Default case should handle type text, eventually longText -->
              <div *ngSwitchCase="field.type === 'colorPicker'" class="color-field" appearance="fill">
                <input
                  class="color-field-input"
                  [colorPicker]="formattedFieldValue(row, field)"
                  [value]="getValue(field)"
                  [style.background]="formattedFieldValue(row, field)"
                  [cpPresetColors]="getColorPickerPresetColors()"
                  (change)="anyColorChange(field, $event)"
                  (colorPickerChange)="setColor(field, $event)"
                  [cpPosition]="'auto'" />
              </div>

              <pearl-form-field *ngSwitchDefault [hasLabel]="false" [small]="true">
                <textarea
                  #textArea
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  [placeholder]="field.title"
                  (change)="setAnything(field, $event)"
                  >{{ getValue(field) }}</textarea
                >
              </pearl-form-field>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>

      <ng-container *ngIf="hasCategory">
        <ng-container [matColumnDef]="category.id" *ngFor="let category of orderedCategoryIds; let i = index">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="{ 'first-cell-of-category last-cell-of-category category-cell': hasCategory }"
            [attr.colspan]="categoryColspan[category.id]">
            {{ category.title }}
          </th>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="isEditable || buttonList">
        <th
          mat-header-cell
          class="actions-cell"
          *matHeaderCellDef
          [ngClass]="{
            'hide-header-cell': formTable,
            'first-cell-of-category last-cell-of-category  category-cell': hasCategory,
          }">
          <!-- inline add button in the header of the "actions" colummn-->
          <pearl-button type="icon" icon="add" spinTooltip="Quick add" *ngIf="canAdd()" (click)="addRow()" />
        </th>
        <td
          mat-cell
          [ngClass]="[
            buttonCollapsed(row) == 2 ? 'actions-cell' : 'one-action-cell',
            hasCategory ? 'first-cell-of-category last-cell-of-category category-cell' : '',
          ]"
          *matCellDef="let row">
          <div class="button-list" *ngIf="shouldHaveButtonList(row); else ButtonCollapse">
            <pearl-button
              *ngIf="canEdit(row)"
              size="small"
              spinTooltip="Edit"
              type="icon"
              icon="edit"
              (click)="makeEditable(row)" />

            <pearl-button-link
              *ngIf="canOpenModal(row)"
              spinTooltip="Open"
              size="small"
              type="icon"
              icon="open"
              [href]="urlFromLink(row)"
              (click)="onOpenEntityClick(row, $event)" />

            <pearl-button
              *ngIf="canDuplicate(row)"
              spinTooltip="Duplicate"
              size="small"
              type="icon"
              icon="duplicate"
              (click)="duplicateRow(row)" />

            <pearl-button
              *ngIf="row.editable"
              size="small"
              spinTooltip="Save"
              type="icon"
              icon="save"
              (click)="saveRow(row)" />

            <pearl-button
              *ngIf="row.editable"
              size="small"
              spinTooltip="Cancel"
              type="icon"
              icon="cancel"
              (click)="exitEditMode(row)" />

            <pearl-button
              *ngIf="canDelete(row)"
              size="small"
              spinTooltip="Delete"
              mode="warning"
              type="icon"
              icon="delete"
              (click)="enterDeleteMode(row)" />

            <pearl-button
              *ngIf="row.deleteMode"
              spinTooltip="Delete"
              mode="warning"
              size="small"
              icon="delete"
              type="alternative"
              (click)="deleteRow(row)"
              class="deleteButton">
              Yes
            </pearl-button>

            <pearl-button
              *ngIf="row.deleteMode"
              spinTooltip="Cancel"
              type="icon"
              size="small"
              icon="cancel"
              (click)="exitDeleteMode(row)" />

            <ng-container *ngIf="!row.deleteMode && !row.editable">
              <ng-container *ngFor="let button of buttonList">
                <ng-container *ngIf="shouldShowButton(button, row)">
                  <spin-link
                    *ngIf="button.type == 'link'; else classicButton"
                    [link]="linkButtonData(button, row)"
                    [description]="button.title"
                    [linkTitle]="button.title"
                    [icon]="button.icon ? button.icon : 'link'">
                  </spin-link>
                  <ng-template #classicButton>
                    <pearl-button
                      *ngIf="button.type != 'navigate'; else navigateButton"
                      [spinTooltip]="button.title"
                      type="icon"
                      size="small"
                      [icon]="button.icon ?? 'information'"
                      (click)="onclick($event, button, row)" />
                    <ng-template #navigateButton>
                      <pearl-button-link
                        [href]="getButtonRouterLink(button, row)"
                        size="small"
                        [spinTooltip]="button.title"
                        type="icon"
                        queryParamsHandling="preserve"
                        [icon]="button.icon ?? 'information'"
                        (click)="onclick($event, button, row)" />
                    </ng-template>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <ng-template #ButtonCollapse>
            <div class="button-list">
              <mat-menu #rowMenu class="button-menu">
                <pearl-button
                  *ngIf="canEdit(row)"
                  spinTooltip="Edit"
                  type="icon"
                  size="small"
                  icon="edit"
                  (click)="makeEditable(row)" />

                <pearl-button-link
                  *ngIf="canOpenModal(row)"
                  type="icon"
                  size="small"
                  icon="open"
                  spinTooltip="Open"
                  [href]="urlFromLink(row)"
                  (click)="onOpenEntityClick(row, $event)" />

                <pearl-button
                  *ngIf="canDuplicate(row)"
                  spinTooltip="Duplicate"
                  type="icon"
                  icon="duplicate"
                  size="small"
                  (click)="duplicateRow(row)" />

                <pearl-button
                  *ngIf="row.editable"
                  size="small"
                  spinTooltip="Save"
                  type="icon"
                  icon="save"
                  (click)="saveRow(row)" />

                <pearl-button
                  *ngIf="row.editable"
                  spinTooltip="Cancel"
                  type="icon"
                  size="small"
                  icon="cancel"
                  (click)="exitEditMode(row)" />

                <pearl-button
                  *ngIf="canDelete(row)"
                  spinTooltip="Delete"
                  type="icon"
                  mode="warning"
                  size="small"
                  icon="delete"
                  (click)="enterDeleteMode(row)" />

                <pearl-button
                  *ngIf="row.deleteMode"
                  spinTooltip="Delete"
                  mode="warning"
                  type="alternative"
                  icon="delete"
                  size="small"
                  (click)="deleteRow(row)">
                  Yes
                </pearl-button>

                <pearl-button
                  *ngIf="row.deleteMode"
                  spinTooltip="Cancel"
                  type="icon"
                  size="small"
                  icon="cancel"
                  (click)="exitDeleteMode(row)" />

                <ng-container *ngIf="!row.deleteMode && !row.editable">
                  <ng-container *ngFor="let button of buttonList">
                    <ng-container *ngIf="shouldShowButton(button, row)">
                      <spin-link
                        *ngIf="button.type == 'link'; else classicButton"
                        [link]="linkButtonData(button, row)"
                        [description]="button.title"
                        [linkTitle]="button.title"
                        [icon]="button.icon ? button.icon : 'link'">
                      </spin-link>
                      <ng-template #classicButton>
                        <pearl-button
                          *ngIf="button.type != 'navigate'; else navigateButton"
                          [spinTooltip]="button.title"
                          type="icon"
                          size="small"
                          [icon]="button.icon ?? 'information'"
                          (click)="onclick($event, button, row)" />
                        <ng-template #navigateButton>
                          <pearl-button-link
                            [href]="getButtonRouterLink(button, row)"
                            [spinTooltip]="button.title"
                            type="icon"
                            queryParamsHandling="preserve"
                            [icon]="button.icon ?? 'information'"
                            size="small"
                            (click)="onclick($event, button, row)" />
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-menu>
              <pearl-button spinTooltip="Actions" icon="more" type="icon" [matMenuTriggerFor]="rowMenu" />
            </div>
          </ng-template>
        </td>
      </ng-container>

      <!-- Drag Handle Cell -->
      <ng-container matColumnDef="dragColumn">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'first-cell-of-category last-cell-of-category  category-cell': hasCategory }">
          <!-- Button to match the css of the Drag handle & action-buttons -->
          <pearl-button type="icon" spinTooltip="Drag and drop allowed when sorted by ascending order" icon="legend" />
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [ngClass]="{ 'first-cell-of-category last-cell-of-category  category-cell': hasCategory }">
          <!-- Button to match the css of the action-buttons -->
          <!-- TODO: Find a way to stick dragAndDrop to table boundaries while using this button to enable dragAndDrop -->
          <pearl-button type="icon" icon="drag_handle" class="dragCursor dragButton" (mousedown)="enableDrag()" />
        </td>
      </ng-container>
      <ng-container *ngIf="hasCategory">
        <tr mat-header-row *matHeaderRowDef="categoryIds; sticky: true"></tr>
      </ng-container>
      <ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnIds; sticky: true"></tr>
      </ng-container>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnIds"
        [ngClass]="{ 'selectable-row': selectableRowPropId }"
        (click)="onRowClick(row)"
        (dblclick)="onOpenEntityClick(row, $event)"
        cdkDrag
        (cdkDragStarted)="onDragStart()"
        (cdkDragEnded)="onDragEnd()"
        [cdkDragDisabled]="dragDisabled"
        cdkDragPreviewContainer="parent"
        cdkDragLockAxis="y"
        cdkDragBoundary=".entity-table">
        <div class="row-custom-placeholder" *cdkDragPlaceholder></div>
      </tr>
    </table>
    <div class="footer-buttons" *ngIf="formTable">
      <pearl-button
        type="alternative"
        icon="add"
        spinTooltip="Quick add"
        *ngIf="definition && (isEditable || buttonList) && canAdd()"
        (click)="addRow()">
        Add
      </pearl-button>
    </div>
  </div>
</div>
