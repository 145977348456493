<ng-container [ngSwitch]="true">
  <!-- entity can have three styles either dropdown combo, searchable box or can be a trip information-->
  <ng-container *ngSwitchCase="field.type === 'entity' || field.type === 'client_entity'" [ngSwitch]="field.style">
    <!-- this is entity styled as choice (with a dropdown combo) -->
    <ng-container *ngSwitchCase="'dropdown'">
      <a
        href="{{ urlFromLink(field) }}"
        [ngClass]="getFieldClass(field)"
        class="entity-form-field"
        *ngIf="isLinkable(field); else DropDownEditBox"
        (click)="preventDefaultIfNormalClick($event) && openLinkedEntity(field, $event)">
        <pearl-form-field
          [hintError]="showErrorHint(field)"
          [errorMessage]="getErrorMessage(field)"
          [small]="small()"
          [hasLabel]="hasLabel()"
          [hintStart]="getSpinValueHint(field)"
          [hintEnd]="field.hint"
          [label]="field.title">
          <mat-select
            [value]="getChoice(field)"
            [required]="field.req"
            [disabled]="true"
            [placeholder]="getFieldPlaceholder(field)"
            [ngClass]="getFieldClass(field)"
            [disabled]="disabled"
            [matTooltip]="tooltip">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let option of field.values | OrderBy: field.orderField : field.comboBoxOrder"
              [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
          <ng-container pearlFormSuffix>
            <description-button [description]="field.description" />
          </ng-container>
        </pearl-form-field>
      </a>
      <ng-template #DropDownEditBox>
        <pearl-form-field
          *ngSwitchCase="'dropdown'"
          [label]="field.title"
          [hintError]="showErrorHint(field)"
          [errorMessage]="getErrorMessage(field)"
          [hintStart]="getSpinValueHint(field)"
          [hintEnd]="field.hint"
          [hasLabel]="hasLabel()"
          [small]="small()">
          <mat-select
            #matSelect
            [value]="getChoice(field)"
            [required]="field.req"
            [disabled]="isFieldDisabled(field)"
            [placeholder]="getFieldPlaceholder(field)"
            [ngClass]="getFieldClass(field)"
            (selectionChange)="optionSelected(field, $event.value)"
            [matTooltip]="tooltip">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let option of field.values | OrderBy: field.orderField : field.comboBoxOrder"
              [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
          <ng-container pearlFormSuffix>
            <pearl-button
              type="icon"
              icon="add"
              size="small"
              *ngIf="canAddNew(field)"
              matSuffix
              matTooltip="Add New"
              (click)="addNew(field, $event)" />
            <description-button [description]="field.description" />
          </ng-container>
        </pearl-form-field>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'chiplist'" class="entity-form-field">
      <spin-chip-list
        [chips]="field.values | OrderBy: field.orderField : field.comboBoxOrder"
        [disabled]="isFieldDisabled(field)"
        [selectable]="!isFieldReadonly(field)"
        [selected]="entityAccessor.getChoice(this.field)"
        (selectionChange)="chipSelectionChanged($event, field)" />
      <mat-hint class="error-hint" *ngIf="showErrorHint(field)"> {{ getErrorMessage(field) }} </mat-hint>
    </ng-container>

    <!-- this is the entity default style - searchable box -->
    <ng-container *ngSwitchDefault>
      <a
        href="{{ urlFromLink(field) }}"
        [ngClass]="getFieldClass(field)"
        class="entity-form-field"
        *ngIf="isLinkable(field); else SearchableEditBox"
        (click)="preventDefaultIfNormalClick($event) && openLinkedEntity(field, $event)">
        <pearl-form-field
          [small]="small()"
          [hasLabel]="hasLabel()"
          [label]="field.title"
          [hintError]="showErrorHint(field)"
          [readonly]="isFieldReadonly(field)"
          [errorMessage]="getErrorMessage(field)"
          [hintStart]="getSpinValueHint(field)"
          [hintEnd]="field.hint">
          <input
            type="text"
            [placeholder]="getFieldPlaceholder(field)"
            matInput
            [readonly]="true"
            [disabled]="isFieldDisabled(field)"
            [ngClass]="getFieldClass(field)"
            [value]="getLinkedEntityTitle(field)"
            [required]="field.req"
            autocomplete="off"
            [matTooltip]="tooltip" />
        </pearl-form-field>
      </a>
      <ng-template #SearchableEditBox>
        <pearl-form-field
          [label]="field.title"
          [small]="small()"
          [hasLabel]="hasLabel()"
          [readonly]="isFieldReadonly(field)"
          [errorMessage]="getErrorMessage(field)"
          [hintError]="showErrorHint(field)"
          [hintStart]="getSpinValueHint(field)"
          [hintEnd]="field.hint">
          <input
            type="text"
            [placeholder]="getFieldPlaceholder(field)"
            matInput
            [matAutocomplete]="auto"
            [readonly]="isFieldReadonly(field)"
            [disabled]="isFieldDisabled(field)"
            [ngClass]="getFieldClass(field)"
            [value]="getLinkedEntityTitle(field)"
            [required]="field.req"
            (input)="inputValueChanges(field, $any($event.target).value)"
            (focusout)="changeEnded($event)"
            autocomplete="off"
            [matTooltip]="tooltip" />

          <mat-autocomplete
            #matAutoComplete
            #auto="matAutocomplete"
            (optionSelected)="optionSelected(field, $event.option.value)"
            [displayWith]="getOptionName">
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let option of field.fieldState.filteredValues | OrderBy: field.orderField : field.comboBoxOrder"
              [value]="option">
              <div class="entity-option">{{ option.title }}</div>
            </mat-option>
            <mat-option *ngIf="field.showMoreVisible" class="show-more" (click)="showAll($event, field)">
              {{ field.showMoreText }}
            </mat-option>
          </mat-autocomplete>
          <ng-container pearlFormSuffix>
            <pearl-button
              type="icon"
              icon="add"
              *ngIf="canAddNew(field)"
              size="small"
              matSuffix
              matTooltip="Add New"
              (click)="addNew(field, $event)" />
            <description-button [description]="field.description" />
          </ng-container>
        </pearl-form-field>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Choice is a combo with list of items -->
  <ng-container *ngSwitchCase="field.type === 'choice' || field.type === 'timezone'" [ngSwitch]="field.style">
    <ng-container *ngSwitchCase="'chiplist'" class="entity-form-field">
      <div class="mat-chip-wrapper">
        <legend class="chip-legend">{{ field.title }}:</legend>
        <div>
          <spin-chip-list
            [chips]="field.values"
            [disabled]="isFieldDisabled(field)"
            [selectable]="!isFieldReadonly(field)"
            [selected]="entityAccessor.getChoice(this.field)"
            (selectionChange)="chipSelectionChanged($event, field)">
          </spin-chip-list>
          <mat-hint class="error-hint" *ngIf="showErrorHint(field)"> {{ getErrorMessage(field) }} </mat-hint>
        </div>
      </div>
    </ng-container>
    <search-bar
      #search_bar
      *ngSwitchCase="'searchBar'"
      [searchLabel]="field.title"
      [searchBarInput]="getSearchBarValue(field)"
      [searchItemsAvailable]="field.values"
      [resultAlreadySorted]="field.resultAlreadySorted"
      [required]="field.req"
      [description]="field.description"
      (onoptionselected)="optionSelected(field, $event)"
      [errorMessage]="getErrorMessage(field)"
      [disabled]="isFieldDisabled(field)"
      [readonly]="isFieldReadonly(field)"
      class="entity-form-field"
      [ngClass]="getFieldClass(field)"
      [matTooltip]="tooltip"
      [small]="small()"
      [hasLabel]="hasLabel()"
      [hint]="field.hint"
      [showIcon]="showSearchBarIcon()">
      <pearl-button
        *ngIf="isSyncButtonDisplayed(field)"
        matSuffix
        icon="suggestion"
        type="icon"
        sync-button
        [matTooltip]="getSyncFieldTooltip(field)"
        matTooltipPosition="above"
        [matIconId]="'sync-icon-' + field.id"
        (click)="onClickSync($event, field)" />
    </search-bar>
    <pearl-form-field
      *ngSwitchDefault
      [small]="small()"
      [hasLabel]="hasLabel()"
      [label]="field.title"
      [errorMessage]="getErrorMessage(field)"
      [readonly]="isFieldReadonly(field)"
      [hintError]="showErrorHint(field)"
      [hintStart]="getSpinValueHint(field)"
      [hintEnd]="field.hint">
      <mat-select
        #matSelect
        [value]="getChoice(field)"
        [required]="field.req"
        [disabled]="isFieldDisabled(field)"
        [placeholder]="getFieldPlaceholder(field)"
        (selectionChange)="optionSelected(field, $event.value)">
        <mat-option *ngIf="!field.req" [value]="null"></mat-option>
        <mat-option *ngFor="let option of field.values" [value]="option"> {{ option.title }} </mat-option>
      </mat-select>
      <ng-container pearlFormSuffix>
        <description-button class="tooltip-icon" [description]="field.description" />
      </ng-container>
    </pearl-form-field>
  </ng-container>

  <pearl-form-field
    [small]="small()"
    [hasLabel]="hasLabel()"
    [label]="field.title"
    [errorMessage]="getErrorMessage(field)"
    [hintError]="showErrorHint(field)"
    [hintEnd]="field.hint"
    [small]="small()"
    [readonly]="isFieldReadonly(field)"
    *ngSwitchCase="field.type === 'date'">
    <input
      matInput
      [pearlDatepicker]="picker"
      timezone="UTC"
      [value]="getDatetime(field)"
      [required]="field.req"
      [disabled]="isFieldDisabled(field)"
      [readonly]="isFieldReadonly(field)"
      [matTooltip]="tooltip"
      (dateChange)="setDatetime(field, $event)" />
    <ng-container pearlFormSuffix>
      <pearl-datepicker-toggle [size]="small() ? 'small' : 'default'" [for]="picker" />
      <pearl-datepicker #picker precision="day" />
      <description-button [description]="field.description" />
    </ng-container>
  </pearl-form-field>

  <pearl-form-field
    *ngSwitchCase="field.type === 'datetime'"
    [label]="field.title"
    [small]="small()"
    [hasLabel]="hasLabel()"
    [errorMessage]="getErrorMessage(field)"
    [readonly]="isFieldReadonly(field)"
    [hintError]="showErrorHint(field)"
    [hintEnd]="field.hint">
    <input
      matInput
      [pearlDatepicker]="picker"
      [value]="getDatetime(field)"
      [required]="field.req"
      [disabled]="isFieldDisabled(field)"
      [readonly]="isFieldReadonly(field)"
      [matTooltip]="tooltip"
      (dateChange)="setDatetime(field, $event)" />
    <ng-container pearlFormSuffix>
      <pearl-datepicker-toggle [for]="picker" [size]="small() ? 'small' : 'default'" *ngIf="!isFieldReadonly(field)" />
      <pearl-datepicker #picker [precision]="datetimePrecision" />
      <description-button [description]="field.description" />
    </ng-container>
  </pearl-form-field>

  <pearl-form-field
    *ngSwitchCase="field.type === 'time'"
    [label]="field.title"
    [small]="small()"
    [hasLabel]="hasLabel()"
    [errorMessage]="getErrorMessage(field)"
    [readonly]="isFieldReadonly(field)"
    [hintError]="showErrorHint(field)"
    [hintEnd]="field.hint">
    <input
      matInput
      [pearlDatepicker]="picker"
      [ngClass]="getFieldClass(field)"
      [value]="getDatetime(field)"
      [required]="field.req"
      [disabled]="isFieldDisabled(field)"
      [readonly]="isFieldReadonly(field)"
      [matTooltip]="tooltip"
      (dateChange)="setDatetime(field, $event)" />
    <ng-container pearlFormSuffix>
      <pearl-datepicker-toggle [for]="picker" [size]="small() ? 'small' : 'default'" *ngIf="!isFieldReadonly(field)" />
      <pearl-datepicker #picker timeOnly precision="minute" />
    </ng-container>
    <div class="time-suffix" *ngIf="field.suffix">{{ field.suffix }}</div>
  </pearl-form-field>

  <pearl-form-field
    *ngSwitchCase="field.type === 'datetimeWithTimezone'"
    [label]="field.title"
    [small]="small()"
    [hasLabel]="hasLabel()"
    [errorMessage]="getErrorMessage(field)"
    [hintError]="showErrorHint(field)"
    [readonly]="isFieldReadonly(field)"
    [hintEnd]="field.hint">
    <input
      matInput
      [pearlDatepicker]="picker"
      [ngClass]="getFieldClass(field)"
      [timezone]="getTimezone(field)"
      [initialValue]="getDatetime(field)"
      [required]="field.req"
      [disabled]="isFieldDisabled(field)"
      [readonly]="isFieldReadonly(field)"
      [matTooltip]="tooltip"
      (dateChange)="setDatetime(field, $event)" />
    <ng-container pearlFormSuffix>
      <pearl-datepicker-toggle [for]="picker" [size]="small() ? 'small' : 'default'" *ngIf="!isFieldReadonly(field)" />
      <pearl-datepicker #picker [precision]="datetimePrecision" withTimezone />
    </ng-container>
  </pearl-form-field>

  <pearl-number
    *ngSwitchCase="field.type === 'number' || field.type === 'duration'"
    updateOn="blur"
    [label]="field.title"
    [hideNumberButtons]="field.hideNumberButtons"
    [small]="small()"
    [value]="$any(getNumber(field))"
    (valueChange)="setAnything(field, $event)"
    [hasLabel]="hasLabel()"
    [errorMessage]="getErrorMessage(field)"
    [hintError]="showErrorHint(field)"
    [hintStart]="getSpinValueHint(field)"
    [readonly]="isFieldReadonly(field)"
    [hintEnd]="field.hint">
    <ng-container pearlFormSuffix>
      <description-button [description]="field.description" />
      <pearl-button
        *ngIf="isSyncButtonDisplayed(field)"
        matSuffix
        icon="suggestion"
        type="icon"
        [matTooltip]="getSyncFieldTooltip(field)"
        matTooltipPosition="above"
        [id]="'sync-icon-' + field.id"
        (click)="onClickSync($event, field)" />
    </ng-container>
  </pearl-number>

  <div *ngSwitchCase="field.type === 'coordinate'" class="coordinate-field">
    <pearl-form-field
      floatLabel="always"
      [label]="field.title"
      [small]="small()"
      [hasLabel]="hasLabel()"
      [readonly]="isFieldReadonly(field)"
      [errorMessage]="getErrorMessage(field)"
      [hintError]="showErrorHint(field)">
      <input
        type="text"
        matInput
        [(ngModel)]="coordinate"
        (keypress)="isDMSCharacter($event)"
        (focusout)="completeCoordinateDegree(field, $event)"
        [placeholder]="getCoordinatePlaceHolder(field)"
        [required]="field.req"
        [readonly]="isFieldReadonly(field)"
        [disabled]="isFieldDisabled(field)"
        [id]="field.id + '_dms'"
        [name]="field.id + '_dms'"
        [ngClass]="getFieldClass(field)"
        autocomplete="off"
        [matTooltip]="tooltip" />
    </pearl-form-field>
    <pearl-number
      [label]="field.title"
      [small]="small()"
      [hasLabel]="hasLabel()"
      [readonly]="isFieldReadonly(field)"
      [disabled]="isFieldDisabled(field)"
      [required]="field.req"
      [matTooltip]="tooltip"
      [hideNumberButtons]="field.hideNumberButtons"
      [value]="$any(getNumber(field))"
      (valueChange)="setCoordinate(field, $event, 'decimal')"
      [hintStart]="getSpinValueHint(field)"
      [readonly]="isFieldReadonly(field)"
      [hintEnd]="field.hint">
      <ng-container pearlFormSuffix>
        <pearl-button
          *ngIf="isSyncButtonDisplayed(field)"
          matSuffix
          icon="suggestion"
          type="icon"
          [matTooltip]="getSyncFieldTooltip(field)"
          matTooltipPosition="above"
          [id]="'sync-icon-' + field.id"
          (click)="onClickSync($event, field)" />
        <description-button [description]="field.description" />
      </ng-container>
    </pearl-number>
  </div>

  <div
    *ngSwitchCase="field.type === 'checkbox'"
    class="entity-form-field entity-form-checkbox-field"
    [ngClass]="getFieldClass(field)">
    <mat-checkbox
      [disabled]="isFieldDisabled(field)"
      [ngClass]="getFieldClass(field)"
      [checked]="isChecked(field)"
      (change)="setCheckbox(field, $event)"
      [matTooltip]="tooltip">
      {{ field.title }}
      <description-button class="checkbox-tooltip-icon tooltip-icon" [description]="field.description" />
    </mat-checkbox>
    <mat-hint class="error-hint" *ngIf="showErrorHint(field)"> {{ getErrorMessage(field) }} </mat-hint>
    <div
      class="spin-checkbox-hint spin-value-hint"
      [ngClass]="getSpinValueHintClass(field)"
      *ngIf="showSpinValueHint(field)">
      {{ getSpinValueHint(field) }}
    </div>
  </div>

  <div
    *ngSwitchCase="field.type === 'collection'"
    class="linked-collection"
    [ngClass]="{ 'simple-multi-field': field.formType == 'multi' }">
    <ng-container *ngIf="field.style == 'integrated'">
      <entity-detail-table
        #integratedTable
        [parentField]="field"
        [parentEntity]="entity"
        [parentEntityDefinition]="$any(this.entityAccessor).definition"
        [parent]="parent"
        [parentAccessor]="$any(entityAccessor)" />
    </ng-container>
    <ng-container *ngIf="!field.style && loaded">
      <mat-label *ngIf="field.formType != 'multi'" class="collection-title"> {{ field.title }} </mat-label>
      <description-button class="collection-tooltip tooltip-icon" [description]="field.description">
      </description-button>
      <entity-linked-collection
        #linkedCollection
        (childElementChanges)="onChildElementChanges($event)"
        (valuesChange)="valueChangeForEntityLinkedCollection(field)"
        [parentField]="field"
        [parentEntity]="entity"
        [afterCloseAction]="parent.afterClose"
        [parentFullyLoaded]="parent.fullyLoaded"
        [parentEntityDefinition]="$any(entityAccessor).definition"
        [editMode]="parent.editMode"
        [parentTitle]="$any(entityAccessor).getEntityTitle()"
        (openEntityRequest)="entityDialogManager.openEntityDialog($event)"
        [alwaysNotifyParent]="true" />
    </ng-container>
    <mat-hint class="error-hint" *ngIf="showErrorHint(field)"> {{ getErrorMessage(field) }} </mat-hint>
  </div>

  <div *ngSwitchCase="field.type === 'integrated-table'" class="linked-collection integrated-table"></div>

  <div *ngSwitchCase="isFileUploader(field.type)" class="file-collection entity-form-field">
    <file-uploader
      #fileCollection
      [parentField]="field"
      [parentEntity]="entity"
      [parentEntityDefinition]="entityAccessor.definition"
      [editMode]="parent.editMode"
      [fieldTitle]="getFieldPlaceholder(field)"
      [disabled]="isFieldDisabled(field)"
      [multi]="field.type === 'files'"
      (fileUploaded)="setFile(field)">
    </file-uploader>
    <mat-hint class="error-hint" *ngIf="showErrorHint(field)"> {{ getErrorMessage(field) }}</mat-hint>
  </div>

  <div *ngSwitchCase="field.type === 'json'" [ngClass]="getFieldClass(field)" class="codemirror-div">
    <mat-label>
      {{ field.title }}
      <description-button class="tooltip-icon" [description]="field.description" [skipMarkdown]="true"
    /></mat-label>
    <div #codemirrorContainer></div>
    <mat-hint class="spin-value-hint" [ngClass]="getSpinValueHintClass(field)" *ngIf="showSpinValueHint(field)">
      {{ getSpinValueHint(field) }}
    </mat-hint>
    <mat-hint
      class="spin-value-hint"
      [ngClass]="getSpinValueHintClass(field)"
      align="end"
      *ngIf="field.hint"
      [innerHtml]="field.hint">
    </mat-hint>
  </div>

  <div *ngSwitchCase="field.type === 'colorPicker'" class="entity-color-field">
    <mat-label class="color-field-label">{{ field.title }}: </mat-label>
    <div class="color-picker-wrapper">
      <input
        class="color-field-input"
        [disabled]="!parent.editMode"
        [colorPicker]="getValue(field)"
        [value]="getValue(field)"
        [style.background]="getValue(field)"
        [cpPresetColors]="getColorPickerPresetColors()"
        (colorPickerChange)="setColor(field, $event)"
        (change)="anyColorChange(field, $event)"
        [cpPosition]="'auto'" />
    </div>
  </div>

  <div *ngSwitchCase="field.type === 'checkbox-details-required'" class="checkbox-details-required">
    <div class="checkbox-details-title">{{ field.title }}</div>
    <div class="checkbox-details">
      <mat-checkbox
        #checkboxDetailsCheckbox
        labelPosition="before"
        class="entity-form-checkbox-field entity-checkbox-detail"
        [disabled]="isFieldDisabled(field)"
        [checked]="getValue(field)?.value"
        (change)="setCheckboxRequired(field, $event.checked)" />

      <pearl-form-field
        [small]="small()"
        [hasLabel]="hasLabel()"
        label="Comment"
        [hintError]="checkboxDetailsHintVisible(checkboxDetailsCheckbox.checked, field)"
        [readonly]="isFieldReadonly(field)"
        errorMessage="Comment is required"
        (change)="setCheckboxRequired(field, undefined, $any($event.target).value)">
        <input
          matInput
          #checkboxRequiredInput
          [required]="checkboxDetailsCheckbox.checked"
          [disabled]="isFieldDisabled(field)"
          [value]="getValue(field)?.comment ? getValue(field).comment : ''"
          (input)="
            setAnything(
              field,
              $any({
                target: { value: { value: checkboxDetailsCheckbox.checked, comment: checkboxRequiredInput.value } },
              })
            )
          " />
      </pearl-form-field>
    </div>
  </div>

  <!-- Default case should handle type text, eventually longText -->
  <pearl-form-field
    *ngSwitchDefault
    [label]="field.title"
    [small]="small()"
    [hasLabel]="hasLabel()"
    [errorMessage]="getErrorMessage(field)"
    [hintError]="showErrorHint(field)"
    [readonly]="isFieldReadonly(field)"
    [hintEnd]="field.hint">
    <textarea
      matInput
      [placeholder]="getFieldPlaceholder(field)"
      [value]="getValue(field)"
      [disabled]="isFieldDisabled(field)"
      rows="1"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      (input)="setAnything(field, $event, false)"
      [required]="field.req"
      [readonly]="isFieldReadonly(field)"
      (focusout)="setAnything(field, $event, true)"></textarea>
    <ng-container pearlFormSuffix>
      <description-button [description]="field.description" />
    </ng-container>
  </pearl-form-field>

  <!-- Blank is a specifal field which is used to add blank field.
              This is use when field number is odd. We want to place last field under the second and not the first column -->
  <ng-container *ngSwitchCase="field.type === 'blank'">
    <div [ngClass]="getFieldClass(field)"></div>
  </ng-container>
</ng-container>
