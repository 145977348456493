@if (fullyLoaded) {
  <div class="integrated-table-wrapper">
    <mat-label class="table-title">{{ parentField?.title }}</mat-label>
    @for (row of rows; track row) {
      <div class="entity-table-row">
        @for (field of row.fields; track field) {
          @if (showField(field)) {
            <entity-detail-field
              #tableFields
              class="entity-table-component-field"
              [field]="field"
              [small]="true"
              [entityAccessor]="row.entityAccessor"
              [parent]="this" />
          }
        }
        <pearl-button type="icon" icon="delete" (click)="deleteRow(row)" />
        <pearl-button type="icon" icon="duplicate" (click)="duplicateRow(row)" />
      </div>
    }
  </div>
  <div class="add-button-wrapper">
    <pearl-button icon="add" type="alternative" (click)="addEmptyRow()">Add new</pearl-button>
  </div>
} @else {
  <div>
    <mat-spinner diameter="20" />
  </div>
}
