import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';

import { meanBy, merge, orderBy } from 'lodash-es';
import { Layout, PlotData, PlotMouseEvent } from 'plotly.js';

import { NvGraph } from './nvgraph';
import { ChartExportData, ChartSelectKey, ChartValues, PlotlyLegendClick, PlotlyXAxisConfig, QuantileData,
  RealPlotDatum, SeriesHeader } from './chart-types';
import { NumOrString, TooltipSeries } from '../helpers/types';
import { ChartingHelpers } from './charting-helpers';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { GraphOptionsComponent } from './graph-options';
import { DescriptionButtonComponent } from '../shared/description-button';
import { ChartSelectsHelper } from './chart-selects-helper';

@Component({
  selector: 'spin-boxplot',
  templateUrl: 'boxplot.html',
  styleUrls: ['nvgraph.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DescriptionButtonComponent,
    GraphOptionsComponent,
    MatProgressSpinnerModule,
    NgClass,
    ChartTooltipComponent,
  ],
})
export class BoxplotComponent extends NvGraph {
  protected override traces: Partial<PlotData>[];
  protected uniqueXValues = null;

  private legendItemClickTimeout: number | null = null;

  public override availableSelects: ChartSelectKey[] = [
    'metric',
    'groupby',
    'splitby',
  ];

  constructor(elementRef: ElementRef) {
    super(elementRef, 'boxplot');
  }

  /**
   * Overridden method for csv data preparation for boxplot
   * box-plot needs to unwrap the object which contains the quantiles
   */
  public override prepareCsvData(_: SeriesHeader, data: any[]): ChartExportData {
    const exportData = [];
    const hasSplit = ChartSelectsHelper.findSelectValue(this.display.splitby, this.selects.splitby) != null;
    const discardOutliers = !this.opts.showOutliers;
    for (const d of data) {
      /*
       * Copy each box data to the export data.
       * Data object contains one item per group. For each data item, the splits correspond to all keys
       * which are not 'x' or technical parameters (beginning by '__')
       */
      Object.keys(d).filter(keyName => keyName !== 'x' && !keyName.startsWith('__'))
        .forEach(split => {
          const exportItem = {
            x: d.x,
            ...d[split],
          };
          if (hasSplit) {
            exportItem['splitName'] = split !== 'All' ? split : '';
          }
          if (discardOutliers) {
            delete exportItem.outliers;
          }
          exportData.push(exportItem);
        });
    }
    const metricTitle = ChartSelectsHelper.findSelectMetrics(this.display.metric, this.selects.metric)[0].title;
    const exportHeader = {
      x: `${ChartSelectsHelper.findSelectValue(this.display.groupby, this.selects.groupby).title}`,
      splitName: hasSplit
        ? `${ChartSelectsHelper.findSelectValue(this.display.splitby, this.selects.splitby).title}`
        : '',
      whiskerLow: `1st Decile - ${metricTitle}`,
      Q1: `1st Quartile - ${metricTitle}`,
      Q2: `Median - ${metricTitle}`,
      Q3: `3rd Quartile - ${metricTitle}`,
      whiskerHigh: `9th Decile - ${metricTitle}`,
      outliers: `Outliers - ${metricTitle}`,
      nbOfObservations: 'Nb. observations',
    };

    if (!hasSplit) {
      delete exportHeader.splitName;
    }
    if (discardOutliers) {
      delete exportHeader.outliers;
    }

    return {
      header: exportHeader,
      data: exportData,
    };
  }

  private getGroupMedian(group: any): number {
    if (!this.selectedSplit) {
      return group[this.getDefaultSplitKeyValue(group)].Q2;
    }
    const allValues: { Q2: unknown }[] = Object.values(group);
    const valuesWithMedian = allValues.filter(d => d?.Q2);
    return meanBy(valuesWithMedian, d => d.Q2);
  }

  /**
   * Transforms the boxplot series
   * If we are in a groupby and splitby configuration we have to transform the series:
   * a boxplot chart expect one box for one group/split
   */
  private transformBoxplotSeries(header: object, data: ChartValues[]): SeriesHeader {
    const defaultSplitKey = data.length ? this.getDefaultSplitKeyValue(data[0]) : 'All';
    const concatHeader: SeriesHeader = {};
    const plotlySeries: { [id: string]: Partial<PlotData> } = {};

    /*
     * By default when plotly hide outliers it includes the outliers values
     * in its quartile calculation. So if we hide outliers we want to use
     * our computed values
     */
    const useCustomComputedValues = !this.opts.showOutliers;

    let i = 0;
    this.ticks = {};

    let sorted = data;
    this.minY = Number.MAX_VALUE;
    this.maxY = -Number.MAX_VALUE;

    // In case where xAxis are number we want to order the xAxis by x values
    if (this.opts.xAxisType === 'numeric') {
      sorted = orderBy(data, d => d.x);
    } else if (this.opts.xAxisType === 'mixed') {
      // sort the values, leave non-numeric values to the end
      sorted.sort((a, b) => ChartingHelpers.numOrStringCompare(a.x, b.x));
    } else {
      sorted = orderBy(data, d => this.getGroupMedian(d), 'desc');
    }

    for (const group of sorted) {
      const groupKey = group.x;

      // No Split by selected
      if (!this.selectedSplit) {
        /*
         * if we don't have splitting then from our point of view
         * all boxplot goes to the same trace
         */
        if (this.traces.length === 0) {
          const traceAll = BoxplotComponent.initTrace(defaultSplitKey, this.opts.showOutliers, useCustomComputedValues);
          this.traces.push(traceAll);
        }
        const trace = this.traces[0];

        /*
         * So if useCustomComputedValues is true (because we want to hide outliers) we will use computed quantile
         * calculated for nvd3
         */
        if (useCustomComputedValues) {
          /*
           * If we are in the case where there is not split by - this line has to be inline with what we have in
           * charting-helpers *sub* definition
           * we are basing ourselves directly on *sub* key defined in charting-helpers
           */
          trace.x.push(i);
          BoxplotComponent.fillTrace(trace, group[defaultSplitKey], group);
          this.minY = Math.min(this.minY, group[defaultSplitKey].Q2);
          this.maxY = Math.max(this.maxY, group[defaultSplitKey].Q2);
          this.ticks[i] = this.formatXTickTextValue(groupKey);
          i++;
          continue;
        }

        trace.y = [];
        continue;
      }

      // handle the standard case when splitby is selected
      for (const splitKey in group) {
        if (splitKey.startsWith('__')) {
          continue;
        }

        if (header[splitKey]) {
          const concatKey = `${groupKey} : ${splitKey}`;
          concatHeader[concatKey] = concatKey;
        }

        // handle plotly trace
        if (splitKey !== 'x') {
          if (!plotlySeries[splitKey]) {
            plotlySeries[splitKey] = BoxplotComponent.initTrace(splitKey, true, useCustomComputedValues);
          }

          /*
           * So if useCustomComputedValues is true (because we want to hide outliers) we will use computed quantile
           * calculated for nvd3
           */
          if (useCustomComputedValues) {
            plotlySeries[splitKey].x.push(i);
            BoxplotComponent.fillTrace(plotlySeries[splitKey], group[splitKey], group);
            this.minY = Math.min(this.minY, group[splitKey].Q2);
            this.maxY = Math.max(this.maxY, group[splitKey].Q2);
            this.ticks[i] = this.formatXTickTextValue(groupKey);
            i++;
            continue;
          }
        }
      }
    }

    this.uniqueXValues = i;

    // Fill plotly traces data structure
    for (const key in plotlySeries) {
      this.traces.push(plotlySeries[key]);
    }

    return concatHeader;
  }

  /**
   * Init boxplot trace specific fields: quartiles (q1, q3), median, lowerfence, upperfence, nbObservations
   */
  private static initTrace(
    splitKey: string,
    showOutliers: boolean,
    useCustomComputedValues: boolean,
  ): Partial<PlotData> {
    const trace: Partial<PlotData> = {
      name: splitKey,
      type: 'box',
      boxpoints: showOutliers ? 'Outliers' : false,
      x: [],
      customData: [],
    };
    if (!useCustomComputedValues) {
      trace.y = [];
    } else {
      trace.q1 = [];
      trace.median = [];
      trace.q3 = [];
      trace.lowerfence = [];
      trace.upperfence = [];
      trace.nbObservations = [];
    }

    return trace;
  }

  /**
   * Fill boxplot trace specific fields: quartiles (q1, q3), median, lowerfence, upperfence, nbObservations
   */
  private static fillTrace(trace: Partial<PlotData>, split: QuantileData, group: ChartValues): void {
    trace.q1.push(split.Q1);
    trace.median.push(split.Q2);
    trace.q3.push(split.Q3);
    trace.lowerfence.push(split.whiskerLow);
    trace.upperfence.push(split.whiskerHigh);
    trace.nbObservations.push(split.nbOfObservations);
    trace.customData.push({ xValue: group.x, xId: group.__xId });
  }

  private formatXTickTextValue(groupKey: NumOrString): string {
    const tickValue = this.getFormattedXAxisTooltipValue(
      { x: groupKey, data: {} } as RealPlotDatum,
      this.getXAxisFormat(),
    );
    const suffix = ChartingHelpers.formatSuffix(this.selectedGroup.suffix);
    return this.adaptValueToXAxisTick(tickValue) + suffix;
  }

  /**
   * Plotly charts have different Layout opts from nvd3 charts.
   * This function is used to map opts from chartOpts to plotly opts
   */
  public override mapChartOptsInPlotlyLayout(): Partial<Layout> {
    const plotlyLayout = super.mapChartOptsInPlotlyLayout();

    // A bit of heuristics to make sure the boxes are never too big and ugly
    if (this.uniqueXValues < 3) {
      plotlyLayout.boxgap = 0.5;
    }

    plotlyLayout.boxgroupgap = 0.25;

    return plotlyLayout;
  }

  /**
   * Boxplot has its own plotly tooltip function because it doesn't need to iterate trough each point
   * and just need to get calculated data for 1 point
   */
  override plotlyTooltip = (data: PlotMouseEvent): void => {
    const point = data.points[0];
    const calcPt = this.calculatedData[point.curveNumber][point.pointNumber];

    if (this.opts.usePlotlyTooltip || !calcPt) {
      return;
    }

    const tooltipOpts: TooltipSeries = {
      type: 'bar',
      splits: [],
      xValue: null,
      showErrors: false,
      hideColorCircle: true,
    };

    if (this.hasGroupbyPageLink()) {
      const pageLink = ChartingHelpers.getPageLink(this.selectedGroup, this.config.availablePages);
      tooltipOpts.xPageUrl = pageLink ? this.computeXPageUrl(pageLink, point) : null;
    }

    tooltipOpts.splits = [];

    /*
     * Some value depend on showOutliers. If showOutliers is true we will used data from plotly calculated from raw data
     * But if showOutliers is false we will use our custom computed quantile
     */
    const yAxisFormatting = this.getYAxisFormat();
    tooltipOpts.splits.push({
      title: this.opts.showOutliers ? 'Min' : '1st Decile',
      value: this.applyFormatTooltip(calcPt['min'], yAxisFormatting),
    });
    tooltipOpts.splits.push({
      title: '1st Quartile',
      value: this.applyFormatTooltip(calcPt['q1'], yAxisFormatting),
    });
    tooltipOpts.splits.push({
      title: 'Median',
      value: this.applyFormatTooltip(calcPt['med'], yAxisFormatting),
    });
    tooltipOpts.splits.push({
      title: '3rd Quartile',
      value: this.applyFormatTooltip(calcPt['q3'], yAxisFormatting),
    });
    tooltipOpts.splits.push({
      title: this.opts.showOutliers ? 'Max' : '9th Decile',
      value: this.applyFormatTooltip(calcPt['max'], yAxisFormatting),
    });
    tooltipOpts.splits.push({
      title: 'Nb observations',
      value: this.opts.showOutliers ? calcPt.pts.length : point.data['nbObservations'][point.pointIndex],
    });

    const xAxisFormatting = this.getXAxisFormat();
    tooltipOpts.xValue = this.getFormattedXAxisTooltipValue(point, xAxisFormatting);

    const coords = [data.event.x, data.event.y] as [number, number];
    this.$chartTooltip.show({ series: [tooltipOpts] }, null, coords);
  };

  public prepareData(header: SeriesHeader, data: ChartValues[]): void {
    // filter out data with null quartiles
    data = data.filter(d => Object.keys(d).every(k => k != null));
    header = this.transformBoxplotSeries(header, data);

    // Update buff data in case some data has been filtered out
    this.buffer = { header, data };

    this.splitByColorService.resetColorsIfDuplicatedValues(header, this.selectedSplit);

    // fill colors for plotly traces
    this.traces.forEach(trace => {
      trace.marker = { color: this.barColors(trace.name) };
    });
  }

  public async chartSpecificPlot(header: { [id: string]: string }, data: ChartValues[]): Promise<void> {
    this.prepareData(header, data);

    /*
     * in case of noRaw data - no plotly chart should be drawing
     * unlike NVD3 which draw always (NVD3 is responsible for the No Data message)
     */
    if (this.noRawData) {
      return;
    }

    // Y-axis layout & X-axis ticks
    const axisLayout = this.getYAxisLayout();
    axisLayout.xaxis = {
      tickvals: Object.keys(this.ticks),
      ticktext: Object.values(this.ticks),
    } as PlotlyXAxisConfig;

    this.layout = merge(this.mapChartOptsInPlotlyLayout(), axisLayout);
    await this.plotlyPlot();
    this.addPlotlyTooltip();

    /**
     * Attach plotly xaxis legend fix to both single click and double click events
     * These are the events dispatched by plotly on legend item clicks
     * (https://plotly.com/javascript/plotlyjs-events/#legend-click-events).
     * They are dispatched before plotly actually changes anything on the chart.
     * When we handle the event, the traces have not yet been updated by plotly: for example a clicked visible trace is
     * still visible when handling the event, but is expected to become hidden.
     * By registering to these events we can act right before plotly does.
     * We can prevent plotly's configured behavior by returning false from the handler (similar to a preventDefault()).
     * Another way of disabling plotly's behavior is by making the layout's legend.itemclick and/or
     * legend.itemdoubleclick false (https://plotly.com/javascript/reference/layout/#layout-legend-itemclick)
     */
    this.addEventHandler(
      'plotly_legendclick',
      {
        event: 'plotly_legendclick',
        handler: this.onLegendItemClick,
        target: this.plotElement,
      },
    );
    this.addEventHandler(
      'plotly_legenddoubleclick',
      {
        event: 'plotly_legenddoubleclick',
        handler: this.onLegendItemDoubleClick,
        target: this.plotElement,
      },
    );
  }

  /**
   * Method that updates the layout xaxis ticks to only include those of the traces with indices present in the
   * `visibleTraces` parameter.
   * This forces plotly to remove the xaxis labels for non selected traces. This fixes a bug where some labels of non-
   * visible traces are still present resulting in labels overlapping (example here https://jsfiddle.net/xcfkdoeL/2/).
   * This happens when combining `tickvals`, `ticktext` and `type="category"` for xaxis (we need the category type so
   * that the boxes of the boxplot are grouped together).
   */
  private fixXAxisTicks(visibleTraces: number[]): void {
    this.ticks = {};
    visibleTraces.forEach(traceIndex => {
      this.traces[traceIndex].customData.forEach((d, index) => {
        this.ticks[this.traces[traceIndex].x[index]] = this.formatXTickTextValue(d.xValue);
      });
    });

    // Update the layout
    this.layout = merge(this.mapChartOptsInPlotlyLayout(), this.getYAxisLayout());
    const layoutUpdate = {
      'xaxis.tickvals': Object.keys(this.ticks),
      'xaxis.ticktext': Object.values(this.ticks),
    };
    this.plotlyRelayout(layoutUpdate);
  }

  /**
   * Boxes will be displayed in the order indicated by trace[n].x, so we list the links to the entities in the same
   * order.
   */
  protected override makeCustomTicksPageLink(): void {
    const customData: PlotData[] = [];
    this.traces.forEach(trace => {
      for (let i = 0; i < trace.customData.length; i++) {
        customData[trace.x[i]] = trace.customData[i];
      }
    });

    this.layout.xaxis.ticktext = this.layout.xaxis.ticktext.map(
      (tickValue: string, i: number) => this.makeTickAnHtmlLink(tickValue, customData[i].xId),
    );
  }

  /**
   * Handler for plotly_legendclick event.
   * Arrow function to keep context.
   */
  private onLegendItemClick = (data: PlotlyLegendClick): void => {
    // Click already registered, ignore new click event
    if (this.legendItemClickTimeout !== null) {
      return;
    }

    /**
     * When double clicking, plotly triggers 2 single click events and then 1 double click event, but we don't want to
     * call the single click handler when there is a double click.
     * So we register a timeout that will be cancelled if a double click event is triggered before the timeout expires.
     */
    this.legendItemClickTimeout = window.setTimeout(
      () => {
        // Single click case
        this.handleLegendItemSingleClick(data);
        this.legendItemClickTimeout = null;
      },
      this.plotElement._context.doubleClickDelay, // Use plotly's configured double click delay
    );
  };

  /**
   * Handler for plotly_legenddoubleclick.
   */
  private onLegendItemDoubleClick = (data: PlotlyLegendClick): void => {
    // Cancel single click timeout if any
    window.clearTimeout(this.legendItemClickTimeout);
    this.legendItemClickTimeout = null;

    this.handleLegendItemDoubleClick(data);
  };

  /**
   * Handler for a single click on a legend item.
   */
  private handleLegendItemSingleClick(data): void {
    const tracesToShow: number[] = [];
    // Build the tracesToShow array: keep visible traces visible, except for the clicked one (which needs to be hidden).
    this.traces.forEach((trace, index) => {
      const traceIsVisible = trace.visible === undefined || trace.visible === true;
      const isClickedTrace = index === data.curveNumber;
      if (isClickedTrace !== traceIsVisible) {
        tracesToShow.push(index);
      }
    });

    this.fixXAxisTicks(tracesToShow);
  }

  /**
   * Handler for a double click on a legend item.
   */
  private handleLegendItemDoubleClick(data: PlotlyLegendClick): void {
    const isClickedTraceVisible = this.traces[data.curveNumber].visible === undefined
      || this.traces[data.curveNumber].visible === true;
    const areAllOtherTracesHidden = this.traces
      .filter((trace, index) => index !== data.curveNumber)
      .every(trace => trace.visible === false || trace.visible === 'legendonly');

    /**
     * There are 3 cases when double-clicking on a legend item:
     * - clicked trace is visible and all other traces are hidden     -> show all traces
     * - clicked trace is visible and not all other traces are hidden -> show only clicked trace
     * - clicked trace is not visible                                 -> show all traces
     * These 3 cases can be factored in only 2 cases:
     */
    const tracesToShow = isClickedTraceVisible && !areAllOtherTracesHidden
      ? [data.curveNumber]
      : Array.from(this.traces.keys());

    this.fixXAxisTicks(tracesToShow);
  }
}
