<ng-container>
  <div mat-dialog-title>
    <h1>Hello !</h1>
  </div>
  @if (loaded()) {
    <mat-dialog-content>
      <p>In order to improve your Spinergie experience, could you please select your main activity below ?</p>
      <pearl-form-field label="Job category">
        <mat-select [formControl]="jobForm">
          <mat-option [value]="DEFAULT_JOB_CATEGORY_VALUE">-</mat-option>
          @for (jobCategory of jobCategories() | keyvalue; track jobCategory.key) {
            <mat-option [value]="+jobCategory.key">
              {{ jobCategory.value }}
            </mat-option>
          }
        </mat-select>
      </pearl-form-field>
    </mat-dialog-content>
  }
  <mat-dialog-actions>
    <pearl-button
      (click)="save()"
      [mat-dialog-close]="jobForm.value === 0 ? OTHER_JOB_CATEGORY_VALUE : jobCategories()?.[jobForm.value]">
      Save
    </pearl-button>
    <pearl-button
      (click)="save(true)"
      mode="warning"
      buttonType="alternative"
      [mat-dialog-close]="OTHER_JOB_CATEGORY_VALUE">
      Skip
    </pearl-button>
  </mat-dialog-actions>
</ng-container>
