<ng-container [ngSwitch]="parentField.style">
  <div class="filesUploader" *ngIf="this.parentField.compactFileUploader">
    <div class="upload-area" *ngIf="!isStandaloneFile()">
      <div
        ng2FileDrop
        *ngIf="editMode && !uploadAreaHidden()"
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver, disabled: !canUploadFile() }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        (onFileDrop)="uploadAllFiles()"
        (click)="fileInput.click()"
        class="well spin-drop-zone compact-spin-drop-zone">
        <pearl-icon icon="upload" [size]="24" />
        <span>Drag and Drop {{ parentField.title }}</span>
      </div>
      <pearl-button
        (click)="fileInput.click()"
        [disabled]="!canUploadFile()"
        type="icon"
        [spinTooltip]="getAttachedFilesTooltip()"
        icon="attach_file"
        class="compact-icon-button upload-file-button" />
      <input
        [accept]="allowedFileExtensionsList"
        [id]="'file-input-' + parentField.id"
        #fileInput
        [disabled]="!canUploadFile()"
        hidden
        class="select-file"
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        (onFileSelected)="uploadAllFiles()"
        [multiple]="multi"
        autocomplete="off" />
      <span *ngIf="parentField?.allowedFileExtensions?.length">Accepted Files: {{ allowedFileExtensionsList }}</span>
      <span>(Max size: {{ maxFileSize | displaySize }})</span>
    </div>

    <div class="compact-uploaded-file-collection" *ngIf="files?.data.length">
      <div
        class="compact-uploaded-file"
        [ngClass]="{ 'standalone-file': isStandaloneFile(), disabled: !(isFileActionPossible$ | async) }"
        *ngFor="let file of files.data; let fileIndex = index"
        [id]="'file-' + parentField.id + '-' + fileIndex">
        <div class="compact-field-text">
          <div class="standalone-file-placeholder" *ngIf="isStandaloneFile()">{{ parentField.title }}</div>
          <a class="compact-file-link" href="{{ file.s3Link }}" target="_blank" download>{{ getCompactTitle(file) }}</a>
        </div>
        <div>
          <pearl-button
            size="small"
            type="icon"
            icon="close"
            *ngIf="editMode && !file.removeMode"
            [disabled]="!(isFileActionPossible$ | async)"
            matTooltip="Remove file"
            (click)="enterRemoveMode(file)" />

          <ng-container *ngIf="!file.tempName && file.removeMode">
            <pearl-button
              size="small"
              type="icon"
              *ngIf="editMode"
              [disabled]="!(isFileActionPossible$ | async)"
              matTooltip="Remove"
              icon="delete"
              (click)="removeFile(file)" />
            <pearl-button
              type="icon"
              size="small"
              icon="cancel"
              *ngIf="editMode"
              matTooltip="Cancel remove"
              [disabled]="!(isFileActionPossible$ | async)"
              (click)="quitRemoveMode(file)" />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="fullscreen" *ngIf="isUploading">
      <div class="loading-indicator">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>

  <div class="filesUploader" *ngIf="!this.parentField.compactFileUploader">
    <div class="title">
      {{ fieldTitle ? fieldTitle + " - " : "" }}
      {{
        files.data.length > 0
          ? "Attached files: " + files.data.length + (files.data.length > 1 ? " files" : " file")
          : "No file attached"
      }}
    </div>

    <table mat-table [dataSource]="files" class="mat-elevation-z0" *ngIf="files.data.length">
      <!-- file name  -->
      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let file">
          <ng-container *ngIf="!file.tempName; else editName">
            <a href="{{ file.s3Link }}" target="_blank" download> {{ file.name | shortenUrl }}</a>
          </ng-container>
          <ng-template #editName>
            <pearl-form-field label="File name" [small]="true">
              <textarea
                #textArea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                (change)="changeName(file, $any($event.target).value)"
                >{{ file.name }}</textarea
              >
            </pearl-form-field>
          </ng-template>
        </td>
      </ng-container>

      <!-- size Column -->
      <ng-container matColumnDef="fileSize">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let file">{{ displayFileSize(file.size) }}</td>
      </ng-container>

      <!-- upload time Column -->
      <ng-container matColumnDef="uploadDate">
        <th mat-header-cell *matHeaderCellDef>Upload Date</th>
        <td mat-cell *matCellDef="let file">{{ file.uploadDate }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="fileAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let file" class="actionButtons">
          <ng-container *ngIf="!file.tempName && !file.removeMode">
            <a href="{{ file.s3Link }}" target="_blank" download>
              <pearl-button type="icon" icon="download" *ngIf="editMode" matTooltip="Download file" />
            </a>
            <pearl-button
              type="icon"
              icon="edit"
              *ngIf="editMode"
              matTooltip="Edit name"
              (click)="editFileName(file)"
              [disabled]="!(isFileActionPossible$ | async)" />

            <pearl-button
              icon="close"
              mode="warning"
              type="icon"
              *ngIf="editMode"
              matTooltip="Remove file"
              (click)="enterRemoveMode(file)"
              [disabled]="!(isFileActionPossible$ | async)" />
          </ng-container>
          <ng-container *ngIf="file.tempName && !file.removeMode">
            <pearl-button
              type="icon"
              icon="save"
              *ngIf="editMode"
              matTooltip="Save name change"
              (click)="saveFileName(file)"
              [disabled]="!(isFileActionPossible$ | async)" />

            <pearl-button
              icon="cancel"
              type="icon"
              *ngIf="editMode"
              matTooltip="Cancel name change"
              (click)="cancelFileName(file)"
              [disabled]="!(isFileActionPossible$ | async)" />
          </ng-container>
          <ng-container *ngIf="!file.tempName && file.removeMode">
            <pearl-button
              type="alternative"
              mode="warning"
              icon="delete"
              *ngIf="editMode"
              matTooltip="Remove"
              (click)="removeFile(file)"
              class="deleteButton"
              [disabled]="!(isFileActionPossible$ | async)">
              Yes
            </pearl-button>
            <pearl-button
              type="icon"
              icon="cancel"
              *ngIf="editMode"
              matTooltip="Cancel remove"
              (click)="quitRemoveMode(file)"
              [disabled]="!(isFileActionPossible$ | async)" />
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div
      ng2FileDrop
      *ngIf="editMode"
      [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
      (onFileDrop)="uploadAllFiles()"
      class="well spin-drop-zone">
      <pearl-icon icon="upload" [size]="24" />
      <h4>Drag and drop a file</h4>
      or
      <input
        [accept]="allowedFileExtensionsList"
        [disabled]="!canUploadFile()"
        class="select-file"
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        (onFileSelected)="uploadAllFiles()"
        [multiple]="multi"
        autocomplete="off" />
      <span *ngIf="parentField?.allowedFileExtensions?.length">Accepted Files: {{ allowedFileExtensionsList }}</span>
      <span>(Max size: {{ maxFileSize | displaySize }})</span>
    </div>
    <div class="fullscreen" *ngIf="isUploading">
      <div class="loading-indicator">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</ng-container>
