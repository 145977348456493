import dayjs from 'dayjs';
import { has, isNumber, orderBy, sortBy } from 'lodash-es';

import { DateTimezone } from '../helpers/types';
import { DateHelper } from '../helpers/date-helper';
import { ChartValues, SelectableGroupBy, SeriesCommonTypeValues, xAxisType } from './chart-types';
import { ChartingHelpers } from './charting-helpers';
import { Ordering } from '../helpers/ordering';
import { ChartTimeManager } from './chart-time-manager';

export class ChartOrdering extends Ordering {
  public static orderBars(
    data: ChartValues[],
    selectedGroup: SelectableGroupBy,
    seriesType: SeriesCommonTypeValues,
    timezone: DateTimezone,
    xAxisType: xAxisType,
  ): ChartValues[] {
    let timeGrouping;

    // Group by can be either a time group (hour, day, etc.) or a none group by with a sampling duration (e.g 3hours)
    if (ChartingHelpers.isTimeGrouping(selectedGroup) || (!selectedGroup.value && selectedGroup.samplingDuration)) {
      timeGrouping = true;

      let hasNonNumericalValues = false;
      const nonNumerical = {};

      /*
       * sort the values, leave non-numeric values to the end
       * put them into separate structure
       */
      data.sort((a, b) => {
        const aX = a.x;
        const bX = b.x;
        const isNumA = isNumber(aX);
        const isNumB = isNumber(bX);

        if (isNumA && isNumB) {
          return aX - bX;
        } else if (!isNumA && isNumB) {
          hasNonNumericalValues = true;
          nonNumerical[aX] = a;
          return 1;
        } else if (isNumA && !isNumB) {
          hasNonNumericalValues = true;
          nonNumerical[bX] = b;
          return -1;
        } else if (!isNumA && !isNumB) {
          hasNonNumericalValues = true;
          nonNumerical[aX] = a;
          nonNumerical[bX] = b;
          return aX.localeCompare(bX);
        }
      });

      /*
       * since sort is called when there is >= 2 elements we may miss a non numerical elements
       * when it is alone, this is the reason for this condition
       */
      if (data.length === 1 && !isNumber(data[0].x)) {
        hasNonNumericalValues = true;
        nonNumerical[data[0].x] = data[0];
      }

      /*
       * if we had detected any non-numerical values leave them out;
       * because we have kept track of them during the sort we can do it without iterating
       */
      if (hasNonNumericalValues) {
        const numberOfNonNumericValues = Object.keys(nonNumerical).length;
        data.splice(data.length - numberOfNonNumericValues, numberOfNonNumericValues);
      }

      /*
       * if this is a time chart but without grouping we will just want to return the data as it is
       * we do not attempt to fit the "holes" in data as there is not grouping, just raw timestamps
       */
      if (selectedGroup.value === 'none') {
        if (timezone !== 'local') {
          data.forEach(d => ChartTimeManager.enforceLocalTimezoneOnChartValues(d, timezone));
        }
        return data;
      }

      let dataAndZeroes: ChartValues[];
      if (hasNonNumericalValues && selectedGroup.value && seriesType !== 'scatter') {
        const timeUnit = selectedGroup.samplingUnit
          ? selectedGroup.samplingUnit
          : ChartingHelpers.timeCoherence(selectedGroup.value) as dayjs.ManipulateType;

        /*
         * in case of temporal grouping how many periods should be added on each step
         * eg 3 hours
         */
        const timePeriods = selectedGroup.samplingDuration ? selectedGroup.samplingDuration : 1;
        dataAndZeroes = ChartOrdering.fillEmptyValues(timezone, data, timePeriods, timeUnit);
      } else {
        if (timezone !== 'local') {
          data.forEach(d => ChartTimeManager.enforceLocalTimezoneOnChartValues(d, timezone));
        }
        dataAndZeroes = data;
      }

      /*
       * In some time grouping graph we also have non date category
       * In this case we will directly transform the numeric date in formatted date before plotting a category graph
       * This is a little bit annoying but this is the only way to plot date + additional bars
       */
      if (timeGrouping && xAxisType === 'mixed') {
        dataAndZeroes = dataAndZeroes.map(d => {
          d.x = ChartingHelpers.renderTimeToGroupByLevel(dayjs(d.x), selectedGroup);
          return d;
        });
      }

      // we add back the non-numerical values to the system
      return dataAndZeroes.concat(Object.values(nonNumerical));
    } // Modulo periods
    else if (has(DateHelper.defaultFormat, selectedGroup.value)) {
      let moduloTimeSortFunction = d => d;

      // sort modulo periods differently depending on format
      switch (selectedGroup.value) {
        case 'dayhour':
        case 'monthday':
          moduloTimeSortFunction = d => parseInt(d);
          break;
        case 'weekday':
          const weekdays = DateHelper.weekdays();
          moduloTimeSortFunction = d => weekdays.indexOf(d);
          break;
        case 'yearmonth':
          moduloTimeSortFunction = d => dayjs.months().indexOf(d);
          break;
      }

      return sortBy(data, d => moduloTimeSortFunction(d.x));
    } else if (selectedGroup.orderBy) {
      const groupOrderBy = selectedGroup.orderBy;
      if (groupOrderBy.fixedOrder) {
        return data.sort((a, b) => this.fixedOrder(a.x as string, b.x as string, groupOrderBy.fixedOrder));
      } else if (groupOrderBy.order && !groupOrderBy.value) {
        /*
         * If order is specified in the group, we order again here
         * We don't do this by default because we could have numeric xaxis to be ordered by total (no use case so far)
         */
        data.sort((a, b) => ChartingHelpers.numOrStringCompare(a.x, b.x, groupOrderBy.order));
      } else if (groupOrderBy.value) {
        /*
         * If the selected group has a specific orderBy in the config,
         * than each data point is expected to have a value for this field name and it will be used for ordering
         * If an orderType has been specified we use it otherwise by default the order is asc
         */
        return orderBy(data, d => d.__orderValue, [groupOrderBy.order ?? 'asc']);
      }
    } // Bars specific sort (sorts by the total)
    else if (seriesType === 'bar') {
      data.sort((a, b) => {
        // Tail values
        if (a.__tail) return 1;
        if (b.__tail) return -1;

        const totals = [0, 0];

        // convert arguments to Array
        [a, b].forEach((argument, i) => {
          /*
           * go over all subs inside the group and sum the values
           * to get the total of the group
           */
          for (const attr in argument) {
            /*
             * attr starting with __ like corresponding to internal attr use to determine error bars, order values etc..
             * And shouldn't be taken in account
             */
            if (attr === 'x' || attr === 'vessel_list' || attr.startsWith('__')) {
              continue;
            }

            totals[i] += argument[attr];
          }
        });

        if (totals[0] > totals[1]) {
          return -1;
        }

        if (totals[0] < totals[1]) {
          return 1;
        }

        return 0;
      });
      return data;
    } /*
     * We have the very special case 'none' which must have its value reduced but can not go through
     * the loop that enforce a time space between its values
     */
    else if (ChartingHelpers.isTimeInterval(selectedGroup) && timezone !== 'local') {
      return data.map((d: ChartValues): ChartValues => {
        const chartValues = { ...d };
        ChartTimeManager.enforceLocalTimezoneOnChartValues(chartValues, timezone);

        return chartValues;
      });
    }
    return data;
  }

  private static fillEmptyValues(
    timezone: DateTimezone,
    data: ChartValues[],
    timePeriods: number,
    timeUnit: dayjs.ManipulateType,
  ) {
    // No data
    if (!data.length) {
      return [];
    }

    // Show empty time periods
    const dataAndZeroes = [];

    /*
     * Note unlike MomentJS with DayJs we cannot create the duration and then add it on each step
     * because the result would be different
     * val.add(1, 'month') Is not the same as
     */

    /*
     * const duration = dayjs.duration(timePeriods, timeUnit)
     * monthDuration = dayjs.duration(1, 'month')
     * val.add(monthDuration)
     */

    // If chart is in local time are using dayjs(ts) to create the dates from local time to fill the holes
    let ts = data[0].x as number;
    const lastTs = data[data.length - 1].x as number;

    if (timezone !== 'utc' && timezone !== 'UTC' && timezone !== 'none' && timezone !== 'dprDay') {
      for (let i = 0; ts <= lastTs;) {
        const currentTs = data[i].x as number;
        if (Math.abs(ts - currentTs) < 2) {
          // comparing irregular duration ±1ms
          dataAndZeroes.push(data[i]);
          i++; // advance alongwith actual data
        } else {
          dataAndZeroes.push({ x: ts });
        }

        const tzOffset = timezone === 'local' ? dayjs(ts).utcOffset() : dayjs(ts).tz(timezone).utcOffset();
        ts = dayjs(ts).add(timePeriods, timeUnit).valueOf();

        /*
         * For local charts we have an issue with filling the empty data in case of daylight saving:
         * Our grouping method groups in UTC or in local time, here we are in the IF that handles local charts
         * If we request 6h grouping we will get the following data: (the local value is the important)
         * --------------------------
         *  UTC     	  |	Local
         * --------------------------
         * 30/10 22:00  | 31/10 00:00
         * 31/10 05:00	| 31/10 06:00
         * 31/10 11:00  | 31/10 12:00
         * ---------------------------
         * The data is grouped by 6 hours in the local time (right column)
         * The issue comes from the statement above, if we ask dayjs to add 6 hours to the local
         * time of 31/10 00:00 it will lose 1 hour:
         * ------------------------------------
         * 31/10 00:00 + 6 hours => 31/10 05:00
         * ------------------------------------
         * so the system won't find the data in the pre-defined buckets and insert zeros
         * the following statement fixes the issue by adjusting the timestamp created by the **add* statement
         */
        if (timeUnit == 'hour' || timeUnit == 'second' || timeUnit == 'h' || timeUnit == 's') {
          const nextTzOffset = timezone === 'local' ? dayjs(ts).utcOffset() : dayjs(ts).tz(timezone).utcOffset();
          if (nextTzOffset != tzOffset) {
            ts = dayjs(ts).add(tzOffset - nextTzOffset, 'minute').valueOf();
          }
        }
      }
    } // If chart is in UTC, we will creates dates in UTC while filling the holes
    else {
      for (let i = 0; ts <= lastTs;) {
        const currentTs = data[i].x as number;
        if (Math.abs(ts - currentTs) < 2) {
          // comparing irregular duration ±1ms
          const currentData = { ...data[i] };
          ChartTimeManager.enforceLocalTimezoneOnChartValues(currentData);
          dataAndZeroes.push(currentData);
          i++; // advance along with actual data
        } else {
          const currentData = { x: ts };
          ChartTimeManager.enforceLocalTimezoneOnChartValues(currentData);
          dataAndZeroes.push(currentData);
        }
        ts = dayjs.utc(ts).add(timePeriods, timeUnit).valueOf();
      }
    }

    return dataAndZeroes;
  }
}
