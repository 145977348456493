import dayjs from 'dayjs';

import { OptionValue, ProjectEntity, ValidityDateEntity } from '../../helpers/types';
import { DataAccessor } from '../../database/data-accessor';
import { FileObject } from '../../database/file-uploader';
import { ReportingField } from './reporting-config-types';
import { PearlIcon } from '../../shared/pearl-components/components/icons/icons';

export type PrimaryActionType = 'sign' | 'open' | 'none';

export interface ReportMetadata {
  id: number;
  date: string | null;
  datetime: string | null;
  dateOrDatetime: string; // coalesce from date or datetime, cannot be null
  title: string;
  documentTypeId: number;
  isDaily: boolean;
  project: string;
  projectId: number;
  vesselId?: number;
  statusId?: ReportStatus;
  lastUpload?: string;
  lastUpdate?: string;
  modifiedBy?: string;
  spinLink?: string;
  version?: number;
  isPlaceholder?: boolean;
  deleted?: boolean;
  clientDiscard?: boolean;
  isAnonymized?: boolean;
  isRealReport?: boolean;
  sourceId?: DocumentSource;
  approvers: Approver[];
  voyageId?: number;
  isCreatedOffline?: boolean;
  isModifiedOffline?: boolean;
  isLoadedOffline?: boolean;
  isReportFullyInitialized?: boolean;
}

export interface DisplayedReportMetadata extends ReportMetadata {
  primaryAction: PrimaryActionType;
  documentType: string;
  voyage: string;
  formattedLastUpdate: string;
  formattedStatus: string;
  formattedDateOrDatetime: string;
}

export interface ReportMetadataForSyncField {
  reportDatetimeStart: number;
  reportDatetimeEnd: number;
}

export interface AisTracking extends ValidityDateEntity {
  id: number;
  vesselId: number;
  title: string;
  vesselTitle: string;
  vesselTypeCode?: string;
}

export interface Poi extends ProjectEntity {
  id: number;
  code: string;
  latitude: number;
  longitude: number;
  parentAoiId?: number;
  title: string;
  poiTypeCode: string;
  parentAoiCode: string;
}

type VesselType = {
  id: number;
  title: string;
  code?: string;
};

export interface DocumentType {
  id: number;
  title: string;
  code?: string;
}

export interface Workphase extends ProjectEntity {
  id: number;
  title: string;
  code: string;
  order?: number;
}

export interface ReportingVoyage extends ProjectEntity, ValidityDateEntity {
  id: number;
  title: string;
  vesselId: number;
  isCompleted: boolean;
  datetimeStart: string;
  datetimeEnd?: string;
}

export interface Port extends ProjectEntity {
  id: number;
  title: string;
  latitude: number;
  longitude: number;
  unLocode: string;
}

/**
 * Ref data is data that is shared by all reports and vessels.
 * This data is mainly used to build the entitiesData object in the SelectedReportConfig
 */
export interface ReportingRefData {
  aisTracking: AisTracking[];
  vessel: Vessel[];
  poi: Poi[];
  aoi: OptionValue[];
  vesselType: VesselType[];
  capacityStatus: LiquidCapacityStatus[];
  equipmentType: OptionValue[];
  unit: LiquidUnit[];
  physicalQuantity: PhysicalQuantity[];
  liquidType: LiquidType[];
  garbageCategory: OptionValue[];
  project: OptionValue[];
  documentType: DocumentType[];
  workphase: Workphase[];
  voyage: ReportingVoyage[];
  port: Port[];
}

export type ReportingRefDataKey = keyof ReportingRefData;

export type ReportingRefDataType =
  | AisTracking
  | Vessel
  | Poi
  | OptionValue
  | VesselType
  | LiquidCapacityStatus
  | LiquidUnit
  | PhysicalQuantity
  | LiquidType
  | Workphase
  | ReportingVoyage;

export interface ReportingConfigVessel {
  id: number;
  title: string;
  typeId: number;
  highFuelConsumption: number;
  vesselImageUri: string;
  isReportingActivated: boolean;
}

export interface LiquidType extends ValidityDateEntity {
  id: number;
  title: string;
  defaultUnitId: number;
  defaultDensity: number;
  defaultSulphurContent: number;
  defaultCfFactor: number;
  fuel?: boolean;
  canBeProduced?: boolean;
  order?: number;
}

// aligned with copy_reference_data
export enum PhysicalQuantityEnum {
  VOLUME = 1,
  WEIGHT = 2,
}

export interface PhysicalQuantity {
  id: number;
  title: string;
}

export interface LiquidUnit {
  id: number;
  title: string;
  physicalQuantityId: number;
  factorDefault: number;
  physicalQuantityDefaultUnit: boolean;
}

export interface Vessel {
  id: number;
  title: string;
  vesselTypeId: number;
  vesselTypeCode?: string;
}

export interface CheckboxWithRequiredComment {
  value: boolean;
  comment: string;
}

export interface DatetimeWithTimezoneValue {
  timezone: string;
  datetimeUtc: string;
}

export type FieldDataValue =
  | string
  | number
  | boolean
  | CheckboxWithRequiredComment
  | DatetimeWithTimezoneValue
  | number[]
  | null;

export interface Approver {
  userEmail: string;
  userFullName: string;
  signatureComment: string;
  signatureCompany: string;
  signaturePosition: string;
  signatureFullName: string;
  signatureSignedAt: string | null;
}

/**
 * Represent the state of the report, all defined properties here will be sent to backend,
 */
export interface ReportState extends ReportMetadata {
  dailyReportId: number;
  lastSubmittedHash?: string;
  lastUploadHash: string;
  currentHash?: string; // only computed on the fly before upload
  fieldsData: FormState;
  activities: ActivityState[];
  flexibleTableTabStates: { [tabId: number]: ReportEntityState[] };
  liquidStates: LiquidState[];
  equipmentStates: EquipmentState[];
  garbageDisposals: GarbageDisposalState[];
  attachedFiles: FileObject[];
  voyageId?: number;
  /*
   * Part of the post-processing of the report only has to be done once. Once the first post process has been
   * completed, this boolean can be set to true.
   */
  isReportFullyInitialized?: boolean;
}

export type ReportDto = Omit<
  ReportState,
  | 'title'
  | 'modifiedBy'
  | 'lastUpload'
  | 'lastUpdate'
  | 'statusId'
  | 'lastSubmittedHash'
  | 'version'
  | 'lastUploadHash'
  | 'currentHash'
  | 'approvers'
  | 'isReportFullyInitialized'
>;

interface FormState {
  [fieldId: `${number}`]: FieldDataValue;
}

export interface ActivityState {
  start: string;
  end: string;
  activityTypeId?: number;
  activityId: number | null;
  activityTab: ActivityTabCode;
  equipmentId?: number;
  // This is only used for backend DTO, soon to be removed when fieldsData is removed from backend
  fieldsData?: { [fieldId: `${number}`]: FieldDataValue };
  [fieldId: `${number}`]: FieldDataValue;
  interruptMissingStart?: boolean;
  interruptMissingEnd?: boolean;
  // In case of activity generated from AIS activity, we store corresponding status id
  autofillActivityStatusId?: number;
}

export interface GarbageDisposalState {
  datetime: string;
  category: number;
  destinationType: number;
  destination: string;
  unit: number;
  quantity: number;
  latitude: number;
  longitude: number;
  comment: string;
  attachedFiles: number[];
}

export enum DocumentSource {
  SPINERGIE = 1,
}

export interface ReportEntityState {
  rowUuid?: string;
  fieldsMetadata?: { [fieldId: string]: any };
  order?: number;
}

export interface ReportEntityExtendedState extends ReportEntityState {
  __isValid?: boolean;
  __coherencyErrors?: { [fieldId: string]: string };
  __coherencyWarnings?: { [fieldId: string]: string };
  __dataAccessor?: DataAccessor;
}

export interface ActivityExtendedState extends ActivityState, ReportEntityExtendedState {
  __description?: string;
  __visibleFields?: { [fieldId: string]: boolean };
  __requiredFields?: { [fieldId: string]: boolean };
  __restrictedFields?: { [fieldId: string]: boolean };
  __warningMessage?: string;
  __interruptionInfo?: string;
  __aisActivityStatus?: AutofillActivityStatus;
  // true if activity has been autofill but was never selected by the user
  __suggestedActivityTypeTitle?: string;
  __autofillToCheck?: boolean;
  __suggestedActivityTypeId?: number;
  __suggestedActivityTypeProba?: number;
}

export interface GarbageDisposalExtendedState extends GarbageDisposalState, ReportEntityExtendedState {
}

export interface EquipmentState {
  equipmentId: number;
  initialState: number;
  finalState: number;
  vesselId: number;
  id: number;
}

export interface RobEditableFields {
  unit: boolean;
  liquidType: boolean;
  initial: boolean;
  cfFactor: boolean;
  sulphurContent: boolean;
  density: boolean;
}

export interface LiquidState {
  liquidCapacityId: number;
  liquidTypeId: number;
  vesselId: number;
  internallyDischarged: number;
  internallyLoaded: number;
  comment: string;
  density: number;
  sulphurContent?: number;
  cfFactor?: number;
  unitId: number;
  id: number;
  initialRob: number;
  stock: number;
  consumption: number;
  discharged: number;
  loaded: number;
  produced: number;
  liquidStatusId: number;
}

export interface EquipmentExtendedState extends EquipmentState, ReportEntityExtendedState {
  __editable?: boolean;
  __isInitialColumnEditable?: boolean;
}

export interface LiquidExtendedState extends LiquidState, ReportEntityExtendedState {
  __editableFields?: RobEditableFields;
  __isFixedLiquidType?: boolean;
  __isInitialColumnEditable?: boolean;
}

/**
 * Represent extended state of the report, which is the report state + properties that are useful only for
 * frontend processing, all properties that begin with __ will be not send to backend
 */
export interface ReportExtendedState extends ReportState {
  equipmentStates: EquipmentExtendedState[];
  liquidStates: LiquidExtendedState[];
  activities: ActivityExtendedState[];
  garbageDisposals: GarbageDisposalExtendedState[];
  flexibleTableTabStates: { [tabId: number]: ReportEntityExtendedState[] };
  fieldsData: FormExtendedState;
}

export interface FormExtendedState extends FormState {
  __dataAccessor?: DataAccessor;
  fieldsMetadata?: { [fieldId: `${number}`]: any };
}

export interface ReportStateInfo {
  title: string;
  lastSubmittedHash: string;
  modifiedBy: string;
  lastUpload: string;
  statusId: number;
  currentHash?: string;
  lastUploadHash?: string;
  version: number;
  createdOffline?: boolean;
  uploadedId?: number;
  isReportFullyInitialized?: boolean;
}

export interface LiquidCapacityStatus {
  id: number;
  title: string;
  capacityDefault: boolean;
  inUse: boolean;
}

export enum ReportStatus {
  IN_PROGRESS = 1,
  READY_TO_SUBMIT = 2,
  SUBMITTED = 3,
  MODIFIED_READY_TO_SUBMIT = 4,
  MODIFIED_WITH_ERRORS = 5,
  SIGNATURE_REQUESTED = 6,
}

export enum ReportStatusTitle {
  'In progress' = 1,
  'Ready to submit' = 2,
  'Submitted' = 3,
  'Modified - Ready to submit' = 4,
  'Modified - With errors' = 5,
  'Signature requested' = 6,
}

export interface ReportingUser extends OptionValue {
  status?: boolean;
}

export interface VesselLastReport {
  lastReportDateOrDatetime: number;
  lastProject: string;
  lastReportStatus: ReportStatus;
  lastReportType: string;
  lastReportIsDaily: boolean;
}

export interface ReportingVessel extends VesselLastReport {
  vesselId: number;
  vesselTitle: string;
  vesselType: string;
  isReportingActivated: boolean;
  vesselTypeCode: string;
  vesselImageURI: string;
}

export interface ActivityMode {
  id: number;
  title: string;
  code: ActivityTabCode;
  equipmentId?: number;
  equipmentTypeId?: number;
  fullDayReporting?: boolean;
  allowActivitiesOverlap?: boolean;
}

export enum ActivityTabCode {
  MAIN = 'main',
  SIMULTANEOUS = 'simultaneous',
  EQUIPMENT = 'equipment',
}

export type ReportSort = 'date-des' | 'date-asc';

export interface ReportUploadResult {
  success: boolean;
  reportDistributedTo: string[] | null;
  hasConflict: boolean;
  uploadedReport: ReportState;
}

export interface ReportUploadQuery {
  DailyReport: ReportDto;
  ReportStateInfo: ReportStateInfo;
  forceUpload?: boolean;
}

export interface ReportEntityStateUpdate {
  entityState: ReportEntityExtendedState;
  field?: ReportingField;
  newFieldValue?: FieldDataValue;
  hasValueChanged?: boolean;
}

export interface InterruptionIcon {
  icon: string;
  tooltip: string;
}

export enum DprEndpoints {
  REPORT_DETAILS = '/spindjango/reporting/report-details',
  FULL_VESSEL_CONFIG = '/spindjango/reporting/config/vessel-config',
  UPLOAD_REPORT = '/spindjango/upload-report',
  DELETE_REPORT = '/spindjango/reporting/delete-report',
  DOWNLOAD_REPORTS = '/spindjango/download-reports',
  REPORT_LAST_VERSION = '/spindjango/reporting/report-last-version',
  DPR_REF_DATA = '/spindjango/dpr-ref-data',
  DPR_VESSELS = '/osv/dpr-vessels',
  VESSEL_REPORTS = '/osv/vessel-reports',
  LIST_ALLOWED_APPROVERS = '/spindjango/reporting/signature/allowed-approvers',
  REQUEST_SIGNATURE = '/spindjango/reporting/signature/request',
  SIGN_REPORT = '/spindjango/reporting/signature/sign',
  GET_PDF_REPORT = '/spindjango/reporting/signature/pdf-report',
  CHECK_FULL_CONFIG = '/spindjango/reporting/config/check-full-config',
  POST_PROCESS_ALL_VOYAGES = '/spindjango/reporting/postprocess-voyages',
}

export type DownloadReportFileFormat = 'xlsx' | 'pdf';

export enum SavingIndicatorState {
  SAVING = 'SAVING',
  SUBMITTING = 'SUBMITTING',
  SAVED = 'SAVED',
  SAVED_OFFLINE = 'SAVED_OFFLINE',
}

export enum ActivityValidityStatus {
  ERROR = 'ERROR',
  REVIEW = 'REVIEW',
  VALID = 'VALID',
}

export enum AutofillActivityStatus {
  Addition = 'ADDITION',
  Modification = 'MODIFICATION',
}

/**
 * The sync field override can behave in 3 ways:
 * - NONE: Set the value only if no value was previously set and autoUpdateDisabled false
 * - SOFT_FORCE: Set the value only if sync field value is not null and autoUpdateDisabled false
 * - HARD_FORCE:  Always set the value
 */
export enum SyncFieldOverrideBehaviour {
  SOFT_FORCE = 'SOFT FORCE',
  HARD_FORCE = 'HARD FORCE',
  NONE = 'NONE',
}

export interface AutofillActivityExtendedState extends ActivityExtendedState {
  autofillStatus: AutofillActivityStatus;
}

export class StatusDisplay {
  static readonly VALID = new StatusDisplay('VALID', 'valid-color', 'checked');
  static readonly WARNING = new StatusDisplay('WARNING', 'warning-color', 'warning');
  static readonly ERROR = new StatusDisplay('ERROR', 'error-color', 'error');
  static readonly REVIEW = new StatusDisplay('REVIEW', 'review-color', 'highlight');

  private constructor(
    private readonly key: string,
    public readonly elementClass: string,
    public readonly icon: PearlIcon,
  ) {
  }

  toString(): string {
    return this.key;
  }
}

export interface ReportCreationParameters {
  selectedDate: dayjs.Dayjs | null;
  selectedDatetime: dayjs.Dayjs | null;
  selectedProjectId: number;
  selectedDocumentTypeId: number;
}

export const M3_UNIT_ID = 1;

export type ReportingFieldType =
  | 'aisTracking'
  | 'aoi'
  | 'booleanDropdown'
  | 'checkbox'
  | 'checkbox-details-required'
  | 'choice'
  | 'composite'
  | 'coordinate'
  | 'date'
  | 'datetime'
  | 'datetimeWithTimezone'
  | 'duration'
  | 'files'
  | 'flexibleTableFixedRowsHeader'
  | 'garbageCategory'
  | 'number'
  | 'poi'
  | 'performedActivity'
  | 'port'
  | 'tank'
  | 'text'
  | 'time'
  | 'unit'
  | 'vessel'
  | 'vesselType'
  | 'voyage'
  | 'workphase';

export type ReportGroupType = 'date' | 'standalone' | 'voyage' | 'all-voyage-mode' | 'all';

export interface ReportGroup {
  key: string;
  type: ReportGroupType;
  reports: DisplayedReportMetadata[];
  dateStart?: string;
  dateEnd?: string;
  collapsed?: boolean;
  displayedFields?: string[];
}

export interface ReportingCoreTrackingInfo {
  isOffline: boolean;
}

export interface SelectedVesselTrackingInfo extends ReportingCoreTrackingInfo {
  vesselId: number;
  vessel: string;
  vesselType: string;
}

export interface SelectedReportTrackingInfo extends SelectedVesselTrackingInfo {
  reportId: number;
}
