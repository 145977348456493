import { Selection, BaseType as SelectionBaseType, select } from 'd3-selection';

import { reportStyle, scheduleTextWidth, shortenWithPoints, svgAttrTranslate } from '../helpers/d3-helpers';
import { Font, LegendContainerLayout, LegendEntryBoundingBoxes, LegendIcon, LegendLayout, Margin } from './chart-types';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';

type ComputedLayout = {
  // The mean value of the legendIcon width. Used inside matchPlotlyLegendStyle
  meanLegendIconWidth: number;
  // The mean value of the legendFont Size. Used inside matchPlotlyLegendStyle
  meanLegendFontHeight: number;
  // The total margin width to add to a textWidth to guess it's total width. Used inside getLegendBoundingWidthFromText
  textTotalMarginWidth: number;
  // Storing the total height size of the legendEntries. Find inside calcLegendEntryBoundingBoxes
  legendEntriesTotalHeight: number;
  // Storing the height of one legendEntryColumn. Used inside calcLegendEntryBoundingBoxes
  legendEntriesColumnHeight: number;
};

/**
 * LegendManager to build custom legends
 * Builds custom legends from plotly ones, wrapped inside a scrollable div.
 * In order to do it, we calculate custom legend entries' bounding box and place the custom legend container.
 * Then, we append the custom legends inside it and link them to plotly legend events.
 */
export class LegendManager {
  private legendEntryBoundingBoxes?: LegendEntryBoundingBoxes;
  private computedLayout: ComputedLayout;
  private customLegendContainer: Selection<SVGSVGElement, undefined, HTMLElement, unknown>;

  /**
   * @param chartDivId Id of the corresponding chart
   * @param containerLayout The layout of the legend container, used to place the customLegend <div>-<Svg> or the <g>
   *  Contain the position of the container, and the margin that will be applied the <svg> or the <g>
   * @param legendLayout The layout of the legends, containing the display options
   *  for the legends (ex: font, icon, etc...)
   * @param legendEntries map of key value. Map allows us to keep the order of the legends (contrary to objects)
   */
  constructor(
    private chartDivId: string,
    private containerLayout?: LegendContainerLayout,
    private legendLayout?: LegendLayout,
    protected legendEntries?: Map<string, string>,
  ) {
  }

  private computeLegendEntriesGenericLayout(): ComputedLayout {
    return {
      // -0.5 will align legendPoints to legendLine middle width for better display
      meanLegendIconWidth: Math.max(this.legendIcon.width / 2 - 0.5, 1),
      meanLegendFontHeight: Math.max(this.legendFont.size / 2 + 0.5, 1),
      textTotalMarginWidth: this.legendLayout.icon.width
        + this.legendLayout.margin.pad
        + this.legendLayout.margin.right
        + this.legendLayout.margin.left,
      legendEntriesTotalHeight: 0,
      legendEntriesColumnHeight: this.legendFont.size + this.legendMargin.top + this.legendMargin.bottom,
    };
  }

  // Getters

  public get containerHeight(): number {
    return this.containerLayout.boundingBox.height
      - this.containerLayout.margin.top
      - this.containerLayout.margin.bottom;
  }

  public get containerWidth(): number {
    return this.containerLayout.boundingBox.width
      - this.containerLayout.margin.left
      - this.containerLayout.margin.right;
  }

  public get legendEntriesTotalHeight(): number {
    return this.computedLayout?.legendEntriesTotalHeight ?? 0;
  }

  protected get legendMargin(): Margin {
    return this.legendLayout.margin;
  }

  protected get legendOrientation(): string {
    return this.legendLayout.orientation;
  }

  protected get legendFont(): Font {
    return this.legendLayout.font;
  }

  protected get legendIcon(): LegendIcon {
    return this.legendLayout.icon;
  }

  protected get maxStringLength(): number {
    return this.legendLayout.maxStringLength;
  }

  protected get d3ChartDimensions(): DOMRect {
    return document.getElementById(this.chartDivId).getBoundingClientRect();
  }

  protected getBiggestLegendString(): string {
    let biggestLegendString = '';
    for (const values of this.legendEntries.values()) {
      biggestLegendString = biggestLegendString.length < values.length ? values : biggestLegendString;
    }
    return biggestLegendString;
  }

  protected getShortenLegendText(text: string): string {
    return text.length > this.legendLayout.maxStringLength
      ? shortenWithPoints(text, this.legendLayout.maxStringLength)
      : text;
  }

  /**
   * calculate and return the legend rectangle Width
   * using legend layout icon size & margin
   *
   * @param text Text used for the width
   * @param toShorten Boolean to shorten the text of the corresponding maxStringLength, using getShortenLegendText
   * @returns the LegendRectWidth, Please consider that it can change a little bit depending on the css font-family
   */
  protected getLegendBoundingWidthFromText(text: string, toShorten = false): number {
    // using scheduleTextWidth because it's more precise to calc textWith
    return scheduleTextWidth(toShorten ? this.getShortenLegendText(text) : text, this.legendLayout.font.size)
      + this.computedLayout.textTotalMarginWidth;
  }

  /*
   * Return the plotly legend of the corresponding datum
   */
  private getPlotlyLegendEntry(datum: string): Selection<SVGGElement, unknown, SelectionBaseType, unknown> {
    try {
      // Using JSON.stringify in order to escape the text's special chars
      const legendText = JSON.stringify(this.legendEntries.get(datum));
      const selector = `#${this.chartDivId} .groups .traces:has(> .legendtext[data-unformatted=${legendText}])`;
      return select(document.querySelector<SVGGElement>(selector));
    } catch (e) {
      throw new ErrorWithFingerprint(`Original message: ${e}`, ['plotly-legend-entry-error']);
    }
  }

  public hidePlotlyLegends(plotlyLegend: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>): void {
    // hide plotly legend and disable pointer events
    plotlyLegend.style('opacity', 0);
    plotlyLegend.selectAll('.legendtoggle').style('pointer-events', 'none');
  }

  // Will style custom legend entries just like plotly ones
  private matchPlotlyLegendStyle(
    elem: SVGGElement,
    datum: string,
  ): void {
    const legendEntry = select(elem);
    const plotlyLegendEntry = this.getPlotlyLegendEntry(datum);

    if (plotlyLegendEntry.empty() || legendEntry.empty()) {
      return;
    }

    reportStyle(plotlyLegendEntry.select('.legendtext'), legendEntry.select('text'));
    // Updating font-size
    legendEntry.select('text').style('font-size', `${this.legendLayout.font.size}px`);
    // use plotly legend entry style for opacity
    reportStyle(plotlyLegendEntry, legendEntry);
    // restore cursor pointer
    legendEntry.style('cursor', 'pointer');

    // .layers is the DOM element containing plotly's points & lines icons
    const plotlyLegendIconLayers = plotlyLegendEntry.select('.layers');
    const plotlyLegendIconLayersClone = plotlyLegendIconLayers.clone(true);

    // Trick to append plotlyLegendIconLayersClone to legendEntry, only using .append(cloned) doesn't work
    legendEntry.append(() => plotlyLegendIconLayersClone.node());

    const legendPoints = legendEntry.select('.legendpoints').select('path');
    const legendLines = legendEntry.select('.legendlines').select('path');

    // meanLegendIconWidth because a x = 0 represent the middle of the icon
    legendPoints?.attr(
      'transform',
      svgAttrTranslate(this.computedLayout.meanLegendIconWidth, this.computedLayout.meanLegendFontHeight),
    );
    // Applying a x of -6 to copy plotlyLegends lines's icon placement
    legendLines?.attr('transform', svgAttrTranslate(-6, this.computedLayout.meanLegendFontHeight));
  }

  // Set the custom legend content using the corresponding datum
  protected setCustomLegendEntryContent(
    entry: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
    datum: string,
  ): void {
    // We limit the string chart length to specified max legend string length
    const textToPrint = this.getShortenLegendText(this.legendEntries.get(datum));

    entry.select('text')
      .attr('id', datum)
      // Margin to be next to icons
      .attr('x', this.legendLayout.margin.pad + this.legendLayout.icon.width)
      // Addition to be aligned to icons
      .attr('y', this.legendLayout.font.size - 1)
      .text(textToPrint);
  }

  /**
   * Placing every custom legends
   * Fill them using plotly ones
   * Linking plotly events to custom legends
   *
   * Using .join method, adding enter & update events for custom legends, allowing the app to create (enter) or
   * to update them when a data is new or updated
   *
   * @param boundingBoxesOverride Optional - Override the class legendEntryBoundingBoxes
   */
  public appendPlotlyLegendsToCustomLegendsContainer(
    customLegendsContainer: Selection<SVGSVGElement, unknown, SelectionBaseType, unknown>,
    boundingBoxesOverride: LegendEntryBoundingBoxes = undefined,
  ): void {
    // Keep the context of the function for use in .each calls inside join's enter and update
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const legendEntryBoundingBoxes = boundingBoxesOverride ?? this.legendEntryBoundingBoxes;

    customLegendsContainer.selectAll<SVGGElement, string>('g').data<string>(this.legendEntries.keys(), d => d)
      // Join allow to implement create (enter =>) and update (update =>) behavior for your legends
      .join(
        enter => {
          // For new enter data datum we append a new legend <g>
          const legend = enter.append('g');
          // Placing it
          legend.attr(
            'transform',
            (d: string) => svgAttrTranslate(legendEntryBoundingBoxes[d].x, legendEntryBoundingBoxes[d].y),
          );
          // Adding text placeholder
          legend.append('text');

          /*
           * Attach click handler to dispatch events to plotly
           * mouseup is the event that triggers tracing redraw
           */
          legend.each(function(d: string) {
            const entry = select(this);

            self.setCustomLegendEntryContent(entry, d);

            entry.on('click', () => {
              // Adding plotly click effect to legends
              const plotlyElem = self.getPlotlyLegendEntry(d);
              if (!plotlyElem) return;
              const plotlyToggle = plotlyElem.select('.legendtoggle');
              plotlyToggle.dispatch('mousedown');
              plotlyToggle.dispatch('mouseup');
            });
          });
          return legend;
        },
        update => {
          update.each(function(d: string) {
            const legendEntry = select(this);

            // Updating legends entry position
            legendEntry.attr(
              'transform',
              (d: string) => svgAttrTranslate(legendEntryBoundingBoxes[d].x, legendEntryBoundingBoxes[d].y),
            );
            return self.setCustomLegendEntryContent(select(this), d);
          });

          return update;
        },
        exit => exit.remove(),
      ).each(function(d: string) {
        self.matchPlotlyLegendStyle(this, d);
      });
  }

  private setCustomLegendWrapperStyle(
    customLegendWrapper: Selection<HTMLElement, unknown, SelectionBaseType, unknown>,
  ): void {
    customLegendWrapper.style('width', `${this.d3ChartDimensions.width}px`);
    customLegendWrapper.style('height', `${this.d3ChartDimensions.height}px`);
  }

  private setCustomLegendScrollBoxStyle(
    customLegendScrollBox: Selection<HTMLElement, unknown, SelectionBaseType, unknown>,
    d3Chart: Selection<HTMLElement, unknown, SelectionBaseType, unknown>,
  ): void {
    // We add half the margin.right in order to have a slightly shifted scrollbar that will not overlap biggest legends
    const widthWithSmallerMarginForScrollBar = this.containerWidth + (this.containerLayout.margin.right / 2);
    let height = this.containerHeight;

    if (this.containerLayout.verticalPosition === 'bottom') {
      const tracesLegendBottom = d3Chart.select<SVGElement>('.cartesianlayer').select<SVGGElement>('.subplot');

      // legends are placed well when there is a rangeslider
      if (d3Chart.select('.rangeslider-container').empty() && !tracesLegendBottom.empty()) {
        /**
         * The plotly traces' legends can be displayed horizontally, diagonally, & vertically.
         * Plotly also manage them as an outside element of the plot, which mean that they can overlap the plot margin.
         *
         * getting the size of the plot with the traces legends
         */
        const tracesLegendBottomHeight = tracesLegendBottom.node().getBoundingClientRect().height;
        /**
         * Calculating new height.
         * In case the height is to small, will be sized of the LegendFont Size
         */
        height = Math.max(this.d3ChartDimensions.height - tracesLegendBottomHeight, this.legendFont.size);
      }
    }

    customLegendScrollBox.style('width', `${widthWithSmallerMarginForScrollBar}px`);
    customLegendScrollBox.style('height', `${height}px`);
    customLegendScrollBox.style(
      'margin',
      `${this.containerLayout.margin.top}px 0 0 ${this.containerLayout.margin.left}px`,
    );
  }

  private setCustomLegendSvgAttr(customLegendSvg: Selection<SVGElement, unknown, SelectionBaseType, unknown>): void {
    customLegendSvg.attr('width', `${this.containerWidth}`)
      .attr('height', `${this.computedLayout.legendEntriesTotalHeight}`)
      .attr('transform', svgAttrTranslate(this.containerLayout.boundingBox.x, this.containerLayout.boundingBox.y));
  }

  // Initializing <svg> custom legends container inside scrollable div
  private initCustomSvgLegend(
    d3Chart: Selection<HTMLElement, undefined, HTMLElement, unknown>,
    existingLegend: Selection<SVGGElement, unknown, SelectionBaseType, unknown>,
  ): Selection<SVGSVGElement, undefined, HTMLElement, unknown> {
    this.hidePlotlyLegends(existingLegend);

    // Looking for existing custom legends
    let customLegendWrapper = d3Chart.select<HTMLElement>(':has(> .custom-legend-scroll-box)');
    let customLegendScrollBox = customLegendWrapper.select<HTMLElement>('.custom-legend-scroll-box');
    let customLegendSvg = customLegendScrollBox.select<SVGSVGElement>('svg');

    if (customLegendScrollBox.empty()) {
      // Performing custom legends initialization styling
      customLegendWrapper = d3Chart.select(':has(> .main-svg)')
        .insert('div')
        // Adding a div that will wrap and position the custom-legend-scroll-box on top or bottom
        .classed('custom-legend-wrapper', true)
        .attr('style', 'background: transparent; position: relative; pointer-events: none;');

      customLegendScrollBox = customLegendWrapper.insert('div').classed('custom-legend-scroll-box', true)
        .attr(
          'style',
          `
          position: absolute;
          overflow-x: hidden;
          overflow-y: auto;
          pointer-events: all;
          ${this.containerLayout.verticalPosition}: 0;
          `,
        );

      customLegendSvg = customLegendScrollBox.insert('svg', '.svg')
        .attr('style', 'background: transparent; position: absolute; pointer-events: all;');
    }

    // Performing generic styling both for initialization or update
    this.setCustomLegendWrapperStyle(customLegendWrapper);
    this.setCustomLegendScrollBoxStyle(customLegendScrollBox, d3Chart);
    this.setCustomLegendSvgAttr(customLegendSvg);

    // Remove plotly legends from visibility
    d3Chart.select('.legend').style('display', 'none');
    return customLegendSvg;
  }

  // Calculate the biggest boundingBoxWidth based on the biggest legend string
  protected calculateLegendEntryBoundingBoxWidth(): number {
    let biggestLegendString = this.getBiggestLegendString();
    // Cutting biggest legend string to maxStringLength chars
    if (biggestLegendString.length > this.maxStringLength) {
      biggestLegendString = shortenWithPoints(biggestLegendString, this.maxStringLength) + '   ';
    }
    return this.getLegendBoundingWidthFromText(biggestLegendString);
  }

  protected getLegendTotalWidth(): number {
    let totalWidth = 0;

    for (const legend of this.legendEntries.values()) {
      totalWidth += this.getLegendBoundingWidthFromText(legend, true);
    }
    return totalWidth;
  }

  /**
   * This method calculates, for each legend entry, its bounding box
   * inside the custom legend container (the svg).
   *
   * Can be implemented by children for custom behavior
   * @param widthOverride Optional - Replace the containerWidth by the specified widthOverride
   * @returns LegendEntryBoundingBoxes Containing the bounding boxes for each legend
   */
  public calcLegendEntryBoundingBoxes(
    widthOverride: number = undefined,
  ): LegendEntryBoundingBoxes {
    const containerWidth = widthOverride ?? this.containerWidth;

    const legendKeys = Array.from(this.legendEntries.keys());

    // RectWidth for all legendEntries if they do not fitOnOneLine
    let rectWidth = this.calculateLegendEntryBoundingBoxWidth();

    const rectHeight = this.legendFont.size;

    let numberOfColumns = 1;
    if (this.legendLayout.orientation === 'h') {
      numberOfColumns = Math.min(Math.floor(containerWidth / rectWidth), legendKeys.length) || 1;
    }
    // Check if legends fit on one line, need the orientation to be horizontal
    const legendsFitOnOneLine = this.getLegendTotalWidth() < containerWidth
      && this.legendLayout.orientation === 'h';

    // Margin to match wanted vertical positioning
    let leftMarginForHorizontalPositioning = 0;
    if (this.containerLayout.horizontalPosition === 'right') {
      // Math.max because we don't want negative values
      leftMarginForHorizontalPositioning = Math.max(
        0,
        legendsFitOnOneLine
          ? containerWidth - this.getLegendTotalWidth()
          : containerWidth - (rectWidth * numberOfColumns),
      );
    }
    /**
     * Represent the space not used when there is no scrollbox
     * - Ex: if height = 50, but there is only two rows of legends of a total height of 35 px
     *       There is 15 unused px that we will use to add to y margin
     * Math.max because we don't want negative values
     */
    const yMarginFromEmptySpace = Math.max(
      0,
      (this.containerHeight - (rectHeight * (legendKeys.length / numberOfColumns))) / 4,
    );

    // Iterate of the keys to generate the bounding boxes

    let index = -1;
    const legendEntryBoundingBoxes: LegendEntryBoundingBoxes = {};

    for (const key of legendKeys) {
      index += 1;

      let x = this.legendMargin.left + leftMarginForHorizontalPositioning;
      let y = yMarginFromEmptySpace;
      if (legendsFitOnOneLine) {
        // getting entry width from text to place it on one line
        rectWidth = this.getLegendBoundingWidthFromText(this.legendEntries.get(key), true);
      }

      if (index > 0) {
        const previousEntryDim = legendEntryBoundingBoxes[legendKeys[index - 1]];
        y = previousEntryDim.y;

        if ((this.legendOrientation === 'h' && (index % numberOfColumns)) || legendsFitOnOneLine) {
          // Adding padding from previous legend
          x = previousEntryDim.x + previousEntryDim.width;
        } else {
          // New row of legends
          y += this.computedLayout.legendEntriesColumnHeight;
        }
      }

      legendEntryBoundingBoxes[key] = { width: rectWidth, height: rectHeight, x: x, y: y };

      /** When we are on the last element, we save the legendEntriesTotalHeight */
      if (index === legendKeys.length - 1) {
        this.computedLayout.legendEntriesTotalHeight = legendEntryBoundingBoxes[key].y
          + legendEntryBoundingBoxes[key].height
          + this.legendLayout.margin.bottom;
      }
    }
    return legendEntryBoundingBoxes;
  }

  /**
   * Building custom legends for corresponding chart
   * @param plotlyChartId DOM id of the chart to retrieve it
   * @param containerLayout Layout that will be applied to the legends container
   * @param legendLayout Layout that will be applied to each legend container content
   * @param legendEntries Dict of 'key:value' legend entries
   */
  public buildCustomLegends(
    plotlyChartId: string,
    containerLayout: LegendContainerLayout,
    legendLayout: LegendLayout,
    legendEntries: Map<string, string>,
  ): void {
    this.chartDivId = plotlyChartId;

    const d3Chart = select<HTMLElement, undefined>(`#${this.chartDivId}`);

    const existingLegend = d3Chart.select<SVGGElement>('.legend');
    if (existingLegend.empty()) return;

    this.containerLayout = containerLayout;
    this.legendLayout = legendLayout;
    this.legendEntries = legendEntries;

    if (!this.legendEntries.size) return;

    this.computedLayout = this.computeLegendEntriesGenericLayout();
    // Init legendEntryBoundingBoxes by calculating entry Position
    this.legendEntryBoundingBoxes = this.calcLegendEntryBoundingBoxes();

    this.customLegendContainer = this.initCustomSvgLegend(d3Chart, existingLegend);
    this.appendPlotlyLegendsToCustomLegendsContainer(this.customLegendContainer);
  }
}
